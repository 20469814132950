import * as React from "react";
import { Option } from "~types/types";
import { Input } from "./FormElements";

export type SelectProps<T> = {
  value?: T;
  onChange: (value?: T) => void;
  /**
   * Note: Option.value should not repeat
   * BAD:
   * `[{value: 'a', label: "A"}, {value: 'a', label: "A"}] */
  options: Option<T>[];
};

export function RadioSelect<T extends string | undefined>({
  value,
  onChange,
  options
}: SelectProps<T>) {
  return (
    <>
      {options.map((option: Option<T>) => (
        <label key={option.value}>
          <Input
            type="radio"
            checked={value === option.value}
            name={option.value}
            onChange={() => onChange(option.value)}
          />{" "}
          {option.label}{" "}
        </label>
      ))}
    </>
  );
}
