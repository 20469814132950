import { ApolloProvider } from "@apollo/react-hooks";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { NotAllowed } from "~/common/NotAllowed";
import { NotFound } from "~/common/NotFound";
import { ColumnPage } from "~/common/Page";
import { apolloClient } from "../apolloClient";
import { UserGroups } from "./UserGroups";
import { Applicants } from "./Applicants";
import { ExternalApplicants } from "./ExternalApplicants";
import { Customer } from "./Customer";
import { OrganizationPerson } from "./OrganizationPerson";
import { Organization } from "./Organization";
import { Customers } from "./Customers";
import { Modules } from "./Modules";
import { PreliminaryReviewModuleVerifications } from './PreliminaryReviewModuleVerifications';
import { PendingOrgCustomer } from "./pending-org-customers/routes/PendingOrgCustomer";
import { PendingOrgCustomers, PendingOrgCustomersParent } from "./pending-org-customers/routes/PendingOrgCustomers";
import { Purchases } from "./Purchases";
import { Redemptions } from "./Redemptions";
import { SanctionMonitoringAlerts } from "./SanctionMonitoringAlerts";

export function InternalRoutes() {
  return (
    <ApolloProvider client={apolloClient}>
      <Switch>
        <Route
          path="/internal/pending-org-customers/:customerId/orgs/:orgId"
          component={PendingOrgCustomer}
        />
        <Route
          path="/internal/pending-org-customers"
          component={PendingOrgCustomersParent}
        />
        <Redirect exact from="/internal" to="/internal/customers" />
        <Route path="/internal/modules" component={Modules} />
        <Route path="/internal/preliminary-modules" component={PreliminaryReviewModuleVerifications} />
        <Route
          path="/internal/individual-applicants"
          render={(props) => <Applicants {...props} customerType={'individual'} />}
        />
        <Route
          path="/internal/org-applicants"
          render={(props) => <Applicants {...props} customerType={'organization'} />}
        />
        <Route path="/internal/external-applicants" component={ExternalApplicants} />
        <Route path="/internal/customers/:customerId" component={Customer} />
        <Route path="/internal/organizations/persons/:personId" component={OrganizationPerson} />
        <Route path="/internal/organizations/:organizationId" component={Organization} />
        <Route path="/internal/customers" component={Customers} />
        <Route path="/internal/mints" component={Purchases} />
        <Route path="/internal/redemptions" component={Redemptions} />
        <Route path="/internal/sanction-monitoring-alerts" component={SanctionMonitoringAlerts} />
        <Route path="/internal/user-groups" component={UserGroups} />
        <Route
          render={() => (
            <ColumnPage>
              <NotFound />
            </ColumnPage>
          )}
        />
      </Switch>
    </ApolloProvider>
  );
}
