import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { BasicPageBody, VSpace } from "~/common/Layout";
import { Section, SectionHeading } from "~/common/Section";
import { Loading } from "~/common/Loading";
import { ColumnPage } from "~/common/Page";
import { ApolloError } from "~common/ApolloError";

const GET_USER_GROUPS = gql`
  query {
    getUserGroups {
      id
      name
      friendlyName
      users {
        id
        email
      }
    }
  }
`;

function UserGroup({ group }) {
  return (
    <div>
      <Section>
        <SectionHeading> {group.friendlyName} </SectionHeading>
        {group.users.map(user => <div>{user.email}</div>)}
      </Section>
    </div>
  )
};

export function UserGroups() {
  const { loading, error, data } = useQuery(GET_USER_GROUPS);

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const userGroups = data.getUserGroups;

  return (
    <ColumnPage>
      <BasicPageBody title="User Groups">
        <VSpace space={4}>
          {userGroups.map(group => <UserGroup key={group.id} group={group} />)}
        </VSpace>
      </BasicPageBody>
    </ColumnPage>
  );
}
