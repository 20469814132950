import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import styled from "styled-components";
import { ApolloError } from "~/common/ApolloError";
import { REJECTION_REASON_NOTES } from "~/common/application";
import { CustomerIdContext } from "~/common/CustomerIdContext";
import { Loading } from "~/common/Loading";
import { formatTime } from "~/common/util";
import { VSpace } from "~common/Layout";
import { Section, SectionHeading } from "~/common/Section";
import { Rejection } from "~types/types";

const GET_EXTERNAL_CUSTOMER_SUBMISSIONS = gql`
  query($customerId: ID) {
    getCustomer(id: $customerId) {
      id
      externalCustomers {
        id
        client {
          id
          name
        }
        applicationSubmissions {
          id
          createdAt
          decision
          reviewedAt
          rejectionReasons {
            component
            code
            message
          }
          clientKycTier {
            name
          }
        }
      }
    }
  }
`;

const Gray = styled.div.attrs({
  className: "gray"
})``;

const Red = styled.div.attrs({
  className: "red"
})``;

const Green = styled.div.attrs({
  className: "green"
})``;

function Submission({ submission }) {
  return (
    <VSpace space={1}>
      <Gray>
        Tier: {submission.clientKycTier.name}
      </Gray>

      {!submission.decision && (
        <Gray>
          Pending Submission: Created At -{" "}
          {formatTime(new Date(submission.createdAt))}
      </Gray>
      )}
      {submission.decision == 'approved' && (
        <Green>
          Submission: Created At -{" "}
          {formatTime(new Date(submission.createdAt))}; Approved At -{" "}
          {formatTime(new Date(submission.reviewedAt))}
        </Green>
      )}
      {submission.decision == 'rejected' && (
        <Red>
          Submission: Created At -{" "}
          {formatTime(new Date(submission.createdAt))}; Rejected At -{" "}
          {formatTime(new Date(submission.reviewedAt))}
        </Red>
      )}
      {submission.rejectionReasons &&
        submission.rejectionReasons.map(
          (rejectionReason: Rejection.Reason) => {
            return (
              <div
                key={`${rejectionReason.component}-${rejectionReason.code}`}
              >
                {rejectionReason.code !== "other" &&
                  REJECTION_REASON_NOTES[rejectionReason.component][
                    rejectionReason.code
                  ]}
                {rejectionReason.code === "other" &&
                  rejectionReason.message}
              </div>
            );
          }
        )}
    </VSpace>
  );
}

export function ExternalCustomerSubmissions() {
  const customerId = React.useContext(CustomerIdContext);

  const { loading, error, data } = useQuery(GET_EXTERNAL_CUSTOMER_SUBMISSIONS, {
    variables: { customerId }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const customer = data.getCustomer;
  const externalCustomers = customer.externalCustomers;

  return (
    <VSpace space={3}>
      {externalCustomers.map((externalCustomer) => {
        const submissions = externalCustomer.applicationSubmissions;

        return (
          <div key={externalCustomer.id}>
            <div className="f4 mb1"> {externalCustomer.client.name} </div>
            {submissions.map((submission, index: number) => {
              return <Submission submission={submission} key={submission.id} />
            })}
          </div>
        );
      })}
    </VSpace>
  );
}
