import * as React from "react";
import { AnticipatedMonthlyVolume } from "~types/types";
import { Unknown } from "~common/Unknown";

export function AnticipatedMonthlyVolume({
  anticipatedMonthlyVolume
}: {
  anticipatedMonthlyVolume?: AnticipatedMonthlyVolume;
}) {
  if (!anticipatedMonthlyVolume) return <Unknown />;

  return (
    <span
      className={
        // Highlight when more than minimum anticipated monthly volume
        // This is so compliance can pay extra attention
        anticipatedMonthlyVolume.amount !== "1-100K" ? "orange" : ""
      }
    >
      {anticipatedMonthlyVolume.amount} {anticipatedMonthlyVolume.currency}
    </span>
  );
}
