import * as React from "react";
import { ErrorBox } from "~/common/ErrorBox";
import { VSpace } from "~/common/Layout";
import { formatTime } from "~/common/util";

type Status = "GOOD" | "WARNING" | "BAD";

function Container({
  status,
  title,
  date,
  deactivatedAt,
  children
}: {
  status: Status;
  title: string;
  date?: string;
  deactivatedAt?: string;
  children?: React.ReactNode;
}) {
  const className = {
    GOOD: "green",
    WARNING: "orange",
    BAD: "dark-red"
  }[status];

  return (
    <div className={className}>
      <VSpace space={1}>
        {deactivatedAt && (
          <div className="flex justify-between items-center dark-red b">
            Deactivated:
            <div className="nowrap">{formatTime(new Date(deactivatedAt))}</div>
          </div>
        )}
        <div className="flex justify-between items-center">
          <b>{title}</b>
        </div>
        <small className="db">
          {date && formatTime(new Date(date))}
          {children}
        </small>
      </VSpace>
    </div>
  );
}

type CustomerFields = {
  deactivatedAt: string;
  application: {
    status: "new" | "presubmitted" | "submitted" | "approved" | "rejected";
    latestSubmission?: {
      createdAt?: string;
      reviewedAt?: string;
      decision?: string;
    };
  };
};

export function ApprovalStatus({ customer }: { customer: CustomerFields }) {
  const approvalStatus = customer.application.status;

  switch (approvalStatus) {
    case "new":
      return (
        <Container
          title="⏳Pending Submission"
          status="WARNING"
          deactivatedAt={customer.deactivatedAt}
        >
          Not Submitted Yet
        </Container>
      );
    case "presubmitted":
      return (
        <Container
          title="⏳Presubmitted"
          status="WARNING"
          deactivatedAt={customer.deactivatedAt}
        >
          Presubmitted (not Submitted)
        </Container>
      );
    case "submitted":
      return (
        <Container
          title="Submitted: Pending Approval"
          status="WARNING"
          date={
            customer.application.latestSubmission &&
            customer.application.latestSubmission.createdAt
          }
          deactivatedAt={customer.deactivatedAt}
        />
      );
    case "approved":
      return (
        <Container
          title="Approved"
          status="GOOD"
          date={
            customer.application.latestSubmission &&
            customer.application.latestSubmission.decision === 'approved' &&
            customer.application.latestSubmission.reviewedAt
          }
          deactivatedAt={customer.deactivatedAt}
        />
      );
    case "rejected":
      return (
        <Container
          title="⏳Rejected: Pending Re-submission"
          status="WARNING"
          date={
            customer.application.latestSubmission &&
            customer.application.latestSubmission.decision === 'rejected' &&
            customer.application.latestSubmission.reviewedAt
          }
          deactivatedAt={customer.deactivatedAt}
        />
      );
    default:
      return <ErrorBox title="Can't figure out approval" />;
  }
}
