// Note(mark.mironyuk): this component is only used by internal portal but I want to make the partner portal use it too
import * as React from "react";
import { DemoModeStatus } from "./DemoModeStatus";
import { ErrorBoundary } from "./ErrorBoundary";
import { HSpace } from "./Layout";
import { NavColumn } from "./NavColumn";
import { ScrollToTopOnMount } from "./ScrollToTopOnMount";

export const Body = ({ children }: { children: React.ReactNode }) => (
  <div className="ns-w-90 center pa4 min-vh-100">{children}</div>
);

export function Footer() {
  return (
    <HSpace
      space={1}
      className="items-center f7 justify-between bt b--moon-gray"
    >
      {/* <DemoModeStatus /> */}
      {/* <div className="mr1">RELEASE: {process.env.RELEASE}</div> */}
    </HSpace>
  );
}

export function ColumnPage({
  top,
  children
}: {
  top?: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <div className="vh-100 flex">
      <ScrollToTopOnMount />
      <div
        className="flex flex-shrink-0 br b--black-20"
        style={{ width: "15rem" }}
      >
        <NavColumn />
      </div>
      {/* `overflow-hidden` to hide shadow  */}
      <div className="flex-grow-1 flex flex-column overflow-hidden">
        {/* `flex-column` necessary for making sure padding added to bottom when contents too big and require scrolling */}
        {top && <div className="shadow-1 z-1">{top}</div>}
        {/* `relative z-0` to create stacking context so no z-index inside here can be above `z-1` */}
        <div className="flex-grow-1 overflow-auto flex flex-column relative z-0">
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
        {/* <Footer /> */}
      </div>
    </div>
  );
}
