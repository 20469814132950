import * as React from "react";
import { compareDesc } from "date-fns";
import { Link } from "react-router-dom";

import {
  PRODUCT_CURRENCY_MAP,
  centsToDollars,
  displayCurrencyNumber,
  formatTime,
  Product
} from "~/common/util";
import { Table, TH, TR, TD } from "~/common/Table";

import { BlockchainTransactionLink } from "./BlockchainTransactionLink";

type Purchase = {
  id: string;
  customerId: string;
  bankingPartner: {
    name: string;
  };
  createdAt: string;
  product: Product;
  amountInCents: number;
  shortId: string;
  status: string;
  requestTransactionHash: string;
  finalizeTransactionHash: string;
  blockchainId: string;
};

export function PurchasesTable({
  purchases,
  hideCustomerColumn
}: {
  purchases?: Purchase[];
  hideCustomerColumn?: boolean;
}) {
  if (!purchases || purchases.length === 0) {
    return <div className="gray pv3">None</div>;
  }

  const reshaped = purchases.map(p => ({
    ...p,
    // TODO: different browsers parse string dates differently
    createdAt: new Date(p.createdAt)
  }));
  const sorted = reshaped.sort((a, b) =>
    compareDesc(new Date(a.createdAt), new Date(b.createdAt))
  );

  const showCustomer = !hideCustomerColumn;

  return (
    <Table
      head={
        <>
          <TH>
            Created At
            <small className="db normal gray">(Most recent first)</small>
          </TH>
          {showCustomer && <TH>Customer</TH>}
          <TH>Amount Received</TH>
          <TH>Reference Number</TH>
          <TH>Banking Partner</TH>
          <TH>Status</TH>
        </>
      }
    >
      {sorted.map(purchase => (
        <TR key={purchase.id}>
          <TD>{formatTime(new Date(purchase.createdAt))}</TD>
          {showCustomer && (
            <TD>
              <Link
                to={`/internal/customers/${purchase.customerId}/transactions`}
                className="blue link underline"
              >
                View Customer
              </Link>
            </TD>
          )}
          <TD className="code tr">
            {displayCurrencyNumber(
              centsToDollars(purchase.amountInCents),
              PRODUCT_CURRENCY_MAP[purchase.product]
            )}
          </TD>
          <TD className="code tr">{purchase.shortId}</TD>
          <TD>{purchase.bankingPartner.name}</TD>
          <TD className="flex justify-between">
            {purchase.status}
            <BlockchainTransactionLink
              product={purchase.product}
              blockchainId={purchase.blockchainId}
              hash={purchase.finalizeTransactionHash}
            />
          </TD>
        </TR>
      ))}
    </Table>
  );
}
