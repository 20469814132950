import { User } from '~types/types';

function isTreasuryTeamMember(user: User) {
  if (!user || !user.groups) { return false; }

  for (let i = 0; i < user.groups.length; i++) {
    const group = user.groups[i];
    if (group.name === 'treasury') { return true; }
  }

  return false;
}

export { isTreasuryTeamMember };
