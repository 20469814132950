import * as React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { isImageFileName, isIFrameFileName } from "~/common/util";
import { DocumentLabel } from "~common/DocumentLabel";
import { UploadedDocument } from "~types/types";
import { Button } from "~/common/FormElements";

type Props = {
  documents?: UploadedDocument[];
  personId?: string,
  organizationId?: string,
  showDelete?: boolean,
};

const DELETE_DOCUMENT_FROM_PERSON = gql`
  mutation($personId: ID, $documentId: ID, $input: DeletePersonDocumentInput) {
    deleteDocumentFromPerson(personId: $personId, documentId: $documentId, input: $input)
  }
`;

const DELETE_DOCUMENT_FROM_ORG = gql`
  mutation($organizationId: ID, $documentId: ID, $input: DeleteOrganizationDocumentInput) {
    deleteDocumentFromOrganization(organizationId: $organizationId, documentId: $documentId, input: $input)
  }
`;

export function DocumentPreviews({ documents, showDelete, personId, organizationId }: Props) {

  const [deleteDocumentFromPerson] = useMutation(DELETE_DOCUMENT_FROM_PERSON);
  const [deleteDocumentFromOrg] = useMutation(DELETE_DOCUMENT_FROM_ORG);

  if (!documents || documents.length === 0) {
    return <div className="black-50">No Documents</div>;
  }

  const handleDeleteDoc = async (documentId) => {
    if (!confirm('Are you sure you want to delete this document?')) {
      return;
    }

    if (personId) {
      await deleteDocumentFromPerson({
        variables: {
          personId,
          documentId,
          input: {
            forceDelete: true,
          }
        }
      })
      alert('Document deleted');
    } else if (organizationId) {
      await deleteDocumentFromOrg({
        variables: {
          organizationId,
          documentId,
          input: {
            forceDelete: true,
          }
        }
      })
      alert('Document deleted');
    }
  };

  return (
    <>
      {documents.map(doc => (
        <div key={doc.id} className="pt2 pb2">
          <a
            className="db ba pa2 blue"
            href={doc.signedUrl}
            target="_blank"
            title="Full size"
          >
            <DocumentLabel document={doc} />
            {doc.signedUrl &&
              isImageFileName(new URL(doc.signedUrl).pathname) && (
                <img src={doc.signedUrl} className="db" />
            )}
          </a>
          {showDelete &&
            <Button onClick={() => handleDeleteDoc(doc.id)} className="mt2 mb2"> Delete Document </Button>
          }
        </div>
      ))}
    </>
  );
}
