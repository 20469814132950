import * as React from "react";
import { Unknown } from "./Unknown";

export function Annotated({
  label,
  value,
  className,
  unknownOk = false
}: {
  label: string;
  value: React.ReactNode;
  className?: string;
  unknownOk?: boolean;
}) {
  return (
    <div className={className}>
      <small
        className="db black-40"
        style={{
          /* Prevent selecting labels, so easier copying when selecting multiple Annotated components */
          userSelect: "none"
        }}
      >
        {label}
      </small>
      <div className="mt1">{value || <Unknown isBad={!unknownOk} />}</div>
    </div>
  );
}
