import * as React from "react";
import { formatTime } from "./util";

type Props = {
  approvedAt: string;
  deactivatedAt: string;
};

export function ApprovalStatus({ approvedAt, deactivatedAt }: Props) {
  return (
    <div>
      {approvedAt && !deactivatedAt ? (
        <div className="green">
          ✅ TCNH Approved (KYC/AML)
          <small className="db">{formatTime(new Date(approvedAt))}</small>
        </div>
      ) : (
        <>
          <div className="bg-washed-red dark-red pa2 mb1">
            TCNH Not Approved (KYC/AML)
          </div>
          {approvedAt && (
            <small className="db">
              Approved: {formatTime(new Date(approvedAt))}
            </small>
          )}
          {deactivatedAt && (
            <small className="db">
              Deactivated: {formatTime(new Date(deactivatedAt))}
            </small>
          )}
        </>
      )}
    </div>
  );
}
