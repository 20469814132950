import * as React from "react";
import { Unknown } from "./Unknown";

type Props = {
  k: React.ReactNode;
  v: React.ReactNode;
  unknownOk?: boolean;
};

export function KV({ k, v, unknownOk = false }: Props) {
  return (
    <div className="flex mb1">
      <b className="w-50">{k}: </b>
      <div className="w-50">{v || (unknownOk ? "" : <Unknown />)}</div>
    </div>
  );
}
