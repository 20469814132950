import isNumber from "lodash-es/isNumber";
import * as React from "react";
import { Address } from "~/common/Address";
import { Button } from "~/common/FormElements";
import { KV } from "~/common/KV";
import { ShowLast4 } from "~/common/ShowLast4";
import { Badge } from "~common/Badge";
import { DocumentPreviews } from "~common/DocumentPreviews";
import { HSpace, VSpace } from "~common/Layout";
import { Section, SectionHeading } from "~common/Section";
import { OrgMember } from "~types/types";

export function OrgMemberLink({ member }: { member: OrgMember }) {
  const [show, setShow] = React.useState(false);

  return (
    <div className="mb2">
      <Section>
        <Button className="ba w-100 pa2" onClick={() => setShow(s => !s)}>
          <SectionHeading className="normal flex justify-between">
            <HSpace space={2}>
              {member.firstName} {member.lastName}{" "}
              {member.isAuthorizedUser && <Badge>Authorized User</Badge>}{" "}
            </HSpace>
            <span>{show ? "↑" : "↓"}</span>
          </SectionHeading>
        </Button>
        {show && (
          <div className="pa4 bg-black-05">
            <VSpace space={4}>
              <div>
                <KV
                  k="Ownership"
                  v={
                    isNumber(member.ownershipPercentage)
                      ? member.ownershipPercentage * 100 + "%"
                      : undefined
                  }
                />
                <KV k="First name" v={member.firstName} />
                <KV k="Middle name" v={member.middleName} unknownOk />
                <KV k="Last name" v={member.lastName} />
                <KV k="Date of birth" v={member.dateOfBirth} />
                <KV
                  k="National ID"
                  v={
                    member.nationalIdNumber && (
                      <ShowLast4 value={member.nationalIdNumber.toString()} />
                    )
                  }
                />
                <KV k="Address" v={<Address address={member.address} />} />
              </div>
              <Section>
                <SectionHeading className="bb">Documents</SectionHeading>
                <DocumentPreviews documents={member.documents} />
              </Section>
            </VSpace>
          </div>
        )}
      </Section>
    </div>
  );
}
