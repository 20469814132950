import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { ApolloError } from "~/common/ApolloError";
import { CustomerIdContext } from "~/common/CustomerIdContext";
import { Loading } from "~/common/Loading";
import { CreateNote, Notes } from "./Notes";

const GET_NOTES = gql`
  query($customerId: ID) {
    getCustomer(id: $customerId) {
      id
      application {
        id
        customerNotes {
          id
          createdAt
          content
        }
      }
    }
  }
`;

const CREATE_NOTE = gql`
  mutation(
    $customerId: ID
    $input: CreateCustomerApplicationCustomerNoteInput
  ) {
    createCustomerApplicationCustomerNote(
      customerId: $customerId
      input: $input
    ) {
      id
      createdAt
      content
    }
  }
`;

export function CustomerNotes() {
  const customerId = React.useContext(CustomerIdContext);

  const { loading, error, data } = useQuery(GET_NOTES, {
    variables: { customerId }
  });

  const [createNote] = useMutation(CREATE_NOTE);

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const notes = data.getCustomer.application.customerNotes;

  const handleCreateNote: CreateNote = content => {
    return createNote({
      variables: {
        customerId: customerId,
        input: { content }
      },
      refetchQueries: [{ query: GET_NOTES, variables: { customerId } }]
    });
  };

  return <Notes notes={notes} onCreateNote={handleCreateNote} />;
}
