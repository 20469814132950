import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { ApolloError } from "~/common/ApolloError";
import { ApprovalStatus } from "~/common/ApprovalStatus";
// Common
import { Loading } from "~/common/Loading";
import { CustomerIdContext } from "~common/CustomerIdContext";
import { VSpace } from "~common/Layout";
import { Section, SectionHeading } from "~common/Section";
import { Customer as CustomerType } from "~types/types";
import { Org } from "./Org";
import { Person } from "./Person";

const GET_CUSTOMER = gql`
  query($id: ID) {
    getCustomer(id: $id) {
      id
      approvedAt
      deactivatedAt
      person {
        id
        firstName
        middleName
        lastName
        dateOfBirth
        nationalIdNumber
        occupation {
          value
          other
        }
        governmentIdCountry
        citizenshipCountry
        address {
          street
          subpremise
          locality
          stateOrProvince
          postalCode
          country
        }
        documents {
          id
          type
          side
          signedUrl
          signedThumbnailUrl
        }
        application {
          id
          accountPurpose {
            value
            other
          }
          anticipatedMonthlyVolume {
            amount
            currency
          }
        }
      }
      organizationId
      organization {
        id
        name
      }
    }
  }
`;

export function Customer({ id }: { id: string }) {
  const { loading, error, data } = useQuery(GET_CUSTOMER, {
    variables: { id }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const customer: CustomerType = data.getCustomer;

  if (customer.person) {
    return (
      <Person
        person={customer.person}
        belowHeader={
          <ApprovalStatus
            approvedAt={customer.approvedAt}
            deactivatedAt={customer.deactivatedAt}
          />
        }
      />
    );
  } else if (customer.organizationId) {
    return (
      <CustomerIdContext.Provider value={id}>
        <Section>
          <VSpace space={4}>
            <VSpace space={2}>
              <SectionHeading>
                {customer.organization && customer.organization.name}
              </SectionHeading>
              <ApprovalStatus
                approvedAt={customer.approvedAt}
                deactivatedAt={customer.deactivatedAt}
              />
            </VSpace>
            <Org orgId={customer.organizationId} />
          </VSpace>
        </Section>
      </CustomerIdContext.Provider>
    );
  }
  throw new Error("Needs to have customer or person set");
}
