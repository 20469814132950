import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { NavLink, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { ApolloError } from "~/common/ApolloError";
import { CustomerIdContext } from "~/common/CustomerIdContext";
import { Loading } from "~/common/Loading";
import { NotFound } from "~/common/NotFound";
import { ColumnPage } from "~/common/Page";
import { CustomerNameHeading } from "../CustomerNameHeading";
import { CustomerGeneral } from "./CustomerGeneral";
import { CustomerTransactions } from "./CustomerTransactions";

const GET_CUSTOMER_ID = gql`
  query($customerId: ID) {
    getCustomer(id: $customerId) {
      id
      organizationId
    }
  }
`;

export const Customer = withRouter(({ match }) => {
  const { customerId } = match.params;

  const { loading, error, data } = useQuery(GET_CUSTOMER_ID, {
    variables: { customerId }
  });

  if (loading) {
    return (
      <ColumnPage>
        <Loading />
      </ColumnPage>
    );
  }

  // API returns an error if no customer found, so using this workaround
  if (!data || !data.getCustomer) {
    return (
      <ColumnPage>
        <NotFound />
      </ColumnPage>
    );
  }

  // Even if `data` exists, we might still have an error
  if (error) {
    return (
      <ColumnPage>
        <ApolloError error={error} />
      </ColumnPage>
    );
  }

  const organizationId = data.getCustomer.organizationId;

  return (
    <CustomerIdContext.Provider value={customerId}>
      <ColumnPage
        top={
          <div className="flex">
            <div className={`pa3 ${!organizationId ? 'pine-green' : 'brown'}`}>
              {/* TODO: don't make this component do its own GraphQL query */}
              <CustomerNameHeading />
            </div>
            <NavLink
              to={`/internal/customers/${customerId}/general`}
              className="blue link ph3 flex items-center"
              activeClassName="bg-black-05"
            >
              General
            </NavLink>
            <NavLink
              to={`/internal/customers/${customerId}/transactions`}
              className="blue link ph3 flex items-center"
              activeClassName="bg-black-05"
            >
              Transactions &amp; Bank Info
            </NavLink>
            {organizationId &&
              <NavLink
                to={`/internal/pending-org-customers/${customerId}/orgs/${organizationId ? organizationId : customerId}`}
                className="blue link ph3 flex items-center"
                activeClassName="bg-black-05"
              >
                Edit Org
              </NavLink>
            }
          </div>
        }
      >
        <Switch>
          <Redirect
            exact
            from="/internal/customers/:customerId"
            to="/internal/customers/:customerId/general"
          />
          <Route
            path="/internal/customers/:customerId/general"
            component={CustomerGeneral}
          />
          <Route
            path="/internal/customers/:customerId/transactions"
            component={CustomerTransactions}
          />
        </Switch>
      </ColumnPage>
    </CustomerIdContext.Provider>
  );
});
