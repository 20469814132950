import { useEffect } from 'react';

// Basically, this:
// https://github.com/rehooks/document-title/blob/master/index.js
// Doing a custom version because the repo is kinda languishing
function useDocumentTitle(title: string) {
  useEffect(() => {
    window.document.title = title + ' - TrueCNH';
  }, [title]);
}

export { useDocumentTitle };
