import * as React from "react";
import { Portal } from "react-portal";
import { ErrorBoundary } from "./ErrorBoundary";
import { GlobalStyles } from "./GlobalStyles";
import { Section, SectionHeading } from "./Section";
import { useEscapeKey } from "./useEscapeKey";

const NOOP = () => {};

export function Modal({
  title,
  children,
  onClose = NOOP
}: {
  title: string;
  children: React.ReactNode;
  onClose?: () => void;
}) {
  useEscapeKey(onClose);

  return (
    <Portal>
      <GlobalStyles>
        <div
          className="fixed left-0 top-0 w-100 h-100 overflow-y-scroll bg-black-10 f5 z-1"
          onClick={() => onClose()}
        >
          <div
            className="relative center ba bg-white w-100 measure-wide ma5 pa4 shadow-5"
            onClick={e => {
              // Prevent clicking modal from propogating to parent and causing it to close
              e.stopPropagation();
            }}
          >
            <Section>
              <div className="flex justify-between mb4">
                <SectionHeading className="mt0 w-100">{title}</SectionHeading>
                {onClose !== NOOP && (
                  <button
                    className="button-reset absolute top-0 right-0 f2 pv0 ph2 bg-white"
                    onClick={() => onClose()}
                  >
                    ×
                  </button>
                )}
              </div>
              <ErrorBoundary>{children}</ErrorBoundary>
            </Section>
          </div>
        </div>
      </GlobalStyles>
    </Portal>
  );
}
