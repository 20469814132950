import { Form, FormikProps } from "formik";
import * as React from "react";
import { Button, Input } from "~common/FormElements";
import { Labeled } from "~common/Labeled";
import { VSpace } from "~common/Layout";
import { Section, SectionHeading } from "~common/Section";
import { Select } from "~common/Select";
import { COUNTRY_LIST, ENTITY_TYPES, STATES_LIST } from "~common/util";
import { EntityType, Org } from "~types/types";
import { AddressFormikPart } from "./AddressFormikPart";

export function OrgFormikForm(formikProps: FormikProps<Org>) {
  const { values, setFieldValue, handleChange } = formikProps;

  return (
    <Form>
      <VSpace space={3}>
        <Labeled label="Name">
          <Input name="name" value={values.name} onChange={handleChange} />
        </Labeled>
        <Labeled label="Entity Type">
          <div className="flex flex-column">
            <Select<EntityType>
              options={ENTITY_TYPES}
              value={values.entityType || undefined}
              onChange={value => setFieldValue("entityType", value)}
            />
          </div>
        </Labeled>
        <Labeled label="Tax ID">
          <Input
            name="taxIdNumber"
            value={values.taxIdNumber}
            onChange={handleChange}
          />
        </Labeled>
        <Labeled label="Region of Formation Country">
          <Select<string>
            options={COUNTRY_LIST}
            value={
              (values.regionOfFormation && values.regionOfFormation.country) ||
              undefined
            }
            onChange={value =>
              setFieldValue("regionOfFormation.country", value)
            }
          />
        </Labeled>
        {values.regionOfFormation && values.regionOfFormation.country === "US" && (
          <Labeled label="Region of Formation State">
            <Select<string>
              options={STATES_LIST}
              value={
                (values.regionOfFormation && values.regionOfFormation.state) ||
                undefined
              }
              onChange={value =>
                setFieldValue("regionOfFormation.state", value)
              }
            />
          </Labeled>
        )}

        <Section>
          <SectionHeading>Location</SectionHeading>
          <AddressFormikPart formikProps={formikProps} />
        </Section>
        <Button
          type="submit"
          className="w-100 bg-light-blue"
          style={{ position: "sticky", bottom: 0 }}
        >
          Save
        </Button>
      </VSpace>
    </Form>
  );
}
