import { useQuery } from "@apollo/react-hooks";
import { compareAsc } from "date-fns";
import gql from "graphql-tag";
import * as React from "react";
import { Link } from "react-router-dom";
import { ColumnPage } from "~/common/Page";
import { ApolloError } from "~common/ApolloError";
import { BasicPageBody, Mortar, VSpace } from "~common/Layout";
import { Loading } from "~common/Loading";
import { formatTime } from "~common/util";
import { Application, OrgMember } from "~types/types";
import { Tab, Tabs } from "~common/Tabs";

const GET_PRESUBMITTED_ORG_CUSTOMERS = gql`
  query($input: GetPresubmittedOrganizationCustomersInput) {
    getPresubmittedOrganizationCustomers(input: $input) {
      id
      application {
        id
        presubmittedAt
      }
      organization {
        id
        name
        authorizedPersons {
          firstName
          middleName
          lastName
          email
        }
      }
    }
  }
`;

function authorizedPerson(customer): OrgMember {
  return (
    customer &&
    customer.organization &&
    customer.organization.authorizedPersons &&
    customer.organization.authorizedPersons.length > 0 &&
    customer.organization.authorizedPersons[0]
  );
}

function Overview() {
  return (
    <aside className="mv0 black-50 measure">
      The process:
      <ul>
        <li className="mv2">
          The authorized user for an organization uses the customer TrueCurrency
          app to fill in basic information about themselves, and upload
          documents for their organization
        </li>
        <li className="mv2">
          As soon as the user has started the process, they show up here without
          the organization name
        </li>
        <li className="mv2">You fill out the organization information</li>
        <li className="mv2">
          Once an organization has been created, it goes to the{" "}
          <Link to="/internal/org-applicants" className="blue link underline">
            Organization Applicants
          </Link>{" "}
          list.
        </li>
      </ul>
    </aside>
  );
}

export function PendingOrgCustomers({ status = "new" }) {
  const { loading, error, data } = useQuery(GET_PRESUBMITTED_ORG_CUSTOMERS, {
    variables: {
      input: { offset: 0, limit: 50 }
    }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const customers = data.getPresubmittedOrganizationCustomers;

  const pageTitle = "Pending Organizations";

  if (!customers || customers.length == 0) {
    return null;
  }

  return (
    <Mortar space={4} className="mt3">
      <VSpace space={2}>
        <p className="mv0 black-50">Sorted by oldest first</p>
        {customers
          .filter(c => c.application && c.application.presubmittedAt)
          .sort((a: any, b: any) =>
            compareAsc(
              new Date(a.application.presubmittedAt),
              new Date(b.application.presubmittedAt)
            )
          )
          .map(customer => (
            <Link
              to={`/internal/pending-org-customers/${customer.id}/orgs/${customer.organization.id}`}
              key={customer.id}
              className="brown link no-underline ba pa2 flex"
            >
              <div className="flex-grow-1">
                <b className="db">
                  {customer &&
                  customer.organization &&
                  customer.organization.name
                    ? customer.organization.name
                    : "UNKNOWN Organization Name"}
                </b>
                {authorizedPerson(customer).firstName}{" "}
                {authorizedPerson(customer).middleName}{" "}
                {authorizedPerson(customer).lastName}
                <div>{authorizedPerson(customer).email}</div>
              </div>
              { formatTime(new Date(customer.application.presubmittedAt)) }
            </Link>
          ))}
      </VSpace>
      <Overview />
    </Mortar>
  );
}


export function PendingOrgCustomersParent() {
  // Parent page to show New and Rejected org applicants
  return (
    <ColumnPage>
      <BasicPageBody title="Pending Organizations">
        <Tabs>
          <Tab name="New">
            <PendingOrgCustomers status="new" />
          </Tab>
        </Tabs>
      </BasicPageBody>
    </ColumnPage>
  );
}
