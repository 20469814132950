import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { VSpace } from "~common/Layout";
import { Section } from "~common/Section";
import { notifyUser } from "~common/util";
import { Org } from "~types/types";
import { cleanOrgForApi } from "./cleanOrgForApi";
import { Column, ColumnBody, ColumnSectionHeading } from "./Column";
import { OrgFormikForm } from "./OrgFormikForm";

const EMPTY_ORG: Org = {
  name: undefined,
  taxIdNumber: undefined,
  entityType: undefined,
  regionOfFormation: {
    country: undefined,
    state: undefined
  },
  address: {
    street: undefined,
    subpremise: undefined,
    locality: undefined,
    stateOrProvince: undefined,
    postalCode: undefined,
    country: undefined
  }
};

const ADD_ORG_TO_ORG = gql`
  mutation($parentOrgId: ID, $org: OrganizationInput) {
    addOwnerOrganizationToOrganization(id: $parentOrgId, input: $org) {
      id
    }
  }
`;

export function ColumnAddOrg({
  parentOrgId,
  onAdd,
  onClose
}: {
  parentOrgId: string;
  onAdd: (orgId: string) => void;
  onClose: () => void;
}) {
  const [addOrg] = useMutation(ADD_ORG_TO_ORG, {
    onError: err => notifyUser(err, "Error adding organization")
  });

  async function handleAddOrg(org: Org) {
    const { data } = await addOrg({
      variables: {
        parentOrgId: parentOrgId,
        org: cleanOrgForApi(org)
      }
    });
    onAdd(data.addOwnerOrganizationToOrganization.id);
  }

  return (
    <Column>
      <Section>
        <ColumnSectionHeading onClose={onClose}>
          Add Organization
        </ColumnSectionHeading>
        <ColumnBody>
          <VSpace space={4}>
            <Formik
              initialValues={EMPTY_ORG}
              onSubmit={handleAddOrg}
              component={OrgFormikForm}
            />
          </VSpace>
        </ColumnBody>
      </Section>
    </Column>
  );
}
