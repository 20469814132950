import * as React from "react";
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Wrapper = styled.span`
  cursor: pointer;
  opacity: 0.7;
  margin-left: 2px;
  
  &:hover {
    opacity: 1;
  }
`;

interface Props {
  textToCopy: string;
}

export function CopyToClipboard({ textToCopy }: Props) {
  const handleClick = () => {
    copy(textToCopy);
    toast.success('Copied to clipboard!');
  };

  return (
    <>
      <Wrapper
        onClick={handleClick}
        title="Copy to clipboard"
      >
        📋
      </Wrapper>
      <ToastContainer
        transition={Slide}
        position="top-right"
        autoClose={2000}
        closeOnClick
        pauseOnFocusLoss={false}
      />
    </>
  );
}
