import * as React from "react";

export const IsUnknownBad = React.createContext(true);

export function Unknown({ isBad = true }: { isBad?: boolean }) {
  const isUnknownBad = isBad && React.useContext(IsUnknownBad);
  return (
    <span className={isUnknownBad ? "dark-red" : "black-40"}>
      {isUnknownBad ? "UNKNOWN" : "-"}
    </span>
  );
}
