import * as React from "react";
import { compareDesc } from "date-fns";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import { formatTime } from "~/common/util";
import { Table, TH, TR, TD } from "~/common/Table";
import { Button } from "~/common/FormElements";

import { UserLink } from "./UserPanel";

const PRELIMINARYILY_APPROVE_SANCTION_MONITORING_ALERT = gql`
  mutation($id: ID) {
    preliminarilyApproveSanctionMonitoringAlert(id: $id) {
      id
    }
  }
`;

const APPROVE_SANCTION_MONITORING_ALERT = gql`
  mutation($id: ID) {
    approveSanctionMonitoringAlert(id: $id) {
      id
    }
  }
`;

const PRELIMINARILY_REJECT_SANCTION_MONITORING_ALERT = gql`
  mutation($id: ID) {
    preliminaryRejectSanctionMonitoringAlert(id: $id) {
      id
    }
  }
`;

const REJECT_SANCTION_MONITORING_ALERT = gql`
mutation($id: ID) {
  rejectSanctionMonitoringAlert(id: $id) {
    id
  }
}
`;

const REVIEW_STATUS_VALUES = {
  NOT_REVIEWED: 'NOT_REVIEWED',
  PRELIMINARY_REVIEWED: 'PRELIMINARY_REVIEWED',
  FINAL_REVIEWED: 'FINAL_REVIEWED',
  REJECTED: 'REJECTED',
};


export function SanctionMonitoringAlertsTable(props) {
  const { sanctionMonitoringAlerts, view } = props;
  const [sanctionMonitoringAlertList, setSanctionMonitoringAlertList] = React.useState(sanctionMonitoringAlerts);
  const [preliminarilyApproveSanctionMonitoringAlert] = useMutation(PRELIMINARYILY_APPROVE_SANCTION_MONITORING_ALERT);
  const [approveSanctionMonitoringAlert] = useMutation(APPROVE_SANCTION_MONITORING_ALERT);
  const [preliminarilyRejectSanctionMonitoringAlert] = useMutation(PRELIMINARILY_REJECT_SANCTION_MONITORING_ALERT);
  const [rejectSanctionMonitoringAlert] = useMutation(REJECT_SANCTION_MONITORING_ALERT);

  const handleApprove = async (sanctionMonitoringAlertId, index) => {
    if (view === REVIEW_STATUS_VALUES.NOT_REVIEWED){
      await preliminarilyApproveSanctionMonitoringAlert({ variables: { id: sanctionMonitoringAlertId }});
    }
    else if (view === REVIEW_STATUS_VALUES.PRELIMINARY_REVIEWED){
      await approveSanctionMonitoringAlert({ variables: { id: sanctionMonitoringAlertId }});
    }

    const newSanctionMonitoringAlertList = sanctionMonitoringAlertList.splice(index, 1);
    setSanctionMonitoringAlertList(newSanctionMonitoringAlertList);
  };

  const handleReject = async (sanctionMonitoringAlertId, index) => {
    if (view === REVIEW_STATUS_VALUES.NOT_REVIEWED){
      await preliminarilyRejectSanctionMonitoringAlert({ variables: { id: sanctionMonitoringAlertId }});
    }
    else if (view === REVIEW_STATUS_VALUES.PRELIMINARY_REVIEWED){
      await rejectSanctionMonitoringAlert({ variables: { id: sanctionMonitoringAlertId }});
    }

    const newSanctionMonitoringAlertList = sanctionMonitoringAlertList.splice(index, 1);
    setSanctionMonitoringAlertList(newSanctionMonitoringAlertList);
  };

  if (!sanctionMonitoringAlerts || sanctionMonitoringAlerts.length === 0) {
    return <div className="gray pv3">None</div>;
  }
  if (view === REVIEW_STATUS_VALUES.NOT_REVIEWED){
    return (
      <Table
        head={
          <>
            <TH>
              Customer Name
            </TH>
            <TH>Link</TH>
            <TH>Date of Match</TH>
            <TH>Preliminary Action</TH>
          </>
        }
      >
        {sanctionMonitoringAlerts.map((sanctionMonitoringAlert, index) => {
          const person = sanctionMonitoringAlert.person;
          const organization = sanctionMonitoringAlert.organization;

          let customer;
          if (person) {
            customer = person.customer;
          } else if (organization) {
            customer = organization.customer;
          }

          let toLink;
          if (customer) {
            toLink = `/internal/customers/${customer.id}/general`;
          } else if (person) {
            toLink = `/internal/organizations/persons/${person.id}`;
          } else if (organization) {
            toLink = `/internal/organizations/${organization.id}`;
          }
          const complyAdvantageSearchId = sanctionMonitoringAlert.externalVendorData && sanctionMonitoringAlert.externalVendorData.complyadvantageSearchId;
          const complyAdvantageLink = `https://app.complyadvantage.com/#/case-management/search/${complyAdvantageSearchId}`;
          return (
            <TR key={sanctionMonitoringAlert.id}>
              {
                <>
                  <TD>
                    <Link
                      to={toLink}
                      className={`${!organization ? 'pine-green' : 'brown'} link underline`}
                    >
                      {organization ? organization.name: (person ? `${person.firstName} ${person.lastName}` : "UNKNOWN")}
                    </Link>
                    </TD>
                  <TD>
                    <a target="_blank" href={complyAdvantageLink} className="db truncate blue link underline" > Link</a>
                  </TD>
                  <TD>{formatTime(new Date(sanctionMonitoringAlert.createdAt))}</TD>
                  <TD>
                    <Button className="mr2 bg-light-green" onClick={() => handleApprove(sanctionMonitoringAlert.id, index)}>Approve</Button>
                    <Button className="bg-light-red" onClick={() => handleReject(sanctionMonitoringAlert.id, index)}>Reject</Button>
                  </TD>
                </>
              }
            </TR>
          );
        })}
      </Table>
    );
  }
  if (view === REVIEW_STATUS_VALUES.PRELIMINARY_REVIEWED){
    return (
      <Table
        head={
          <>
            <TH>
              Customer Name
            </TH>
            <TH>Link</TH>
            <TH>Date of Match</TH>
            <TH>Preliminary Decision</TH>
            <TH>Preliminary Reviewer</TH>
            <TH>Action</TH>
          </>
        }
      >
        {sanctionMonitoringAlerts.map((sanctionMonitoringAlert, index) => {
          const person = sanctionMonitoringAlert.person;
          const organization = sanctionMonitoringAlert.organization;

          let customer;
          if (person) {
            customer = person.customer;
          } else if (organization) {
            customer = organization.customer;
          }

          let toLink;
          if (customer) {
            toLink = `/internal/customers/${customer.id}/general`;
          } else if (person) {
            toLink = `/internal/organizations/persons/${person.id}`;
          } else if (organization) {
            toLink = `/internal/organizations/${organization.id}`;
          }
          const complyAdvantageSearchId = sanctionMonitoringAlert.externalVendorData && sanctionMonitoringAlert.externalVendorData.complyadvantageSearchId;
          const complyAdvantageLink = `https://app.complyadvantage.com/#/case-management/search/${complyAdvantageSearchId}`;
          return (
            <TR key={sanctionMonitoringAlert.id}>
              {
                <>
                  <TD>
                    <Link
                      to={toLink}
                      className={`${!organization ? 'pine-green' : 'brown'} link underline`}
                    >
                      {organization ? organization.name: `${person.firstName} ${person.lastName}`}
                    </Link>
                    </TD>
                  <TD>
                    <a target="_blank" href={complyAdvantageLink} className="db truncate blue link underline" > Link</a>
                  </TD>
                  <TD>{formatTime(new Date(sanctionMonitoringAlert.createdAt))}</TD>
                  <TD>{sanctionMonitoringAlert.preliminaryDecision}</TD>
                  <TD>
                    <UserLink userId={sanctionMonitoringAlert.preliminaryReviewedBy}/>
                  </TD>
                  <TD>
                    <Button className="mr2 bg-light-green" onClick={() => handleApprove(sanctionMonitoringAlert.id, index)}>Approve</Button>
                    <Button className="bg-light-red" onClick={() => handleReject(sanctionMonitoringAlert.id, index)}>Reject</Button>
                  </TD>
                </>
              }
            </TR>
          );
        })}
      </Table>
    );
  }
  if (view === REVIEW_STATUS_VALUES.FINAL_REVIEWED || view === REVIEW_STATUS_VALUES.REJECTED ){
    return (
      <Table
        head={
          <>
            <TH>
              Customer Name
            </TH>
            <TH>Link</TH>
            <TH>Date of Match</TH>
            <TH>Preliminary Decision</TH>
            <TH>Preliminary Reviewer</TH>
            <TH>Final Decision</TH>
            <TH>Final Reviewer</TH>
          </>
        }
      >
        {sanctionMonitoringAlerts.map((sanctionMonitoringAlert, index) => {
          const person = sanctionMonitoringAlert.person;
          const organization = sanctionMonitoringAlert.organization;

          let customer;
          if (person) {
            customer = person.customer;
          } else if (organization) {
            customer = organization.customer;
          }
          
          let toLink;
          if (customer) {
            toLink = `/internal/customers/${customer.id}/general`;
          } else if (person) {
            toLink = `/internal/organizations/persons/${person.id}`;
          } else if (organization) {
            toLink = `/internal/organizations/${organization.id}`;
          }
          const complyAdvantageSearchId = sanctionMonitoringAlert.externalVendorData && sanctionMonitoringAlert.externalVendorData.complyadvantageSearchId;
          const complyAdvantageLink = `https://app.complyadvantage.com/#/case-management/search/${complyAdvantageSearchId}`;
          return (
            <TR key={sanctionMonitoringAlert.id}>
              {
                <>
                  <TD>
                    <Link
                      to={toLink}
                      className={`${!organization ? 'pine-green' : 'brown'} link underline`}
                    >
                      {organization ? organization.name: `${person.firstName} ${person.lastName}`}
                    </Link>
                    </TD>
                  <TD>
                    <a target="_blank" href={complyAdvantageLink} className="db truncate blue link underline" > Link</a>
                  </TD>
                  <TD>{formatTime(new Date(sanctionMonitoringAlert.createdAt))}</TD>
                  <TD>{sanctionMonitoringAlert.preliminaryDecision}</TD>
                  <TD>
                    <UserLink userId={sanctionMonitoringAlert.preliminaryReviewedBy}/>
                  </TD>
                  <TD>{sanctionMonitoringAlert.decision}</TD>
                  <TD>
                    <UserLink userId={sanctionMonitoringAlert.reviewedBy}/>
                  </TD>
                </>
              }
            </TR>
          );
        })}
      </Table>
    );
  }
}
