import * as React from "react";
import {
  hashToEtherScanUrl,
  hashToAvalancheScanUrl,
  hashToBinanceUrl,
  hashToTronUrl,
  ETHEREUM_PRODUCTS,
  BINANCE_PRODUCTS,
  Product
} from "~/common/util";

function EtherTransactionLink({ hash }: { hash?: string }) {
  if (!hash) return null;

  return (
    <a
      href={hashToEtherScanUrl(hash)}
      target="_blank"
      className="black bg-light-blue pa1"
    >
      ETH ✓
    </a>
  );
}

function AvalancheTransactionLink({ hash }: { hash?: string }) {
  if (!hash) return null;

  return (
    <a
      href={hashToAvalancheScanUrl(hash)}
      target="_blank"
      className="black bg-light-blue pa1"
    >
      AVA ✓
    </a>
  );
}

function BinanceTransactionLink({ hash }: { hash?: string }) {
  if (!hash) return null;

  return (
    <a
      href={hashToBinanceUrl(hash)}
      target="_blank"
      className="black bg-light-yellow pa1"
    >
      BNB ✓
    </a>
  );
}

function TronTransactionLink({ hash }: { hash?: string }) {
  if (!hash) return null;

  return (
    <a
      href={hashToTronUrl(hash)}
      target="_blank"
      className="black bg-light-red pa1"
    >
      TRON ✓
    </a>
  );
}

export function BlockchainTransactionLink({
  product,
  blockchainId,
  hash
}: {
  product: Product;
  blockchainId: string;
  hash: string;
}) {
  // Converting to `string[]` because TypeScript doesn't allow
  // searching for things that don't exist

  if (blockchainId === 'BNB') {
    return <BinanceTransactionLink hash={hash} />;
  } else if (blockchainId === 'AVA') {
    return <AvalancheTransactionLink hash={hash} />;
  } else if (blockchainId === 'TRON') {
    return <TronTransactionLink hash={hash} />;
  } else {
    return <EtherTransactionLink hash={hash} />;
  }
  throw new TypeError("Expected either an ETH or Binance product");
}
