interface BucketKeyToName {
  truecurrency: string
  truefi: string
  watr: string
  [key: string]: string
}

interface BucketPresence {
  truecurrency: boolean
  truefi: boolean
  watr: boolean
  other: { name: string }[]
}

const bucketKeyToName: BucketKeyToName = {
  truecurrency: 'TrueCurrencies',
  truefi: 'TrueFi Borrower',
  watr: 'Watr.',
  alameda_sbp: 'Alameda Single Borrower Pool',
};

function getApplicationBucketNames(applicationBucket?: BucketPresence): string[] {
  if (!applicationBucket) {
    return [];
  }

  let bucketNames = []

  if (applicationBucket.truecurrency) {
    bucketNames.push(bucketKeyToName.truecurrency);
  }
  if (applicationBucket.truefi) {
    bucketNames.push(bucketKeyToName.truefi);
  }
  if (applicationBucket.watr) {
    bucketNames.push(bucketKeyToName.watr);
  }
  bucketNames.push(...applicationBucket.other.map(bucket => bucketKeyToName[bucket.name] || bucket.name));

  if (bucketNames.length === 0) {
    bucketNames.push(bucketKeyToName.truecurrency);
  }

  return bucketNames;
}

export { getApplicationBucketNames };
