import * as React from "react";

export function useEscapeKey(onClose: () => void) {
  React.useEffect(() => {
    function esc(e: KeyboardEvent) {
      if (e.key === "Esc" || e.key === "Escape") {
        onClose();
      }
    }
    document.addEventListener("keyup", esc);
    return () => {
      document.removeEventListener("keyup", esc);
    };
  }, []);
}
