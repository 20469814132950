import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { Annotated } from "~/common/Annotated";
import { ApolloError } from "~/common/ApolloError";
import { CustomerIdContext } from "~/common/CustomerIdContext";
import { HSpace } from "~/common/Layout";
import { Loading } from "~/common/Loading";
import { Address } from "~common/Address";
import { ShowLast4 } from "~common/ShowLast4";
import { UserContext } from "~common/UserContext";
import { Application, Customer } from "~types/types";
import { AnticipatedMonthlyVolume } from "./AnticipatedMonthlyVolume";

export const GET_ORG = gql`
  query($customerId: ID) {
    getCustomer(id: $customerId) {
      id
      organization {
        id
        name
        taxIdNumber
        regionOfFormation {
          country
          state
        }
        address {
          street
          subpremise
          locality
          stateOrProvince
          postalCode
          country
        }
      }
    }
  }
`;

export function CustomerInfoOrg({
  application
}: {
  application?: Application;
}) {
  const user = React.useContext(UserContext);
  const customerId = React.useContext(CustomerIdContext);
  const { loading, error, data } = useQuery(GET_ORG, {
    variables: { customerId }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const customer: Customer = data.getCustomer;
  const org = customer.organization;

  if (!org) {
    return <div className="black-50">No Organization</div>;
  }

  return (
    <>
      <Annotated label="Name" value={org.name} />
      {user.experimental.isCompliance && (
        <Annotated
          label="Tax ID"
          value={
            org.taxIdNumber && <ShowLast4 value={org.taxIdNumber.toString()} />
          }
        />
      )}
      <Annotated
        label="Purpose"
        value={
          application &&
          application.accountPurpose &&
          (application.accountPurpose.other || application.accountPurpose.value)
        }
      />
      <Annotated
        label="Anticipated Monthly Volume"
        value={
          application && (
            <AnticipatedMonthlyVolume
              anticipatedMonthlyVolume={application.anticipatedMonthlyVolume}
            />
          )
        }
      />
      <HSpace space={1}>
        <Annotated
          label="Region of Formation Country"
          value={org.regionOfFormation && org.regionOfFormation.country}
        />
        {/* If location outside of US, then state is unset */}
        <Annotated
          label="Region of Formation State"
          value={org.regionOfFormation && org.regionOfFormation.state}
          unknownOk
        />
      </HSpace>
      <b className="db pb1 bb b--black-20">Location</b>
      <Annotated label="Address" value={<Address address={org.address} />} />
    </>
  );
}
