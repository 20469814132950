import { useMutation, useQuery } from "@apollo/react-hooks";
import { compareDesc } from "date-fns";
import gql from "graphql-tag";
import * as React from "react";
import { PoseGroup } from "react-pose";
import { ApolloError } from "~/common/ApolloError";
import { Loading } from "~/common/Loading";
import { ScrollToTopOnMount } from "~/common/ScrollToTopOnMount";
import { PosedTR, Table, TD, TH } from "~/common/Table";
import {
  centsToDollars,
  displayCurrencyNumber,
  formatTime,
  notifyUser,
  Product,
  PRODUCT_CURRENCY_MAP
} from "~/common/util";
import { Customer } from "~types/types";
import { AddPurchaseForm, OnSubmitData } from "../AddPurchaseForm";
import { CustomerLink } from "../CustomerLink";

export const GET_MINTS = gql`
  query {
    getMintsForMyBankingPartner {
      id
      createdAt
      amountInCents
      product
      customer {
        id
        shortId # used as wire reference
        approvedAt
        deactivatedAt
        person {
          firstName
          lastName
        }
        organization {
          id
          name
        }
      }
    }
  }
`;

const CREATE_MINT = gql`
  mutation(
    $customerId: ID
    $product: String
    $amountInCents: Int
    $wireReference: String
  ) {
    createMint(
      customerId: $customerId
      product: $product
      amountInCents: $amountInCents
      wireReference: $wireReference
    ) {
      id
    }
  }
`;

type Purchase = {
  id: string;
  createdAt: string;
  amountInCents: number;
  product: Product;
  customer?: Customer & { shortId: string };
};

export function PurchasesTable({ purchases }: { purchases: Purchase[] }) {
  const reshaped = purchases.map(p => ({
    ...p,
    // TODO: different browsers parse string dates differently
    createdAt: new Date(p.createdAt)
  }));
  const sorted = reshaped.sort((a, b) =>
    compareDesc(new Date(a.createdAt), new Date(b.createdAt))
  );
  return (
    <Table
      head={
        <>
          <TH>
            Added
            <small className="db normal gray">(Most recent first)</small>
          </TH>
          <TH>TrueCurrency Holder</TH>
          <TH>Amount</TH>
          <TH>Wire Reference</TH>
        </>
      }
    >
      <PoseGroup>
        {sorted.map(({ id, createdAt, amountInCents, product, customer }) => (
          <PosedTR key={id} hiddenAtXPosition={-100}>
            <TD>{formatTime(new Date(createdAt))}</TD>
            <TD>{customer && <CustomerLink customer={customer} />}</TD>
            <TD className="code tr">
              {displayCurrencyNumber(
                centsToDollars(amountInCents),
                PRODUCT_CURRENCY_MAP[product]
              )}
            </TD>
            <TD className="code tr">{customer && customer.shortId}</TD>
          </PosedTR>
        ))}
      </PoseGroup>
    </Table>
  );
}

function handleCreatePurchaseError(err: Error) {
  notifyUser(err, "Unexpected error adding mint");
}

function PurchasesContent() {
  const { loading, error, data } = useQuery(GET_MINTS);
  const [createMint] = useMutation(CREATE_MINT, {
    onError: handleCreatePurchaseError,
    onCompleted(data) {
      if (!data)
        handleCreatePurchaseError(
          new Error("Empty response when adding mint")
        );
    }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const mints = data.getMintsForMyBankingPartner;

  // async function handleSubmit(variables: OnSubmitData) {
  //   try {
  //     const result = await createMint({
  //       refetchQueries: [{ query: GET_MINTS }],
  //       variables
  //     });
  //     if (!result) {
  //       notifyUser(
  //         new Error("Empty response when adding mint"),
  //         "Unexpected error adding purchase"
  //       );
  //     }
  //   } catch (error) {
  //     notifyUser(error, "Unexpected error adding mint");
  //   }
  // }

  return (
    <>
      {/* <div className="ba pa3 mb4">
        <b className="db mb2">Add Mint</b>
        <AddPurchaseForm onSubmit={handleSubmit} />
      </div> */}
      {mints.length > 0 ? (
        <PurchasesTable purchases={mints} />
      ) : (
        <div className="tc pv5 f1">Add some mints above ↑</div>
      )}
    </>
  );
}

export function Purchases() {
  return (
    <div>
      <ScrollToTopOnMount />
      <h1>TrueCurrencies Mints</h1>
      <PurchasesContent />
    </div>
  );
}
