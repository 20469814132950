import { OrgMember } from "~types/types";

export function cleanPersonForApi(person: OrgMember) {
  const {
    firstName,
    middleName,
    lastName,
    email,
    dateOfBirth,
    nationalIdNumber,
    address,
    ownershipPercentage,
    governmentIdCountry,
  } = person;

  return {
    firstName,
    middleName,
    lastName,
    // email,
    dateOfBirth,
    nationalIdNumber,
    governmentIdCountry,
    ownershipPercentage,
    address: address
      ? {
          street: address.street,
          subpremise: address.subpremise,
          locality: address.locality,
          stateOrProvince: address.stateOrProvince,
          postalCode: address.postalCode,
          country: address.country
        }
      : null
  };
}
