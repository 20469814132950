import * as React from "react";
import { Button } from "./FormElements";

export function ShowLast4({ value }: { value: string }) {
  const [showAll, setShowAll] = React.useState(false);
  if (!value) return null;
  return (
    <span>
      <span className="code">
        {showAll ? (
          value
        ) : (
          <span>
            <span className="black-10">
              {"X".repeat(value.slice(0, -4).length)}
            </span>
            {value.slice(-4)}
          </span>
        )}
      </span>
      <small>
        <Button className="ml1" onClick={() => setShowAll(v => !v)}>
          {showAll ? "🙈" : "👀"}
        </Button>
      </small>
    </span>
  );
}
