import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { ApolloError } from "~common/ApolloError";
import { ErrorBox } from "~common/ErrorBox";
import { FlexColumn, VSpace } from "~common/Layout";
import { Loading } from "~common/Loading";
import { Section } from "~common/Section";
import { notifyUser } from "~common/util";
import { UploadedDocument } from "~types/types";
import { AuthorizedUserDocuments } from "./AuthorizedUserDocuments";
import { Column, ColumnBody, ColumnSectionHeading } from "./Column";

const ADD_DOCUMENT_TO_ORGANIZATION = gql`
  mutation($orgId: ID, $document: AddDocumentOnboardingInput) {
    addDocumentToOrganizationForOnboarding(id: $orgId, input: $document) {
      id
    }
  }
`;

const ADD_DOCUMENT_TO_PERSON = gql`
  mutation($personId: ID, $document: AddDocumentOnboardingInput) {
    addDocumentToPersonForOrganizationOnboarding(
      personId: $personId
      input: $document
    ) {
      id
    }
  }
`;

export const GET_ORG_AUTHORIZED_USER = gql`
  query($rootOrgId: ID) {
    getOrganization(id: $rootOrgId) {
      id
      authorizedPersons {
        id
        userId
      }
    }
  }
`;

/**
 * Show documents associated with the root org's authorized user.
 * Allow adding these documents to either an org or a person.
 */
export function ColumnAvailableDocuments({
  rootOrgId,
  orgId,
  personId
}: {
  rootOrgId: string;
  orgId?: string;
  personId?: string;
}) {
  const { loading, error, data } = useQuery(GET_ORG_AUTHORIZED_USER, {
    variables: { rootOrgId },
    fetchPolicy: "network-only"
  });

  const [addDocumentToPerson] = useMutation(ADD_DOCUMENT_TO_PERSON, {
    onCompleted: () => alert("Added"),
    onError: err => notifyUser(err, "Error adding document")
  });

  const [addDocumentToOrganization] = useMutation(
    ADD_DOCUMENT_TO_ORGANIZATION,
    {
      onCompleted: () => alert("Added"),
      onError: err => notifyUser(err, "Error adding document")
    }
  );

  function handleDocumentSelect(doc: UploadedDocument) {
    const documentInput = {
      slug: doc.slug,
      purpose: doc.purpose,
      type: doc.type
    };

    if (personId) {
      addDocumentToPerson({
        variables: {
          personId,
          document: documentInput
        }
      });
    } else if (orgId) {
      addDocumentToOrganization({
        variables: {
          orgId,
          document: documentInput
        }
      });
    } else {
      throw new Error("Need an orgId or a personId in the URL");
    }
  }

  if (loading) {
    return (
      <Column>
        <ColumnBody>
          <Loading />
        </ColumnBody>
      </Column>
    );
  }
  if (error) return <ApolloError error={error} />;

  const org = data.getOrganization;

  if (!(org && org.authorizedPersons && org.authorizedPersons[0])) {
    return (
      <ErrorBox title="Organization doesn't have an authorized user">
        Please select a person from the persons list in the left column and set
        one of them as the authorized user.
      </ErrorBox>
    );
  }

  const { userId } = org.authorizedPersons[0];

  return (
    <FlexColumn style={{ minWidth: "50em" }}>
      <Section>
        <ColumnSectionHeading>Available Documents</ColumnSectionHeading>
        <ColumnBody>
          <VSpace space={3}>
            {/* <JsonView
              json={{
                rootOrgId,
                orgId,
                personId
              }}
            /> */}
            <AuthorizedUserDocuments
              userId={userId}
              onDocumentSelect={
                orgId || personId ? handleDocumentSelect : undefined
              }
            />
          </VSpace>
        </ColumnBody>
      </Section>
    </FlexColumn>
  );
}
