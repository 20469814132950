import * as React from "react";
import { ExecutionResult } from "@apollo/react-common";

import { formatTime, notifyUser } from "~/common/util";
import { UserContext } from "~/common/UserContext";
import { ErrorBox } from "~/common/ErrorBox";
import { Button } from "~/common/FormElements";

/**
 * Requires returning promise so we know to clear current user input
 */
export type CreateNote = (note: string) => Promise<ExecutionResult<any>>;

type Note = {
  id: string;
  content: string;
  createdAt: string;
};

type Props = {
  notes: Note[];
  onCreateNote: CreateNote;
};

export function Notes({ notes, onCreateNote }: Props) {
  const [note, setNote] = React.useState("");
  const user = React.useContext(UserContext);

  if (!user.id) {
    return (
      <ErrorBox>Cannot display or add notes unless you're logged in</ErrorBox>
    );
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    if (!note) {
      alert("Empty notes not allowed");
      return;
    }

    const noteWithEmail = `${note} - ${user.email}`;

    onCreateNote(noteWithEmail)
      .then(() => setNote(""))
      .catch(err => notifyUser(err, "Error adding note"));
  }

  return (
    <>
      {notes.map(note => (
        <div key={note.id}>
          {note.content}
          <small className="db mt1 gray">
            {formatTime(new Date(note.createdAt))}
          </small>
        </div>
      ))}
      {(!notes || notes.length === 0) && <div className="gray">No Notes</div>}
      <form onSubmit={handleSubmit}>
        <textarea
          className="db mb1 w-100"
          value={note}
          onChange={e => setNote(e.target.value)}
        />
        <Button className="db ml-auto">Add Note</Button>
      </form>
    </>
  );
}
