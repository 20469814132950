import * as React from "react";
import { Switch, Route } from "react-router";
import { ApolloProvider } from "@apollo/react-hooks";

import { UserContext } from "~/common/UserContext";
import { NotFound } from "~/common/NotFound";

// Routes
import { Dashboard } from "./Dashboard";
import { Purchases } from "./Purchases";
import { Redemptions } from "./Redemptions";

// Other
import { Page, NotAllowedPage } from "~/partner-portal/Page";
import { apolloClient } from "~/partner-portal/apolloClient";

export function PartnerRoutes() {
  const user = React.useContext(UserContext);

  if (!user.roles.map(r => r.name).includes("banking_partner")) {
    return <NotAllowedPage />;
  }

  return (
    <ApolloProvider client={apolloClient}>
      <Page>
        <Switch>
          <Route exact path="/partner" component={Dashboard} />
          <Route path="/partner/mints" component={Purchases} />
          <Route path="/partner/redemptions" component={Redemptions} />
          <Route component={NotFound} />
        </Switch>
      </Page>
    </ApolloProvider>
  );
}
