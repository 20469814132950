import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { Address } from "~/common/Address";
import { ApolloError } from "~/common/ApolloError";
import { KV } from "~/common/KV";
import { VSpace } from "~/common/Layout";
import { Loading } from "~/common/Loading";
import { Section, SectionHeading } from "~/common/Section";
import { ShowLast4 } from "~/common/ShowLast4";
import { DocumentPreviews } from "~common/DocumentPreviews";
import { Org as OrgType } from "~types/types";
import { OrgMembers } from "./OrgMembers";

const GET_ORG = gql`
  query($orgId: ID) {
    getOrganization(id: $orgId) {
      id
      name
      taxIdNumber
      regionOfFormation {
        country
        state
      }
      address {
        street
        subpremise
        locality
        stateOrProvince
        postalCode
        country
      }
      documents {
        id
        type
        side
        signedUrl
        signedThumbnailUrl
      }
      beneficialOwners {
        documents {
          id
          type
          side
          signedUrl
          signedThumbnailUrl
        }
      }
    }
  }
`;

export function Org({ orgId }: { orgId: string }) {
  const { loading, error, data } = useQuery(GET_ORG, {
    variables: { orgId }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const org: OrgType = data.getOrganization;

  return (
    <VSpace space={4}>
      <VSpace space={1}>
        <KV k="Name" v={org.name} />
        <KV
          k="Tax ID"
          v={
            org.taxIdNumber && <ShowLast4 value={org.taxIdNumber.toString()} />
          }
        />
        <KV k="Address" v={<Address address={org.address} />} />
        <KV
          k="Region of Formation Country"
          v={org.regionOfFormation && org.regionOfFormation.country}
        />
        {/* If location outside of US, then state is unset */}
        <KV
          k="Region of Formation State"
          v={org.regionOfFormation && org.regionOfFormation.state}
          unknownOk
        />
      </VSpace>

      <VSpace space={3}>
        <OrgMembers orgId={orgId} />
        <Section>
          <SectionHeading className="bb">Documents</SectionHeading>
          <DocumentPreviews documents={org.documents} />
        </Section>
      </VSpace>
    </VSpace>
  );
}
