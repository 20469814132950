import styled from "styled-components";

// TODO: See this for sizing https://github.com/trusttoken/trusttoken-web/blob/2813de8d06683c6bb3e63da05895ae97905917cf/packages/partner-portal/src/common/Layout.tsx#L226
const V_PADDING = "0.25em";
const H_PADDING = "0.5em";

const shared = `
  color: inherit;
  border-color: inherit;
  border: 1px solid #00000044;
  border-radius: 3px;
  padding: ${V_PADDING} ${H_PADDING};
`;

export const Input = styled.input`
  ${shared};
  width: 100%;
  &[type="checkbox"] {
    width: initial;

    &:hover {
      cursor: pointer;
    }
  }
  &[type="radio"] {
    width: initial;
    margin-top: calc(${V_PADDING} + 2px);
    margin-bottom: calc(${V_PADDING} + 2px);

    &:hover {
      cursor: pointer;
    }
  }
`;

export const Select = styled.select`
  ${shared};
  // Note: calculate min-height from a normal <Input /> (based on padding, line-height, and font size)
  height: 28px;
`;

export const Button = styled.button`
  ${shared};
  &:hover {
    cursor: pointer;
    border-color: #00000099;
    box-shadow: 0 1px 2px #00000033;
  }
  & {
    background-image: linear-gradient(
      #ffffff44 20%,
      transparent 80%,
      #00000011
    );
  }
  &:active {
    color: inherit; /* for Safari */
  }
  &:disabled {
    opacity: 0.5;
  }
`;
