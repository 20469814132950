import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import styled from "styled-components";
import { ApolloError } from "~/common/ApolloError";
import { REJECTION_REASON_NOTES } from "~/common/application";
import { CustomerIdContext } from "~/common/CustomerIdContext";
import { Loading } from "~/common/Loading";
import { formatTime } from "~/common/util";
import { VSpace } from "~common/Layout";
import { Rejection, Submission } from "~types/types";

const GET_SUBMISSIONS = gql`
  query($customerId: ID) {
    getCustomer(id: $customerId) {
      id
      application {
        id
        submissions {
          id
          createdAt
          decision
          reviewedAt
          rejectionReasons {
            component
            code
            message
          }
        }
      }
    }
  }
`;

const Gray = styled.div.attrs({
  className: "gray"
})``;

const Red = styled.div.attrs({
  className: "red"
})``;

const Green = styled.div.attrs({
  className: "green"
})``;

export function Submissions() {
  const customerId = React.useContext(CustomerIdContext);

  const { loading, error, data } = useQuery(GET_SUBMISSIONS, {
    variables: { customerId }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const customer = data.getCustomer;
  const application = customer ? customer.application : null;
  const submissions = application ? application.submissions : [];

  return (
    <VSpace space={3}>
      {submissions.length === 0 && (
        <Gray>User has not submitted an application</Gray>
      )}
      {submissions.map((submission: Submission, index: number) => {
        const submissionNumber = submissions.length - index;
        return (
          <VSpace space={1} key={submission.id}>
            {!submission.decision && (
              <Gray>Latest submission is pending</Gray>
            )}
            {submission.decision === 'approved' && (
              <Green>
                Submission {submissionNumber}: Created At -{" "}
                {formatTime(new Date(submission.createdAt))}; Accepted At -{" "}
                {formatTime(new Date(submission.reviewedAt))}
              </Green>
            )}
            {submission.decision === 'rejected' && (
              <Red>
                Submission {submissionNumber}: Created At -{" "}
                {formatTime(new Date(submission.createdAt))}; Rejected At -{" "}
                {formatTime(new Date(submission.reviewedAt))}
              </Red>
            )}
            {submission.rejectionReasons &&
              submission.rejectionReasons.map(
                (rejectionReason: Rejection.Reason) => {
                  return (
                    <div
                      key={`${rejectionReason.component}-${rejectionReason.code}`}
                    >
                      {rejectionReason.code !== "other" &&
                        REJECTION_REASON_NOTES[rejectionReason.component][
                          rejectionReason.code
                        ]}
                      {rejectionReason.code === "other" &&
                        rejectionReason.message}
                    </div>
                  );
                }
              )}
          </VSpace>
        );
      })}
    </VSpace>
  );
}
