import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { ApolloError } from "~common/ApolloError";
import { Badge } from "~common/Badge";
import { Button } from "~common/FormElements";
import { HRule, VSpace } from "~common/Layout";
import { Section, SectionHeading } from "~common/Section";
import { notifyUser } from "~common/util";
import { PrimaryComplianceButtons } from "~internal-portal/PrimaryComplianceButtons";
import { Org, Org as OrgType } from "~types/types";
import { Application } from "./Application";
import { cleanOrgForApi } from "./cleanOrgForApi";
import {
  Column,
  ColumnBody,
  ColumnSectionHeading,
  LoadingColumn
} from "./Column";
import { AddDocumentsSection } from "./DocumentsSection";
import { LinkToNewColumn } from "./LinkToNewColumn";
import { OrgFormikForm } from "./OrgFormikForm";

const GET_ORG = gql`
  query($orgId: ID) {
    getOrganization(id: $orgId) {
      id
      name
      taxIdNumber
      entityType
      regionOfFormation {
        country
        state
      }
      address {
        street
        subpremise
        locality
        stateOrProvince
        postalCode
        country
      }
      documents {
        id
        slug
        signedUrl
        purpose
        type
        side
      }
      persons {
        id
        firstName
        middleName
        lastName
        email
        userId
        isAuthorizedUser
      }
      ownerOrganizations {
        name
        id
      }
    }
  }
`;

export const UPDATE_ORG = gql`
  mutation($orgId: ID, $org: OrganizationInput) {
    updateOrganization(id: $orgId, input: $org) {
      id
    }
  }
`;

export const DELETE_ORG = gql`
  mutation($parentOrgId: ID, $orgId: ID) {
    deleteOwnerOrganizationFromOrganization(
      id: $parentOrgId
      ownerOrganizationId: $orgId
    )
  }
`;

export function ColumnOrg({
  url,
  customerId,
  parentOrgId,
  orgId,
  onClose
}: {
  url: string;
  parentOrgId?: string;
  customerId?: string;
  orgId: string;
  onClose: () => void;
}) {
  const history = useHistory()
  const { loading, error, data, refetch } = useQuery(GET_ORG, {
    variables: { orgId }
  });

  const [updateOrg] = useMutation(UPDATE_ORG, {
    onCompleted: () => alert("Updated organization"),
    onError: err => notifyUser(err, "Error updating organization")
  });

  const [deleteOrg] = useMutation(DELETE_ORG, {
    onError: err => notifyUser(err, "Error updating organization")
  });

  // ---

  function handleSaveOrg(org: Org) {
    updateOrg({
      variables: {
        orgId,
        org: cleanOrgForApi(org)
      }
    }).then(() => refetch());
  }

  React.useEffect(() => {
    const unlisten = history.listen((historyLocation) => {
      refetch()
    })
    return () => {
      unlisten()
    }
  }, [history])

  if (loading) return <LoadingColumn />;
  if (error) return <ApolloError error={error} />;

  const org: OrgType = data.getOrganization;

  if (!orgId || !org) {
    return (
      <Column>
        <ColumnSectionHeading onClose={onClose}>
          No Organization
        </ColumnSectionHeading>
        <ColumnBody>
          <div className="black-50">No Organization</div>
        </ColumnBody>
      </Column>
    );
  }

  return (
    <Column>
      <Section>
        <ColumnSectionHeading onClose={onClose}>
          <span className="brown">{org.name || "UNKNOWN Organization Name"}</span>
        </ColumnSectionHeading>
        <ColumnBody>
          <VSpace space={3}>
            {/* <JsonView json={{ url, customerId, parentOrgId, orgId }} /> */}
            {customerId && (
              <>
                <Link
                  to={`/internal/customers/${customerId}`}
                  className="blue link underline"
                >
                  Go to Customer Page
                </Link>
                <Application customerId={customerId} />
                <div className="bt b--black-10" />
              </>
            )}
            <Formik
              initialValues={org}
              onSubmit={handleSaveOrg}
              component={OrgFormikForm}
            />
            <VSpace space={3}>
              <AddDocumentsSection documents={org.documents} organizationId={org.id} />
              <Section>
                <SectionHeading>Persons</SectionHeading>
                <div className="bg-black-05 pa3 flex flex-column">
                  <VSpace space={1}>
                    {org.persons &&
                      org.persons.length > 0 &&
                      org.persons.map(person => (
                        <LinkToNewColumn
                          key={person.id}
                          to={`${url}/persons/${person.id}`}
                        >
                          {person.firstName} {person.lastName}{" "}
                          {person.isAuthorizedUser && (
                            <Badge>Authorized User</Badge>
                          )}{" "}
                        </LinkToNewColumn>
                      ))}
                    <LinkToNewColumn to={`${url}/add-person`}>
                      + Add Person
                    </LinkToNewColumn>
                  </VSpace>
                </div>
              </Section>
              <Section>
                <SectionHeading>Owner Organizations</SectionHeading>
                <div className="bg-black-05 pa3 flex flex-column">
                  <VSpace space={1}>
                    {org.ownerOrganizations &&
                      org.ownerOrganizations.length > 0 &&
                      org.ownerOrganizations.map(owner => (
                        <LinkToNewColumn
                          key={owner.id}
                          to={`${url}/orgs/${owner.id}`}
                        >
                          {owner.name}
                        </LinkToNewColumn>
                      ))}
                    <LinkToNewColumn to={`${url}/add-org`}>
                      + Add Organization
                    </LinkToNewColumn>
                  </VSpace>
                </div>
              </Section>
            </VSpace>
            {parentOrgId && (
              <>
                <HRule />
                <Button
                  className="w-100 bg-washed-red"
                  onClick={() => {
                    deleteOrg({ variables: { parentOrgId, orgId } });
                    onClose();
                  }}
                >
                  Delete Organization
                </Button>
              </>
            )}
          </VSpace>
        </ColumnBody>
        {!parentOrgId && (
          <>
            <HRule />
            <PrimaryComplianceButtons />
          </>
        )}
      </Section>
    </Column>
  );
}
