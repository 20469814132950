import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { useState, useEffect } from "react";
import { Address } from "~/common/Address";
import { Annotated } from "~/common/Annotated";
import { ApolloError } from "~/common/ApolloError";
import { Button } from "~/common/FormElements";
import { CustomerIdContext } from "~/common/CustomerIdContext";
import { UserContext } from "~/common/UserContext";
import { LABELS } from "~/common/labels";
import { VSpace } from "~/common/Layout";
import { Loading } from "~/common/Loading";
import { Section, SectionHeading } from "~/common/Section";
import { Select } from "~common/Select";
import { IsUnknownBad } from "~/common/Unknown";
import { ShowLast4 } from "~common/ShowLast4";
import { Customer } from "~types/types";
import { formatShortDate } from "~/common/util";
import { isTreasuryTeamMember } from "~/lib/userGroup";

const GET_BANK_INFO = gql`
  query($customerId: ID, $product: String, $currency: String) {
    getCustomer(id: $customerId) {
      id
      customerBankingPartner(currency: $currency) {
        wireReference
        externalAccountId
        bankingPartner {
          id
          name
          internalName
        }
      }
      defaultBlockchainAccount(product: $product) {
        id
        blockchainId
        blockchainAddress
        exchange {
          value
          other
        }
        memo
      }
      blockchainAccountLogs {
        id
        createdAt
        action
        product
        blockchainAccountId
        blockchainAccount {
          id
          blockchainAddress
          blockchainId
        }
      }
      defaultBankAccount(currency: $currency) {
        id
        accountHolderName
        bankName
        accountNumber
        routingNumber
        swiftCode
        notes
        phoneNumber
        address {
          street
          subpremise
          locality
          stateOrProvince
          postalCode
          country
        }
        intermediaryBankName
        intermediaryBankSwiftCode
        intermediaryBankAddress {
          street
          subpremise
          locality
          stateOrProvince
          postalCode
          country
        }
      }
    }
  }
`;

//{ currency: "USD", bankingPartnerName: "primetrust" }
const CHANGE_CUSTOMER_BANKING_PARTNER = gql`
  mutation ChangeCustomerBankingPartner($id: ID, $input: ChangeCustomerBankingPartnerInput) {
    changeCustomerBankingPartner(id: $id, input: $input) {
      bankingPartner {
        internalName
      }
    }
  }
`;

const TREASURY_CUSTOMER_ID = '43853176607081035';

function ChangeCustomerBankingPartner(props) {
  const product = props.product;
  const currency = props.currency;
  const initialBankingPartner = props.initialBankingPartner;
  const onSubmit = props.onSubmit;
  const onCancel = props.onCancel;

  const customerId = React.useContext(CustomerIdContext);

  const [currentBankingPartner, setCurrentBankingPartner] = useState(initialBankingPartner);

  return (
    <>
      <Annotated
        label={"Partner Bank"}
        value={
          <Select
            value={currentBankingPartner}
            options={[
              { label: "Prime Trust, LLC", value: "primetrust" },
              { label: "1st Digital Trust", value: "legacytrust" },
              { label: "Silvergate Bank", value: "silvergate" },
              { label: "Signature Bank", value: "signet" }
            ]}
            onChange={(value) => setCurrentBankingPartner(value)}
          />
        }
      />
      <Button onClick={() => onSubmit(currentBankingPartner)}>Save</Button>
      <Button onClick={() => onCancel()}>Cancel</Button>
    </>
  );
}

export function BankInfo({ product, currency }: { product: string; currency:string; }) {
  const customerId = React.useContext(CustomerIdContext);
  const user = React.useContext(UserContext);
  const isTreasury = isTreasuryTeamMember(user);

  const [showBankingPartnerForm, setShowBankingPartnerForm] = useState(false);
  const [newBankingPartner, setNewBankingPartner] = useState('');

  const [changeCustomerBankingPartner] = useMutation(CHANGE_CUSTOMER_BANKING_PARTNER, {
    refetchQueries: [{
      query: GET_BANK_INFO,
      variables: { customerId, product, currency },
    }]
  });

  const { loading, error, data } = useQuery(GET_BANK_INFO, {
    variables: { customerId, product, currency }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const customer: Customer | undefined = data.getCustomer;
  const customerBankingPartner = customer?.customerBankingPartner;
  const bankingPartner = customerBankingPartner?.bankingPartner;
  const account = customer?.defaultBankAccount;
  const blockchainAccount = customer?.defaultBlockchainAccount;
  const isSilvergate = customer?.customerBankingPartner?.bankingPartner?.internalName === 'silvergate';
  const isSignature = customer?.customerBankingPartner?.bankingPartner?.internalName === 'signet';
  const blockchainAccountLogsForProduct = customer?.blockchainAccountLogs?.filter((blockchainAccountLog) => {
    return blockchainAccountLog.product === product && blockchainAccountLog.action === 'added';
  });

  async function onChangeBankingPartnerSubmit(newBankingPartner) {
    const input = {
      currency,
      bankingPartnerName: newBankingPartner,
    };

    const changeBankingPartnerRes = await changeCustomerBankingPartner({
      variables: {
        id: customerId,
        input: input,
      }
    });

    setShowBankingPartnerForm(false);
    setTimeout(() => {
      alert(`Banking partner updated for ${product} successfully`);
    }, 500);
  }

  function onChangeBankingPartnerCancel() {
    setShowBankingPartnerForm(false);
  }

  console.log('customerId', customerId);
  console.log('treasuryId', TREASURY_CUSTOMER_ID);

  return (
    <VSpace space={4}>
      <VSpace space={3}>
        <Annotated
          label={LABELS.customer.customerBankingPartner.bankingPartner.name}
          value={
            bankingPartner && bankingPartner.name
          }
          unknownOk
        />
        {(!showBankingPartnerForm && isTreasury && customerId === TREASURY_CUSTOMER_ID) &&
          <Button onClick={() => setShowBankingPartnerForm(true)}>Update banking partner</Button>
        }
        {(showBankingPartnerForm && isTreasury && customerId === TREASURY_CUSTOMER_ID) &&
          <ChangeCustomerBankingPartner
            product={product}
            currency={currency}
            initialBankingPartner={bankingPartner.internalName}
            onSubmit={onChangeBankingPartnerSubmit}
            onCancel={onChangeBankingPartnerCancel}
          />
        }
        {isSilvergate && (
          <Annotated
            label={LABELS.customer.customerBankingPartner.trustTokenSenAccount}
            value="TRUECOIN - NETWORK 5090016543"
          />
        )}
        {isSignature && (
          <Annotated
            label={LABELS.customer.customerBankingPartner.trustTokenSignetAddress}
            value="S52004404bf5614fa2667558fbba7a76d9c16a2fd"
          />
        )}
        {!isSilvergate && !isSignature && (
          <Annotated
            label={LABELS.customer.customerBankingPartner.wireReference}
            value={
              customer &&
              customer.customerBankingPartner && (
                <code>{customer.customerBankingPartner.wireReference}</code>
              )
            }
            unknownOk
          />
        )}
        <Annotated
          label={LABELS.blockchainAccount.exchange}
          value={
            blockchainAccount &&
            blockchainAccount.exchange &&
            (blockchainAccount.exchange.other ||
              blockchainAccount.exchange.value)
          }
          unknownOk
        />

        <Annotated
          label={LABELS.blockchainAccount.blockchainAddress}
          value={
            blockchainAccount &&
            blockchainAccount.blockchainAddress && (
              <code style={{ wordBreak: "break-all" }}>
                {blockchainAccount.blockchainAddress}
              </code>
            )
          }
          unknownOk
        />

        <Annotated
          label={LABELS.blockchainAccount.memo}
          value={
            blockchainAccount &&
            blockchainAccount.memo && (
              <code style={{ wordBreak: "break-all" }}>
                {blockchainAccount.memo}
              </code>
            )
          }
          unknownOk
        />
        <Annotated
          label={'Blockchain Account Changelog'}
          value={<div>
            <ul className="mt1">
              {blockchainAccountLogsForProduct.map((blockchainAccountLog) => {
                const blockchainAccount = blockchainAccountLog.blockchainAccount;
                const date = formatShortDate(blockchainAccountLog.createdAt).replace(/\//g, '-');
                const blockchainId = blockchainAccount.blockchainId
                const blockchainAddress = blockchainAccount.blockchainAddress

                return (
                  <li key={blockchainAccountLog.id}>
                    {date}: Updated to {blockchainId} address <code>{blockchainAddress}</code>
                  </li>
                );
              })}
            </ul>
          </div>}
        />

        {account && (
          <>
            <div className="bb b--black-10" />
            <Annotated
              label={LABELS.bank.accountHolderName}
              value={account.accountHolderName}
            />
            {(isSilvergate || isSignature) ? (
              <>
                <Annotated
                  label={LABELS.bank.bankName}
                  value={
                    customer &&
                    customer.customerBankingPartner &&
                    customer.customerBankingPartner.bankingPartner &&
                    customer.customerBankingPartner.bankingPartner.name
                  }
                />
                <Annotated
                  label={isSilvergate ? LABELS.bank.senAccount : LABELS.bank.signetAddress}
                  value={
                    <ShowLast4
                      value={
                        customer &&
                        customer.customerBankingPartner &&
                        customer.customerBankingPartner.externalAccountId || ""
                      }
                    />
                  }
                  unknownOk
                />
                {isSilvergate && (
                  <>
                    <Annotated
                      label={LABELS.bank.address}
                      value={<Address address={{
                        street: '4250 Executive Square',
                        subpremise: 'Suite 300',
                        locality: 'La Jolla',
                        stateOrProvince: 'CA',
                        postalCode: '92037',
                        country: 'US',
                      }} />}
                    />
                  </>
                )}
                {isSignature && (
                  <Annotated
                    label={LABELS.bank.address}
                    value={<Address address={{
                      street: '565 Fifth Avenue',
                      locality: 'New York',
                      stateOrProvince: 'NY',
                      postalCode: '10017',
                      country: 'US',
                    }} />}
                  />
                )}
              </>
            ) : (
              <>
                <Annotated label={LABELS.bank.bankName} value={account.bankName} />
                <Annotated
                  label={LABELS.bank.accountNumber}
                  value={<ShowLast4 value={account.accountNumber || ""} />}
                  unknownOk
                />
                <Annotated
                  label={LABELS.bank.routingNumber}
                  value={account.routingNumber}
                  unknownOk
                />
                <Annotated
                  label={LABELS.bank.swiftCode}
                  value={account.swiftCode}
                  unknownOk
                />
                <Annotated
                  label={LABELS.bank.notes}
                  value={account.notes}
                  unknownOk
                />
                <Annotated
                  label={LABELS.bank.address}
                  value={<Address address={account.address} />}
                />
              </>
            )}
            <Section>
              <VSpace space={3}>
                <SectionHeading className="bb">
                  {LABELS.bank.intermediaryHeading}
                </SectionHeading>
                <IsUnknownBad.Provider value={false}>
                  <Annotated
                    label={LABELS.bank.bankName}
                    value={account.intermediaryBankName}
                  />
                  <Annotated
                    label={LABELS.bank.swiftCode}
                    value={account.intermediaryBankSwiftCode}
                  />
                  <Annotated
                    label={LABELS.bank.address}
                    value={
                      <Address address={account.intermediaryBankAddress} />
                    }
                  />
                </IsUnknownBad.Provider>
              </VSpace>
            </Section>
          </>
        )}
      </VSpace>
    </VSpace>
  );
}
