import * as React from "react";
import { ErrorBoundary } from "./ErrorBoundary";
import { Section, SectionHeading } from "./Section";

type TabProps = {
  name: string;
  children: React.ReactNode;
};

export function Tab({ children }: TabProps) {
  // Wrapping in <> so TypeScript stops complaining
  return <>{children}</>;
}

export function Tabs({
  tabFontSize,
  children
}: {
  tabFontSize?: number;
  children: React.ReactElement<TabProps>[] | React.ReactElement<TabProps>;
}) {
  const childrenArr = React.Children.toArray(children);

  if (childrenArr.length < 1) throw new Error("Must have at least 1 tab");
  if (!childrenArr.every(child => child.type === Tab)) {
    throw new Error("All children must be instances of <Tab /> component");
  }

  const firstChild = childrenArr[0];
  const [activeName, setActiveName] = React.useState(firstChild.props.name);
  const activeTab = childrenArr.find(child => child.props.name === activeName);

  return (
    // Need div because don't want to return list of elements
    // Note: <Section /> doesn't render and UI
    <div>
      <Section>
        {/* Tab buttons */}
        <div className="flex bb">
          <SectionHeading className={tabFontSize ? `f${tabFontSize}` : ""}>
            {React.Children.map(children, child => {
              if (!child) return null;
              const maybeActiveClass =
                child.props.name === activeName
                  ? "bg-black white"
                  : "bg-black-10";

              return (
                <button
                  className={`mr1 b--none br0 pv1 ph2 ${maybeActiveClass}`}
                  onClick={() => setActiveName(child.props.name)}
                >
                  {child.props.name}
                </button>
              );
            })}
          </SectionHeading>
        </div>
        {/* Tab display */}
        <ErrorBoundary>{activeTab}</ErrorBoundary>
      </Section>
    </div>
  );
}
