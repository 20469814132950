// TODO: move most type definitions to types.d.ts to avoid cyclical dependency

import * as Sentry from "@sentry/browser";
import axios from "axios";
/*
  Using Big.js because:
  - It solves the rounding issue with currencies
  - It's small, minimal, and super popular
  - Hard to mess things up
    EX: Dinero library accepts decimal values even though it's not meant to be used that way

  https://frontstuff.io/how-to-handle-monetary-values-in-javascript
  https://timleland.com/money-in-javascript/
  https://medium.com/@magnusjt/how-to-handle-money-in-javascript-b954d612373c
  https://news.ycombinator.com/item?id=18334865
*/
import Big, { BigSource } from "big.js";
import * as React from "react";
import {
  DocumentPurpose,
  DocumentType,
  EntityType,
  Option,
  OrgAccountPurpose,
  OrgMonthlyVolume,
  PersonAccountPurpose,
  PersonMonthlyVolume
} from "~types/types";

export function dollarsToCents(dollars: BigSource): number {
  const result = new Big(dollars).times(100);

  // Ideally, we'd never come to this
  if (result.toString() !== result.round().toString()) {
    throw new Error("Cents has too many decimal places");
  }

  return parseInt(result.toString());
}

export function centsToDollars(cents: BigSource) {
  return new Big(cents).div(100);
}

// TODO: move to types.d.ts
/** Currencies should also be valid for `number.toLocaleString` for currencies */
export type Currency = "CNH" | "USD";
export type EthereumProduct = "TCNH" | "TUSD";
export type BinanceProduct =
  | "TUSDB-888";
export type Product = EthereumProduct | BinanceProduct;

// TODO: maps stolen from truecurrency package, consolidate
// https://github.com/trusttoken/transaction-service/blob/5625ef1017f9ab5ce484dfd3f1517b640582e7ec/trusttoken_transaction/constants.py

// export const CURRENCIES: Currency[] = ["USD", "HKD", "GBP", "AUD", "CAD"];
export const CURRENCIES: Currency[] = ["CNH"];
export const ETHEREUM_PRODUCTS: EthereumProduct[] = [
  "TUSD",
  "TCNH",
];
export const BINANCE_PRODUCTS: BinanceProduct[] = [
  "TUSDB-888",
];

export const PRODUCT_CURRENCY_MAP: Record<
  EthereumProduct | BinanceProduct,
  Currency
> = {
  TCNH: "CNH",
  TUSD: "USD",
  "TUSDB-888": "USD",
};

// Not 1-1 with others on purpose
export const CURRENCY_PRODUCT_MAP: Record<Currency, EthereumProduct> = {
  CNH: "TCNH",
  USD: "TUSD",
};

// TODO: stolen from truecurrency package, consolidate
export function getCookie(cname: string) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let cookie of ca) {
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}

export function cleanGraphQLErrorMessage(messageString) {
  return messageString.replace(/GraphQL error: /g, '');
}

export function displayCurrencyNumber(number: Big, currency: Currency) {
  // return number.toString()
  return new Intl.NumberFormat('zh-CN', {
    style: 'currency',
    currency: 'CNY',
  }).format(Number(number.toString()));
}

export function formatTime(date: Date) {
  return new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
    hour12: false
  }).format(date);
}

export function formatShortDate(dateString) {
  const date = new Date(dateString);

  return new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true
  }).format(date);
}

export function formatDateString(dateString) {
  const date = new Date(dateString);

  return new Intl.DateTimeFormat("default", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
    hour12: false
  }).format(date);
}

export function isImageFileName(name: string) {
  return /.(bmp|jpg|jpeg|png)$/.test(name.toLowerCase());
}

export function isIFrameFileName(name: string) {
  return /.pdf$/.test(name.toLowerCase());
}

type SignedRequestData = {
  fields: Record<string, any> & Record<"key", any>;
  url: string;
};

async function signFileWithS3(file: File) {
  return axios
    .get<SignedRequestData>(`/api/s3/sign?file_name=${file.name}`)
    .then(res => {
      if (!res.data) {
        throw new Error("Can't sign file S3");
      }
      return res;
    });
}

function uploadFileToS3(file: File, signedReqData: SignedRequestData) {
  const postData = new FormData();

  Object.keys(signedReqData.fields).map((key: string) => {
    postData.append(key, signedReqData.fields[key]);
  });

  postData.append("file", file);

  return axios.post(signedReqData.url, postData, {
    headers: { "x-amz-acl": "public-read" }
  });
}

async function uploadAndSignFile(file: File) {
  const signedReqData = await signFileWithS3(file);
  await uploadFileToS3(file, signedReqData.data);
  const uploadSlug = signedReqData.data.fields.key;

  return uploadSlug;
}

export function useFileUpload() {
  const fileRef = React.useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [slug, setSlug] = React.useState("");

  async function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files || e.target.files.length === 0) return;

    try {
      const file = e.target.files[0];
      setIsUploading(true);
      // This is the file name of the uploaded document
      const uploadSlug = await uploadAndSignFile(file);

      setSlug(uploadSlug);
    } catch (error) {
      notifyUser(error, "Upload failed");
      setSlug("");
    }
  }

  /**
   * Reset uploading state and file input
   */
  function resetUpload() {
    if (fileRef.current) {
      fileRef.current.value = "";
    }
    setIsUploading(false);
  }

  // When slug value changes, it means we either sucessfully
  // uploaded a file, or we failed to. Either way, we want to
  // reset some things
  React.useEffect(resetUpload, [slug]);

  return {
    isUploading,
    slug,
    inputProps: {
      type: "file",
      ref: fileRef,
      onChange: handleFileChange
    },
    reset: () => setSlug("")
  };
}

// TODO: If user session is expired, we want to display an error for that
// Alert is not the best way
export function notifyUser(error: any, message: string) {
  Sentry.captureException(error);
  alert(message + '\n\n' + error?.message);
}

export function hashToEtherScanUrl(hash: string) {
  return `https://etherscan.io/tx/${hash}`;
}

export function hashToAvalancheScanUrl(hash: string) {
  return `https://cchain.explorer.avax.network/tx/${hash}/token-transfers`;
}

export function hashToBinanceUrl(hash: string) {
  return `https://explorer.binance.org/tx/${hash}`;
}

export function hashToTronUrl(hash: string) {
  return `https://tronscan.org/#/transaction/${hash}`;
}

export function alertNotImplemented() {
  alert("Not implemented. Please use the black admin panel.");
}

export type PrimaryIdentity =
  | "driver_license"
  | "government_id"
  | "passport"
  | "resident_permit";

export const PRIMARY_IDENTITY_TYPES: Record<string, PrimaryIdentity> = {
  DRIVER_LICENSE: "driver_license",
  GOVERNMENT_ID: "government_id",
  PASSPORT: "passport",
  RESIDENT_PERMIT: "resident_permit"
};

export const PRIMARY_IDENTITY_TYPE_LIST: Option<PrimaryIdentity>[] = [
  // Passport is accepted with least caveats, so put at top
  // Also only requires a single picture
  {
    label: "Passport",
    value: PRIMARY_IDENTITY_TYPES.PASSPORT
  },
  {
    label: "Government Issued ID",
    value: PRIMARY_IDENTITY_TYPES.GOVERNMENT_ID
  },
  {
    label: "Driver's License",
    value: PRIMARY_IDENTITY_TYPES.DRIVER_LICENSE
  },
  // Keep at the end because other IDs are better
  {
    label: "Resident's Permit",
    value: PRIMARY_IDENTITY_TYPES.RESIDENT_PERMIT
  }
];

export type SecondaryIdentity =
  | "cell_phone_bill"
  | "utility_bill"
  | "lease_agreement"
  | "bank_credit_card_statement"
  | "payroll_stub";

export const SECONDARY_IDENTITY_TYPES: Record<string, SecondaryIdentity> = {
  CELL_PHONE_BILL: "cell_phone_bill",
  UTILITY_BILL: "utility_bill",
  LEASE_AGREEMENT: "lease_agreement",
  BANK_STATEMENT: "bank_credit_card_statement",
  PAYROLL_STUB: "payroll_stub"
};

export const SECONDARY_IDENTITY_TYPE_LIST: Option<SecondaryIdentity>[] = [
  {
    label: "Cell Phone Bill",
    value: SECONDARY_IDENTITY_TYPES.CELL_PHONE_BILL
  },
  {
    label: "Utility Bill",
    value: SECONDARY_IDENTITY_TYPES.UTILITY_BILL
  },
  // {
  //   label: 'Lease Agreement',
  //   value: SECONDARY_IDENTITY_TYPES.LEASE_AGREEMENT,
  // },
  {
    label: "Original Bank or Credit Card Statement",
    value: SECONDARY_IDENTITY_TYPES.BANK_STATEMENT
  },
  {
    label: "Payroll Check Stub",
    value: SECONDARY_IDENTITY_TYPES.PAYROLL_STUB
  }
];

export const INDIVIDUAL_MONTHLY_VOLUMES: Option<PersonMonthlyVolume>[] = [
  { value: "1-100K", label: "1 - 100K" },
  { value: "100K-500K", label: "100K - 500K" },
  { value: "500K-1M", label: "500K - 1M" },
  { value: "1M-5M", label: "1M – 5M" },
  { value: "5M+", label: "Over 5M" }
];

export const ORGANIZATION_MONTHLY_VOLUMES: Option<OrgMonthlyVolume>[] = [
  { value: "1-500K", label: "1 - 500K" },
  { value: "500K-1M", label: "500K to 1 mil" },
  { value: "1M-5M", label: "1 mil - 5 mil" },
  { value: "5M-15M", label: "5 mil – 15 mil" },
  { value: "15M+", label: "15 mil" }
];

export const INDIVIDUAL_PURPOSES: Option<PersonAccountPurpose>[] = [
  { value: "investment", label: "Investment" },
  { value: "cryptocurrency_trading", label: "Cryptocurrency Trading" },
  { value: "personal_trading", label: "Personal Trading" },
  { value: "commercial_trading", label: "Commercial Trading" },
  { value: "other", label: "Other" }
];

export const ORGANIZATION_PURPOSES: Option<OrgAccountPurpose>[] = [
  { value: "commercial_trading", label: "Commercial Trading" },
  { value: "cryptocurrency_trading", label: "Cryptocurrency Trading" },
  { value: "proprietary_trading", label: "Proprietary Trading" },
  { value: "other", label: "Other" }
];

export const ENTITY_TYPES: Option<EntityType>[] = [
  { label: "Corporation", value: "corporation" },
  { label: "Partnership", value: "partnership" },
  { label: "Nonprofit", value: "nonprofit" },
  { label: "Trust", value: "trust" },
  {
    label: "Hedgefund / PE / VC",
    value: "hedgefund_pe_vc"
  },
  { label: "LLC", value: "llc" }
];

export const DOCUMENT_TYPE_TO_PURPOSE_MAP: Record<
  DocumentType,
  DocumentPurpose
> = {
  articles_of_incorporation: "articles_of_incorporation",
  bylaws_memorandum: "bylaws_memorandum",
  proof_of_address: "proof_of_address",
  organization_chart: "organization_chart",
  driver_license: "identity_verification",
  government_id: "identity_verification",
  passport: "identity_verification",
  resident_permit: "identity_verification",
  other: "other"
};

export const DOCUMENT_TYPE: Option<DocumentType>[] = [
  {
    value: "articles_of_incorporation",
    label: "Articles of Incorporation"
  },
  {
    value: "bylaws_memorandum",
    label: "Bylaws/Memorandum"
  },
  {
    value: "proof_of_address",
    label: "Address Verification"
  },
  {
    value: "organization_chart",
    label: "Org Chart"
  },
  {
    value: "driver_license",
    label: "Driver's License"
  },
  {
    value: "government_id",
    label: "Government ID"
  },
  {
    value: "passport",
    label: "Passport"
  },
  {
    value: "resident_permit",
    label: "Resident Permit"
  },
  {
    value: "other",
    label: "Other"
  }
];

export const STATES_LIST = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District Of Columbia", value: "DC" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" }
];

export const COUNTRY_LIST = [
  ["AF", "Afghanistan"],
  ["AX", "Åland Islands"],
  ["AL", "Albania"],
  ["DZ", "Algeria"],
  ["AS", "American Samoa"],
  ["AD", "Andorra"],
  ["AO", "Angola"],
  ["AI", "Anguilla"],
  ["AQ", "Antarctica"],
  ["AG", "Antigua and Barbuda"],
  ["AR", "Argentina"],
  ["AM", "Armenia"],
  ["AW", "Aruba"],
  ["AU", "Australia"],
  ["AT", "Austria"],
  ["AZ", "Azerbaijan"],
  ["BS", "Bahamas"],
  ["BH", "Bahrain"],
  ["BD", "Bangladesh"],
  ["BB", "Barbados"],
  ["BY", "Belarus"],
  ["BE", "Belgium"],
  ["BZ", "Belize"],
  ["BJ", "Benin"],
  ["BM", "Bermuda"],
  ["BT", "Bhutan"],
  ["BO", "Bolivia"],
  ["BA", "Bosnia and Herzegovina"],
  ["BW", "Botswana"],
  ["BV", "Bouvet Island"],
  ["BR", "Brazil"],
  ["IO", "British Indian Ocean Territory"],
  ["BN", "Brunei Darussalam"],
  ["BG", "Bulgaria"],
  ["BF", "Burkina Faso"],
  ["BI", "Burundi"],
  ["KH", "Cambodia"],
  ["CM", "Cameroon"],
  ["CA", "Canada"],
  ["CV", "Cape Verde"],
  ["KY", "Cayman Islands"],
  ["CF", "Central African Republic"],
  ["TD", "Chad"],
  ["CL", "Chile"],
  ["CN", "China"],
  ["CX", "Christmas Island"],
  ["CC", "Cocos (Keeling) Islands"],
  ["CO", "Colombia"],
  ["KM", "Comoros"],
  ["CG", "Congo"],
  ["CD", "Congo, The Democratic Republic of the"],
  ["CK", "Cook Islands"],
  ["CR", "Costa Rica"],
  ["CI", `Cote D'Ivoire`],
  ["HR", "Croatia"],
  ["CU", "Cuba"],
  ["CY", "Cyprus"],
  ["CZ", "Czech Republic"],
  ["DK", "Denmark"],
  ["DJ", "Djibouti"],
  ["DM", "Dominica"],
  ["DO", "Dominican Republic"],
  ["EC", "Ecuador"],
  ["EG", "Egypt"],
  ["SV", "El Salvador"],
  ["GQ", "Equatorial Guinea"],
  ["ER", "Eritrea"],
  ["EE", "Estonia"],
  ["ET", "Ethiopia"],
  ["FK", "Falkland Islands (Malvinas)"],
  ["FO", "Faroe Islands"],
  ["FJ", "Fiji"],
  ["FI", "Finland"],
  ["FR", "France"],
  ["GF", "French Guiana"],
  ["PF", "French Polynesia"],
  ["TF", "French Southern Territories"],
  ["GA", "Gabon"],
  ["GM", "Gambia"],
  ["GE", "Georgia"],
  ["DE", "Germany"],
  ["GH", "Ghana"],
  ["GI", "Gibraltar"],
  ["GR", "Greece"],
  ["GL", "Greenland"],
  ["GD", "Grenada"],
  ["GP", "Guadeloupe"],
  ["GU", "Guam"],
  ["GT", "Guatemala"],
  ["GG", "Guernsey"],
  ["GN", "Guinea"],
  ["GW", "Guinea-Bissau"],
  ["GY", "Guyana"],
  ["HT", "Haiti"],
  ["HM", "Heard Island and Mcdonald Islands"],
  ["VA", "Holy See (Vatican City State)"],
  ["HN", "Honduras"],
  ["HK", "Hong Kong"],
  ["HU", "Hungary"],
  ["IS", "Iceland"],
  ["IN", "India"],
  ["ID", "Indonesia"],
  ["IR", "Iran, Islamic Republic Of"],
  ["IQ", "Iraq"],
  ["IE", "Ireland"],
  ["IM", "Isle of Man"],
  ["IL", "Israel"],
  ["IT", "Italy"],
  ["JM", "Jamaica"],
  ["JP", "Japan"],
  ["JE", "Jersey"],
  ["JO", "Jordan"],
  ["KZ", "Kazakhstan"],
  ["KE", "Kenya"],
  ["KI", "Kiribati"],
  ["KP", `Korea, Democratic People's Republic of`],
  ["KR", "Korea, Republic of"],
  ["KW", "Kuwait"],
  ["KG", "Kyrgyzstan"],
  ["LA", `Lao People's Democratic Republic`],
  ["LV", "Latvia"],
  ["LB", "Lebanon"],
  ["LS", "Lesotho"],
  ["LR", "Liberia"],
  ["LY", "Libyan Arab Jamahiriya"],
  ["LI", "Liechtenstein"],
  ["LT", "Lithuania"],
  ["LU", "Luxembourg"],
  ["MO", "Macao"],
  ["MK", "Macedonia, The Former Yugoslav Republic of"],
  ["MG", "Madagascar"],
  ["MW", "Malawi"],
  ["MY", "Malaysia"],
  ["MV", "Maldives"],
  ["ML", "Mali"],
  ["MT", "Malta"],
  ["MH", "Marshall Islands"],
  ["MQ", "Martinique"],
  ["MR", "Mauritania"],
  ["MU", "Mauritius"],
  ["YT", "Mayotte"],
  ["MX", "Mexico"],
  ["FM", "Micronesia, Federated States of"],
  ["MD", "Moldova, Republic of"],
  ["MC", "Monaco"],
  ["MN", "Mongolia"],
  ["ME", "Montenegro"],
  ["MS", "Montserrat"],
  ["MA", "Morocco"],
  ["MZ", "Mozambique"],
  ["MM", "Myanmar"],
  ["NA", "Namibia"],
  ["NR", "Nauru"],
  ["NP", "Nepal"],
  ["NL", "Netherlands"],
  ["AN", "Netherlands Antilles"],
  ["NC", "New Caledonia"],
  ["NZ", "New Zealand"],
  ["NI", "Nicaragua"],
  ["NE", "Niger"],
  ["NG", "Nigeria"],
  ["NU", "Niue"],
  ["NF", "Norfolk Island"],
  ["MP", "Northern Mariana Islands"],
  ["NO", "Norway"],
  ["OM", "Oman"],
  ["PK", "Pakistan"],
  ["PW", "Palau"],
  ["PS", "Palestinian Territory, Occupied"],
  ["PA", "Panama"],
  ["PG", "Papua New Guinea"],
  ["PY", "Paraguay"],
  ["PE", "Peru"],
  ["PH", "Philippines"],
  ["PN", "Pitcairn"],
  ["PL", "Poland"],
  ["PT", "Portugal"],
  ["PR", "Puerto Rico"],
  ["QA", "Qatar"],
  ["RE", "Reunion"],
  ["RO", "Romania"],
  ["RU", "Russian Federation"],
  ["RW", "Rwanda"],
  ["SH", "Saint Helena"],
  ["KN", "Saint Kitts and Nevis"],
  ["LC", "Saint Lucia"],
  ["PM", "Saint Pierre and Miquelon"],
  ["VC", "Saint Vincent and the Grenadines"],
  ["WS", "Samoa"],
  ["SM", "San Marino"],
  ["ST", "Sao Tome and Principe"],
  ["SA", "Saudi Arabia"],
  ["SN", "Senegal"],
  ["RS", "Serbia"],
  ["SC", "Seychelles"],
  ["SL", "Sierra Leone"],
  ["SG", "Singapore"],
  ["SK", "Slovakia"],
  ["SI", "Slovenia"],
  ["SB", "Solomon Islands"],
  ["SO", "Somalia"],
  ["ZA", "South Africa"],
  ["GS", "South Georgia and the South Sandwich Islands"],
  ["ES", "Spain"],
  ["LK", "Sri Lanka"],
  ["SD", "Sudan"],
  ["SR", "Suriname"],
  ["SJ", "Svalbard and Jan Mayen"],
  ["SZ", "Swaziland"],
  ["SE", "Sweden"],
  ["CH", "Switzerland"],
  ["SY", "Syrian Arab Republic"],
  ["TW", "Taiwan"],
  ["TJ", "Tajikistan"],
  ["TZ", "Tanzania, United Republic of"],
  ["TH", "Thailand"],
  ["TL", "Timor-Leste"],
  ["TG", "Togo"],
  ["TK", "Tokelau"],
  ["TO", "Tonga"],
  ["TT", "Trinidad and Tobago"],
  ["TN", "Tunisia"],
  ["TR", "Turkey"],
  ["TM", "Turkmenistan"],
  ["TC", "Turks and Caicos Islands"],
  ["TV", "Tuvalu"],
  ["UG", "Uganda"],
  ["UA", "Ukraine"],
  ["AE", "United Arab Emirates"],
  ["GB", "United Kingdom"],
  ["US", "United States"],
  ["UM", "United States Minor Outlying Islands"],
  ["UY", "Uruguay"],
  ["UZ", "Uzbekistan"],
  ["VU", "Vanuatu"],
  ["VE", "Venezuela"],
  ["VN", "Viet Nam"],
  ["VG", "Virgin Islands, British"],
  ["VI", "Virgin Islands, U.S."],
  ["WF", "Wallis and Futuna"],
  ["EH", "Western Sahara"],
  ["YE", "Yemen"],
  ["ZM", "Zambia"],
  ["ZW", "Zimbabwe"]
].map(([code, name]) => ({ label: name, value: code }));
