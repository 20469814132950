import * as React from 'react';
import styled from 'styled-components';

import { Annotated } from '~common/Annotated';
import { LABELS } from '~common/labels';
import { HexAddress } from '~lib/HexAddress';
import { CopyToClipboard } from '~common/CopyToClipboard';

// Binance const and functions below should be moved to some common library
const BINANCE_CHAIN_OWNER_ADDRESS = 'bnb100dxzy02a6k7vysc5g4kk4fqamr7jhjg4m83l0';

const hexAddressToTron = (address: string): string => {
  const tron_hex = "41" + address.substring(2);
  return HexAddress.toBase58(tron_hex);
}

const toBinanceMemo = (address: string): string => {
  return address.substr(2).replace(/^0+(?!$)/, '');
}

const Address = styled.code.attrs({
  className: 'db',
})`
  word-break: break-all;
`;

interface Props {
  addresses: { redemptionAddress: string }[];
}

export function CustomerRedemptionAddresses({ addresses }: Props) {
  return (
    <>
      <Annotated
        label={`Ethereum / Avalanche ${LABELS.customer.redemptionAddresses}`}
        value={
          addresses.map(address => (
            <Address key={address.redemptionAddress}>
              {address.redemptionAddress}
              <CopyToClipboard textToCopy={`Ethereum / Avalanche ${LABELS.customer.redemptionAddresses}\n${address.redemptionAddress}`} />
            </Address>
          ))
        }
      />
      <div style={{ display: 'flex', gap: '1em' }}>
        <Annotated
          label={`Binance Chain ${LABELS.customer.redemptionAddresses}`}
          value={
            addresses.map(address => (
              <Address key={address.redemptionAddress}>
                {BINANCE_CHAIN_OWNER_ADDRESS}
              </Address>
            ))
          }
        />
        <Annotated
          label={`Binance Chain ${LABELS.customer.redemptionMemo}`}
          value={
            addresses.map(address => (
              <Address key={address.redemptionAddress}>
                {toBinanceMemo(address.redemptionAddress)}
                <CopyToClipboard textToCopy={`Binance Chain ${LABELS.customer.redemptionAddresses}\n${BINANCE_CHAIN_OWNER_ADDRESS}\nBinance Chain ${LABELS.customer.redemptionMemo}\n${toBinanceMemo(address.redemptionAddress)}`} />
              </Address>
            ))
          }
        />
      </div>
      <Annotated
        label={`Tron ${LABELS.customer.redemptionAddresses}`}
        value={
          addresses.map(address => (
            <Address key={address.redemptionAddress}>
              {hexAddressToTron(address.redemptionAddress)}
              <CopyToClipboard textToCopy={`Tron ${LABELS.customer.redemptionAddresses}\n${hexAddressToTron(address.redemptionAddress)}`} />
            </Address>
          ))
        }
      />
    </>
  );
}
