import * as React from "react";
import { VSpace } from "~/common/Layout";
import { MODULE_NAME_TO_LABEL_MAP } from "~common/application";
import { Checkbox } from "~common/CheckboxSelect";
import { Button } from "~common/FormElements";
import { Modal } from "~common/Modal";
import { Todo } from "~common/Todo";
import { Rejection } from "~types/types";

export function CustomerModulesRejectButton({ moduleName, onReject, disabled }) {
  const [showModal, setShowModal] = React.useState(false);
  // TODO: Consider doing more advanced state management

  const [poorImage, setPoorImage] = React.useState(false);
  const [poorImageMessage, setPoorImageMessage] = React.useState("");
  const [notPassport, setNotPassport] = React.useState(false);
  const [notPassportMessage, setNotPassportMessage] = React.useState("");
  const [other, setOther] = React.useState(false);
  const [otherMessage, setOtherMessage] = React.useState("");

  return (
    <>
      <Button
        disabled={disabled}
        className="bg-light-red"
        onClick={() => setShowModal(!showModal)}
      >
        Reject
      </Button>
      {showModal && (
        <Modal title="Reject" onClose={() => setShowModal(false)}>
          <form
            onSubmit={e => {
              e.preventDefault();
              let rejectionReasons: Rejection.ModuleReason[] = [];
              if (poorImage) {
                rejectionReasons.push({
                  code: "poor_image_quality",
                  message: poorImageMessage
                });
              }
              if (notPassport) {
                rejectionReasons.push({
                  code: "not_passport",
                  message: poorImageMessage
                });
              }
              if (notPassport) {
                rejectionReasons.push({
                  code: "not_passport",
                  message: poorImageMessage
                });
              }
              if (other) {
                rejectionReasons.push({
                  code: "other",
                  message: otherMessage
                });
              }
              onReject(rejectionReasons);
            }}
          >
            <VSpace space={2}>
              {(() => {
                switch (moduleName) {
                  case "sanction_screening":
                    return <Todo />;
                  case "government_id":
                    return (
                      <>
                        <b>{MODULE_NAME_TO_LABEL_MAP["government_id"]}</b>
                        <VSpace space={1}>
                          <Checkbox
                            label="Poor image quality"
                            value={poorImage}
                            onChange={setPoorImage}
                          />
                          {poorImage && (
                            <textarea
                              value={poorImageMessage}
                              onChange={e =>
                                setPoorImageMessage(e.target.value)
                              }
                            />
                          )}
                          <Checkbox
                            label="Not a passport"
                            value={notPassport}
                            onChange={setNotPassport}
                          />
                          {notPassport && (
                            <textarea
                              value={notPassportMessage}
                              onChange={e =>
                                setNotPassportMessage(e.target.value)
                              }
                            />
                          )}
                        </VSpace>
                      </>
                    );
                  case "selfie":
                    return <Todo />;
                  case "us_ssn_check":
                    return <Todo />;
                  case "liveness":
                    return <Todo />;
                  default:
                    break;
                }
              })()}
              <b>Other</b>
              <Checkbox label="Other" value={other} onChange={setOther} />
              {other && (
                <textarea
                  value={otherMessage}
                  onChange={e => setOtherMessage(e.target.value)}
                />
              )}
              <Button type="submit">Confirm Reject</Button>
            </VSpace>
          </form>
        </Modal>
      )}
    </>
  );
}
