import * as React from "react";

import { notifyUser } from "~/common/util";
import { HSpace } from "~/common/Layout";
import { Button } from "~/common/FormElements";

export function TestSentry() {
  const [shouldCrash, setShouldCrashed] = React.useState(false);
  if (shouldCrash) {
    // Ignore undefined function
    // @ts-ignore
    callUndefinedFunction();
  }
  return (
    <HSpace space={1}>
      <Button
        onClick={() => {
          throw new Error("Test error");
        }}
      >
        Throw Event Error
      </Button>
      <Button
        onClick={() => {
          try {
            throw new Error("Test notify error");
          } catch (error) {
            notifyUser(error, "TEST EXAMPLE MESSAGE");
          }
        }}
      >
        Throw Event Error + Notify User
      </Button>
      <Button onClick={() => setShouldCrashed(true)}>Crash Component</Button>
    </HSpace>
  );
}
