import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { NavLink, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { ApolloError } from "~/common/ApolloError";
import { Loading } from "~/common/Loading";
import { NotFound } from "~/common/NotFound";
import { ColumnPage } from "~/common/Page";

const GET_CUSTOMER_FOR_ORGANIZATION = gql`
query($id: ID) {
  getCustomerForOrganization(organizationId: $id) {
    id
  }
}
`;


export const Organization = withRouter(({ match }) => {
  const { organizationId } = match.params;
  const { loading, error, data } = useQuery(GET_CUSTOMER_FOR_ORGANIZATION, {
    variables: { id: organizationId }
  });

  if (loading) {
    return (
      <ColumnPage>
        <Loading />
      </ColumnPage>
    );
  }

  // API returns an error if no customer found, so using this workaround
  if (!data || !data.getCustomerForOrganization) {
    return (
      <ColumnPage>
        <NotFound />
      </ColumnPage>
    );
  }

  // Even if `data` exists, we might still have an error
  if (error) {
    return (
      <ColumnPage>
        <ApolloError error={error} />
      </ColumnPage>
    );
  }

  const customerId = data.getCustomerForOrganization.id;

  return (
    <Redirect exact to={`/internal/customers/${customerId}`} />
  );
});
