import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { ApolloError } from "~/common/ApolloError";
import { CustomerIdContext } from "~/common/CustomerIdContext";
import { Button } from "~/common/FormElements";
import { HSpace, VSpace } from "~/common/Layout";
import { Loading } from "~/common/Loading";
import { notifyUser } from "~/common/util";
import { ApprovalStatus } from "./ApprovalStatus";
import { RejectButton } from "./RejectButton";
import { getApplicationBucketNames } from '~lib/application';

type Status = "new" | "presubmitted" | "submitted" | "approved" | "rejected";

const GET_CUSTOMER_COMPLIANCE = gql`
  query($customerId: ID) {
    getCustomer(id: $customerId) {
      id
      deactivatedAt
      application {
        id
        status
        applicationBucket {
          watr
          truecurrency
          truefi
          other {
            name
          }
        }
        latestSubmission {
          createdAt
          reviewedAt
          decision
        }
      }
    }
  }
`;

const SUBMIT = gql`
  mutation($customerId: ID) {
    submitCustomerApplication(customerId: $customerId) {
      id
    }
  }
`;

const APPROVE = gql`
  mutation($customerId: ID) {
    approveCustomerApplication(customerId: $customerId) {
      id
    }
  }
`;

const DEACTIVATE = gql`
  mutation($customerId: ID) {
    deactivateCustomer(customerId: $customerId) {
      id
    }
  }
`;

const REJECT_BECAUSE_DEACTIVATED = gql`
  mutation($customerId: ID) {
    deactivateCustomer(customerId: $customerId) {
      id
    }
    rejectCustomerApplication(
      customerId: $customerId
      input: {
        rejectionReasons: {
          code: "other"
          message: "Rejecting because the customer was deactivated"
        }
      }
    ) {
      id
    }
  }
`;

const REACTIVATE = gql`
  mutation($customerId: ID) {
    reactivateCustomer(customerId: $customerId) {
      id
    }
  }
`;

export function PrimaryComplianceButtons() {
  const customerId = React.useContext(CustomerIdContext);
  const { loading, error, data, refetch } = useQuery(GET_CUSTOMER_COMPLIANCE, {
    errorPolicy: "all",
    variables: { customerId }
  });

  const [submit] = useMutation(SUBMIT, {
    variables: {
      customerId
    },
    onError: err => notifyUser(err, "Error submitting customer"),
    onCompleted: refetch
  });

  const [approve] = useMutation(APPROVE, {
    variables: {
      customerId
    },
    onError: err => notifyUser(err, "Error approving customer"),
    onCompleted: refetch
  });

  const [deactivateForReal] = useMutation(DEACTIVATE, {
    variables: {
      customerId
    },
    onError: err => notifyUser(err, "Error deactivating user"),
    onCompleted: refetch
  });

  const [rejectBecauseDeactivated] = useMutation(REJECT_BECAUSE_DEACTIVATED, {
    variables: {
      customerId
    },
    onError: err => notifyUser(err, "Error rejecting user"),
    onCompleted: refetch
  });

  function deactivate() {
    if (
      window.confirm(
        `Are you sure you want to DEACTIVATE this user?`
      )
    ) {
      deactivateForReal();
    }
  }

  const [reactivate] = useMutation(REACTIVATE, {
    variables: {
      customerId
    },
    onError: err => notifyUser(err, "Error reactivating customer"),
    onCompleted: refetch
  });

  if (loading) return <Loading />;
  if (error && !data) return <ApolloError error={error} />;

  const customer = data.getCustomer;
  const application = customer?.application;
  const applicationBucketNames = getApplicationBucketNames(application?.applicationBucket);
  const status = customer?.application?.status as Status;

  let bgColor = {
    new: "bg-washed-yellow",
    submitted: "bg-washed-yellow",
    approved: "bg-washed-green",
    rejected: "bg-washed-yellow"
  }[status];

  return (
    <div>
      <VSpace space={2} className={`pv3 ph4 ${bgColor}`}>
        <div className="f3">{applicationBucketNames.map(name => <div key={name}>{name}</div>)}</div>
        <ApprovalStatus
          customer={{
            application: {
              status,
              latestSubmission: customer.application.latestSubmission
            },
            deactivatedAt: customer.deactivatedAt
          }}
        />
        <HSpace space={1}>
          {(status === "new" || status === "presubmitted") && <Button onClick={() => submit()}>Submit</Button>}
          {status === "submitted" && (
            <>
              <Button onClick={() => approve()}>Approve</Button>
              <RejectButton />
            </>
          )}
          {status === "approved" && <RejectButton />}
          {status === "rejected" && (
            <Button onClick={() => submit()}>Submit</Button>
          )}
          {customer.deactivatedAt &&
            <Button onClick={() => reactivate()}>Reactivate</Button>
          }
          {!customer.deactivatedAt && status !== 'submitted' &&
            <Button onClick={() => deactivate()}>Deactivate</Button>
          }

        </HSpace>
      </VSpace>
    </div>
  );
}
