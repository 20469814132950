import xor from "lodash-es/xor";
import * as React from "react";
import { Option } from "~types/types";
import { Input } from "./FormElements";

type SelectProps<T> = {
  /**
   * Note: Option.value should not repeat
   * BAD:
   * `[{value: 'a', label: "A"}, {value: 'a', label: "A"}] */
  options: Option<T>[];
  checkedValues: T[];
  onChange: (values: T[]) => void;
};

export function Checkbox({
  label,
  value,
  onChange
}: {
  label: React.ReactNode;
  value: boolean;
  onChange: (value: boolean) => void;
}) {
  return (
    <label className="db flex">
      <Input
        checked={value}
        type="checkbox"
        onChange={e =>
          // Add option.value if not there, remove if it is there
          onChange(e.target.checked)
        }
      />{" "}
      <span className="ml2">{label}</span>
    </label>
  );
}

/**
 * Note: Ignores `checkedValues` that aren't in `options`
 */
function CheckboxSelect<T>({
  checkedValues = [],
  onChange,
  options
}: SelectProps<T>) {
  return (
    <>
      {options.map((option, index) => {
        return (
          <Checkbox
            key={index}
            label={option.label}
            value={checkedValues.includes(option.value)}
            onChange={() =>
              // Add option.value if not there, remove if it is there
              onChange(xor(checkedValues, [option.value]))
            }
          />
        );
      })}
    </>
  );
}

export { CheckboxSelect };
