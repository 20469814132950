import * as React from "react";
import { DocumentPreviews } from "~common/DocumentPreviews";
import { VSpace } from "~common/Layout";
import { Section, SectionHeading } from "~common/Section";
import { UploadedDocument } from "~types/types";

interface Props {
  documents?: UploadedDocument[];
  personId?: string,
  organizationId?: string,
}

export function AddDocumentsSection({
  documents,
  personId,
  organizationId,
}: Props) {
  return (
    <Section>
      <SectionHeading>Documents</SectionHeading>
      <div className="bg-black-05 pa3 flex flex-column">
        <VSpace space={1}>
          <DocumentPreviews
            documents={documents}
            showDelete={true}
            personId={personId}
            organizationId={organizationId}
          />
        </VSpace>
      </div>
    </Section>
  );
}
