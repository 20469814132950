import * as React from "react";
import { NavLink } from "react-router-dom";
import { UserContext } from "./UserContext";

export function NavColumn() {
  const user = React.useContext(UserContext);

  return (
    <div
      className="bg-black-05 flex-grow-1 flex flex-column"
      // `minWidth: 0` because https://codepen.io/chriscoyier/pen/zqedEr
      style={{ minWidth: 0 }}
    >
      <div className="pa3">
        <div className="db f7 black-50">TCNH</div>
        <div className="db b f4 black-60">Internal Portal</div>
      </div>
      <div className="flex-grow-1 flex flex-column">
        {/* <NavLink
          to="/internal/preliminary-modules"
          className="blue link pv2 ph3 hover-bg-black-10"
          activeClassName="bg-black-05 br bw2"
        >
          Preliminary Modules
        </NavLink>
        <NavLink
          to="/internal/modules"
          className="blue link pv2 ph3 hover-bg-black-10"
          activeClassName="bg-black-05 br bw2"
        >
          Final Modules
        </NavLink> */}
        <NavLink
          to="/internal/pending-org-customers"
          className="blue link pv2 ph3 hover-bg-black-10"
          activeClassName="bg-black-05 br bw2"
        >
          Pending Organization Applicants
        </NavLink>
        {/* <NavLink
          to="/internal/individual-applicants"
          className="blue link pv2 ph3 hover-bg-black-10"
          activeClassName="bg-black-05 br bw2"
        >
          Individual Applicants
        </NavLink> */}
        <NavLink
          to="/internal/org-applicants"
          className="blue link pv2 ph3 hover-bg-black-10"
          activeClassName="bg-black-05 br bw2"
        >
          Organization Applicants
        </NavLink>
        {/* <NavLink
          to="/internal/external-applicants"
          className="blue link pv2 ph3 hover-bg-black-10"
          activeClassName="bg-black-05 br bw2"
        >
          External Client Applicants
        </NavLink> */}
        {/* <NavLink
          to="/internal/sanction-monitoring-alerts"
          className="blue link pv2 ph3 hover-bg-black-10"
          activeClassName="bg-black-05 br bw2"
        >
          Sanction Monitoring Alerts
        </NavLink> */}
        <div className="mv2 bt b--black-10" />
        <NavLink
          to="/internal/customers"
          className="blue link pv2 ph3 hover-bg-black-10"
          activeClassName="bg-black-05 br bw2"
        >
          Customers
        </NavLink>
        <NavLink
          to="/internal/mints"
          className="blue link pv2 ph3 hover-bg-black-10"
          activeClassName="bg-black-05 br bw2"
        >
          Mints
        </NavLink>
        <NavLink
          to="/internal/redemptions"
          className="blue link pv2 ph3 hover-bg-black-10"
          activeClassName="bg-black-05 br bw2"
        >
          Redemptions
        </NavLink>
        <NavLink
          to="/internal/user-groups"
          className="blue link pv2 ph3 hover-bg-black-10"
          activeClassName="bg-black-05 br bw2"
        >
          User Groups
        </NavLink>
      </div>
      <div className="pa3 bt b--black-10">
        <b className="db b truncate" title={user.email || ""}>
          {user.email}
        </b>
        <small className="flex justify-between mt1">
          <div className="db gray">
            Roles: {user.roles.map(r => r.name).join(", ")}
          </div>
          <a
            href={process.env.LOGOUT_URL}
            target="_blank"
            className="blue link flex-shrink-0"
          >
            Sign Out
          </a>
        </small>
      </div>
    </div>
  );
}
