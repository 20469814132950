import * as React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { CustomerIdContext } from "~/common/CustomerIdContext";
import { Loading } from "~/common/Loading";
import { ApolloError } from "~/common/ApolloError";
import { Unknown } from "~/common/Unknown";

const GET_CUSTOMER = gql`
  query($customerId: ID) {
    getCustomer(id: $customerId) {
      id
      person {
        id
        firstName
        lastName
        middleName
      }
      organization {
        id
        name
      }
    }
  }
`;

export function CustomerNameHeading() {
  const customerId = React.useContext(CustomerIdContext);
  const { loading, error, data } = useQuery(GET_CUSTOMER, {
    variables: { customerId }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const customer = data.getCustomer;

  if (!customer) return <Unknown />;

  const maybePersonName =
    customer.person &&
    customer.person.firstName + " " + customer.person.lastName;

  const maybeOrgName = customer.organization && customer.organization.name;

  return (
    <div>
      <div className="f7 black-50">Customer ID #{customer.id}</div>
      <div className="b f4">{maybePersonName || maybeOrgName}</div>
    </div>
  );
}
