import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { ApolloError } from "~common/ApolloError";
import { Checkbox } from "~common/CheckboxSelect";
import { ErrorBox } from "~common/ErrorBox";
import { Button } from "~common/FormElements";
import { HRule, VSpace } from "~common/Layout";
import { Section } from "~common/Section";
import { notifyUser } from "~common/util";
import { Org, OrgMember } from "~types/types";
import { cleanPersonForApi } from "./cleanPersonForApi";
import {
  Column,
  ColumnBody,
  ColumnSectionHeading,
  LoadingColumn
} from "./Column";
import { AddDocumentsSection } from "./DocumentsSection";
import { OrgMemberPersonFormikForm } from "./OrgMemberPersonFormikForm";

const GET_ORG = gql`
  query($orgId: ID) {
    getOrganization(id: $orgId) {
      id
      persons {
        id
        firstName
        middleName
        lastName
        email
        dateOfBirth
        nationalIdNumber
        governmentIdCountry
        address {
          street
          subpremise
          locality
          stateOrProvince
          postalCode
          country
        }
        isBeneficialOwner
        isAuthorizedUser
        ownershipPercentage
        documents {
          id
          slug
          signedUrl
          purpose
          type
          side
        }
      }
    }
  }
`;

const UPDATE_PERSON = gql`
  mutation($orgId: ID, $personId: ID, $person: OrganizationPersonInput) {
    updatePersonForOrganization(
      id: $orgId
      personId: $personId
      input: $person
    ) {
      id
    }
  }
`;

const UPDATE_PERSON_MEMBERSHIP = gql`
  mutation(
    $orgId: ID
    $personId: ID
    $personMembership: PersonOrgMembershipInput
  ) {
    updateOrganizationPersonMembership(
      id: $orgId
      personId: $personId
      input: $personMembership
    ) {
      id
    }
  }
`;

const DELETE_PERSON = gql`
  mutation($orgId: ID, $personId: ID) {
    deleteBeneficialOwnerFromOrganization(id: $orgId, personId: $personId)
  }
`;

function useOrgPerson(orgId: string, personId: string) {
  const { loading, error, data, refetch } = useQuery(GET_ORG, {
    variables: { orgId },
    fetchPolicy: "cache-and-network"
  });
  const [updatePerson] = useMutation(UPDATE_PERSON, {
    onCompleted: () => alert("Updated person"),
    onError: err => notifyUser(err, "Error updating person")
  });
  const [updatePersonMembership] = useMutation(UPDATE_PERSON_MEMBERSHIP, {
    onCompleted: () => alert("Updated person"),
    onError: err => notifyUser(err, "Error updating person")
  });
  const [deletePerson] = useMutation(DELETE_PERSON, {
    variables: { orgId, personId }
  });

  if (loading) {
    return {
      loading: true
    }
  }
  if (error) {
    return {
      loading: false,
      error: error
    }
  }

  const org: Org = data.getOrganization;

  let person = null;
  if (org && org.persons && org.persons.length > 0) {
    person = org.persons.find(p => p.id === personId);
  }

  function savePerson(person: OrgMember) {
    updatePerson({
      variables: {
        orgId,
        personId,
        person: cleanPersonForApi(person)
      }
    }).then(() => refetch());
  }

  // Ideally there would only be `savePerson()`
  function savePersonMembership(person: OrgMember) {
    updatePersonMembership({
      variables: {
        orgId,
        personId,
        personMembership: {
          isAuthorizedUser: person.isAuthorizedUser,
          isBeneficialOwner: person.isBeneficialOwner
        }
      }
    }).then(() => refetch());
  }

  return {
    loading,
    error,
    person,
    savePerson,
    savePersonMembership,
    deletePerson
  };
}

export function ColumnOrgMemberPerson({
  orgId,
  personId,
  onClose
}: {
  orgId: string;
  personId: string;
  onClose: () => void;
}) {
  const {
    loading,
    error,
    person,
    savePerson,
    savePersonMembership,
    deletePerson
  } = useOrgPerson(orgId, personId);

  if (loading) return <LoadingColumn />;
  if (error) return <ApolloError error={error} />;

  function handleDelete() {
    deletePerson();
    onClose();
  }

  if (!person) return <ErrorBox>No Person</ErrorBox>;
  return (
    <Column>
      <Section>
        <ColumnSectionHeading onClose={onClose}>
          {person.firstName || person.middleName || person.lastName
            ? `${person.firstName || ""} ${person.middleName ||
                ""} ${person.lastName || ""}`
            : "UNKNOWN Person name"}
        </ColumnSectionHeading>
        <ColumnBody>
          <VSpace space={3}>
            <Formik
              /*
              Adding `key` to force Formik to force re-rendering the component when the person changes.
              Not sure why Formik's shallow comparison thinks it's the same `person` object.
              Using the `enableReinitialize` prop is another way to solve this.
              */
              key={person.id}
              initialValues={person}
              onSubmit={savePerson}
              component={OrgMemberPersonFormikForm}
            />
            <Checkbox
              label="Beneficial Owner"
              value={person.isBeneficialOwner || false}
              onChange={isBeneficialOwner =>
                savePersonMembership({ ...person, isBeneficialOwner })
              }
            />
            <Checkbox
              label="Authorized User"
              value={person.isAuthorizedUser || false}
              onChange={isAuthorizedUser =>
                savePersonMembership({ ...person, isAuthorizedUser })
              }
            />
            <AddDocumentsSection documents={person.documents} personId={person.id}/>
            <HRule />
            <Button className="w-100 bg-washed-red" onClick={handleDelete}>
              Delete Person
            </Button>
          </VSpace>
        </ColumnBody>
      </Section>
    </Column>
  );
}
