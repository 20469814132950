import ApolloClient from "apollo-boost";

import { getCookie } from "~/common/util";
import { isFakeDataSource } from "~/common/isFakeDataSource";

const isFake = isFakeDataSource();

export const apolloClient = new ApolloClient({
  uri: isFake ? "/partner/fake/graphql" : "/partner/graphql",
  credentials: "same-origin",
  headers: {
    "CSRF-Token": getCookie("csrf_token")
  }
});
