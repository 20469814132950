import * as React from "react";
import { NavLink, Route, Switch } from "react-router-dom";

import TrueCNHMark from "./truecnh-mark.png";
import { UserContext } from "./UserContext";

const navLinkClassName =
  "link blue hover-bg-light-gray pa3 flex b items-center";
const navLinkActiveClassName = "bg-near-white";

function HomeLink({ name, url }: { name?: React.ReactNode, url: any }) {
  return (
    <NavLink
      to={url}
      exact
      className={navLinkClassName}
      activeClassName={navLinkActiveClassName}
    >
      <img src={TrueCNHMark} height="30px" />
      {name && <h1 className="mv0 ml2 f5">{name}</h1>}
    </NavLink>
  );
}

function PartnerLinks() {
  return (
    <>
      <HomeLink name="Banking Partner Portal" url="/partner" />
      <NavLink
        to="/partner/mints"
        className={navLinkClassName}
        activeClassName={navLinkActiveClassName}
      >
        <div>
          <div>Mints</div>
          <small className="gray normal">
            Incoming wires to mint
            <br />
            Money → TrueCurrencies
          </small>
        </div>
      </NavLink>
      <NavLink
        to="/partner/redemptions"
        className={navLinkClassName}
        activeClassName={navLinkActiveClassName}
      >
        <div>
          <div>Redemptions</div>
          <small className="gray normal">
            Outgoing wires to redeem
            <br />
            TrueCurrencies → Money
          </small>
        </div>
      </NavLink>
    </>
  );
}

function InternalLinks() {
  return (
    <>
      <HomeLink name="Internal Portal" url="/internal/customers" />
      <NavLink
        to="/internal/applicants"
        className={navLinkClassName}
        activeClassName={navLinkActiveClassName}
      >
        Applicants
      </NavLink>
      <NavLink
        to="/internal/customers"
        className={navLinkClassName}
        activeClassName={navLinkActiveClassName}
      >
        Customers
      </NavLink>
      <NavLink
        to="/internal/mints"
        className={navLinkClassName}
        activeClassName={navLinkActiveClassName}
      >
        Mints
      </NavLink>
      <NavLink
        to="/internal/redemptions"
        className={navLinkClassName}
        activeClassName={navLinkActiveClassName}
      >
        Redemptions
      </NavLink>
    </>
  );
}

function User() {
  const user = React.useContext(UserContext);

  return (
    <>
      <div className="pa3 flex items-center">{user.email}</div>
      <a
        href={process.env.LOGOUT_URL}
        className={navLinkClassName}
        target="_blank"
      >
        Sign Out
      </a>
    </>
  );
}

export function Nav() {
  return (
    <div className="bb ph4">
      <div className="flex flex-wrap justify-between nl3 nr3">
        <div className="flex items-stretch">
          <Switch>
            <Route path="/partner" component={PartnerLinks} />
            <Route path="/internal" component={InternalLinks} />
            <Route render={() => <HomeLink />} />
          </Switch>
        </div>
        <div className="flex items-stretch">
          <a
            href="mailto:official@trueusd.com"
            target="_blank"
            className={navLinkClassName}
          >
            Support
          </a>
          <User />
        </div>
      </div>
    </div>
  );
}
