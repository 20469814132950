import * as React from "react";
import styled from "styled-components";

import { VSpace } from "~/common/Layout";
import { Section, SectionHeading } from "~/common/Section";
import { Button } from "~/common/FormElements";

function BodyContent() {
  return (
    <div className="f2">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. A, ad. Possimus
      suscipit aliquid veniam in perferendis quam fuga modi nihil culpa. Lorem
      ipsum dolor sit amet consectetur adipisicing elit. A, ad. Possimus
      suscipit aliquid veniam in perferendis quam fuga modi nihil culpa. Magnam
      totam officiis repellendus nemo similique laborum iusto aliquam. Lorem
      ipsum dolor sit amet consectetur adipisicing elit. Repellendus, totam.
      {/* <div style={{ top: 0, position: "sticky", background: "white" }}>
        Lorem
      </div> */}
      Ullam expedita hic voluptatibus non, alias nulla soluta amet vitae
      obcaecati. Velit cumque beatae quae autem aliquid? Ut, sit laborum? Lorem
      ipsum, dolor sit amet consectetur adipisicing elit. Quod nostrum enim nam
      exercitationem. Aperiam perspiciatis similique possimus omnis cumque.
      Error quo maiores doloremque facere non ad aperiam excepturi deserunt
      ipsa! Lorem ipsum dolor sit amet consectetur adipisicing elit.
      {/* <div style={{ top: 0, position: "sticky", background: "white" }}>
        Ipsum
      </div>{" "} */}
      A, ad. Possimus suscipit aliquid veniam in perferendis quam fuga modi
      nihil culpa. Magnam totam officiis repellendus nemo similique laborum
      iusto aliquam. Lorem ipsum dolor sit amet consectetur adipisicing elit.
      Repellendus, totam. Ullam expedita hic voluptatibus non, alias nulla
      soluta amet vitae obcaecati. Velit cumque beatae quae autem aliquid? Ut,
      sit laborum? Lorem ipsum, dolor sit amet consectetur adipisicing elit.
      Quod nostrum enim nam exercitationem. Aperiam perspiciatis similique
      possimus omnis cumque. Error quo maiores doloremque facere non ad aperiam
      excepturi deserunt ipsa!
    </div>
  );
}

// function TestLayout2() {
//   return (
//     <div className="vh-100 flex">
//       <div className="measure-narrow flex-grow-1 flex-shrink-0 flex">
//         <Column header={<Heading />} body={<BodyContent />} />
//       </div>
//       <Column
//         header={<Heading />}
//         body={
//           <div className="flex-grow-1 flex overflow-auto">
//             <Column body={<BodyContent />} footer={<Heading />} />
//             <Column header={<Heading />} body={<BodyContent />} />
//             <Column body={<BodyContent />} />
//           </div>
//         }
//         footer={<Heading />}
//       />
//       <div className="flex flex-shrink-0">Hello</div>
//     </div>
//   );
// }

// export function TestLayout() {
//   return (
//     <PageSkeleton
//       // left={<Column header={<Heading />} body={<BodyContent />} />}
//       right={
//         <Column
//           header={<Heading />}
//           body={
//             <>
//               <Column body={<BodyContent />} footer={<Heading />} />
//               <Column header={<Heading />} body={<BodyContent />} />
//               <Column body={<BodyContent />} />
//             </>
//           }
//           footer={<Heading />}
//         />
//       }
//     />
//   );
// }

function width({ width }: { width: string }) {
  if (width) {
    return `
      flex-shrink: 0;
      flex-basis: ${width};
    `;
  }
  return "flex-grow: 1;";
}

const Col = styled.div<{ width?: unknown }>`
  display: flex;
  flex-direction: column;
  width: 0; // default to zero
  height: 100%;
  overflow: auto;
  ${width as any}
`;

// Use directly inside a <Col />
const ColBlock = styled.div<{ scroll?: boolean; grow?: boolean }>`
  overflow: auto;
  ${({ grow }) => (grow === true ? "flex-grow: 1;" : "flex-shrink: 0;")}
`;

function Page({
  title,
  children
}: {
  title?: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <div className="vh-100 flex">
      <Col className="bg-yellow" width="15rem">
        <div className="bg-black-10 pv4 ph3">TrustToken</div>
      </Col>
      <Col className="bg-black-10">
        <Section>
          {title ? (
            <VSpace space={4} className="pv5 ph4">
              <SectionHeading>{title}</SectionHeading>
              {children}
            </VSpace>
          ) : (
            children
          )}
        </Section>
      </Col>
    </div>
  );
}

export function TestLayout() {
  return (
    <div>
      <Page title="Testing">
        <BodyContent />
        <BodyContent />
        <BodyContent />
        <BodyContent />
      </Page>
      <Page>
        <ColBlock className="flex flex-row" grow>
          <Col className="bg-light-blue">
            <div className="pa2">Header</div>
            <ColBlock className="flex flex-row bg-black-10" grow>
              <Col className="bg-light-green">
                <BodyContent />
                <BodyContent />
                <BodyContent />
              </Col>
              <Col className="bg-light-red" width="22rem">
                <ColBlock grow>
                  <BodyContent />
                </ColBlock>
                <div className="pa3 bt b--black-20 flex flex-column">
                  <div className="flex">
                    <Button className="f3 flex-grow-1">Approve</Button>
                    <div className="mr1 mt1" />
                    <Button className="f3 flex-grow-1">Reject</Button>
                  </div>
                  <div className="mr1 mt1" />
                  <Button className="flex-grow-1">Ask for more info</Button>
                </div>
              </Col>
            </ColBlock>
            <div className="pa2">Footer</div>
          </Col>
        </ColBlock>
      </Page>
      <div className="vh-100 flex">
        <Col className="bg-yellow">
          <div>Hello</div>
          <ColBlock grow className="bg-black-10 pa5 flex flex-row">
            <Col className="bg-light-blue">
              <div>Hello</div>
              <ColBlock grow scroll className="bg-black-10 pa2">
                <BodyContent />
              </ColBlock>
              <div>Hello</div>
            </Col>
            <Col className="bg-light-green">
              <div>Hello</div>
              <ColBlock grow scroll className="bg-black-10">
                <BodyContent />
              </ColBlock>
            </Col>
          </ColBlock>
          <div>Hello</div>
        </Col>
      </div>
      <div className="vh-100 flex">
        <Col className="bg-black-10" width="15rem">
          <div className="bg-light-red">Test</div>
          <ColBlock className="bg-yellow">Hello</ColBlock>
          <ColBlock scroll style={{ height: "10rem" }} className="pa3">
            <BodyContent />
          </ColBlock>
          <ColBlock grow className="bg-black-10">
            <div className="flex justify-center items-center h-100">
              Lorem ipsum dolor sit
            </div>
          </ColBlock>
          <ColBlock>Footer</ColBlock>
        </Col>
        <Col className="bg-light-green">
          <ColBlock>Hello</ColBlock>
          <ColBlock scroll grow className="bg-black-10 pa4">
            <BodyContent />
          </ColBlock>
          <ColBlock>Hello</ColBlock>
        </Col>
        <Col className="bg-light-blue">
          <ColBlock>Hello</ColBlock>
          <ColBlock scroll grow className="bg-black-10">
            <Col className="bg-light-blue">
              <ColBlock>Hello</ColBlock>
              <ColBlock scroll grow className="bg-black-10">
                <div className="bg-black-10 ma2 pa4">
                  <BodyContent />
                </div>
              </ColBlock>
              <ColBlock>Hello</ColBlock>
            </Col>
          </ColBlock>
          <ColBlock>Hello</ColBlock>
        </Col>
        <Col className="bg-light-red" width="20rem">
          <ColBlock scroll grow className="bg-black-30">
            <BodyContent />
          </ColBlock>
        </Col>
      </div>
    </div>
  );
}
