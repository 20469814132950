import * as React from "react";
import * as Sentry from "@sentry/browser";
import { ErrorBox } from "./ErrorBox";
import { Modal } from "./Modal";
// import { DemoModeStatus } from "./DemoModeStatus";
import { NotAllowed } from "./NotAllowed";
import { ErrorResponse } from "apollo-link-error";
import { ApolloError as AError } from "apollo-boost";

function MaybeDevelopment({
  children,
  className
}: {
  children: React.ReactNode;
  className: string;
}) {
  if (process.env.NODE_ENV === "development") {
    return (
      <div className={`ba pa2 bg-washed-yellow ${className}`}>
        <b className={`db ${children && "mb1"}`}>NODE_ENV === 'development'</b>
        {children}
      </div>
    );
  }
  return null;
}

function SignInModal() {
  const currUrlEncoded = encodeURIComponent(window.location.href);

  const loginRedirectUrl = `${process.env.LOGIN_REDIRECT_URL}?next=${currUrlEncoded}`;

  return (
    <Modal title="Please Sign In">
      <a href={loginRedirectUrl} className="db mt2 ba pa3 blue link underline">
        Sign In
      </a>
      {/* <MaybeDevelopment className="mt2"> */}
        {/* <DemoModeStatus /> */}
      {/* </MaybeDevelopment> */}
    </Modal>
  );
}

export function ApolloError({
  error = "MUST_BE_SET"
}: {
  error: AError | "MUST_BE_SET";
}) {
  if (process.env.NODE_ENV === "development") debugger;

  // TODO: Fix Apollo Error weirdness when this gets resolved
  // https://github.com/apollographql/apollo-link/issues/536
  const e = (error as unknown) as ErrorResponse | "MUST_BE_SET";

  if (e === "MUST_BE_SET") {
    Sentry.captureException(
      new TypeError(
        "Should send errors to <ApolloError error={/* MUST BE SET */} />"
      )
    );
    return <ErrorBox error={e} />;
  }

  if (
    e.graphQLErrors &&
    e.graphQLErrors.find(err => err.extensions.code === "UNAUTHENTICATED")
  ) {
    return <SignInModal />;
  }

  const networkError = e.networkError;

  if (networkError) {
    if ("result" in networkError && networkError.result) {
      if (
        networkError.result.errors.find(
          (err: any) =>
            err.extensions && err.extensions.code === "UNAUTHENTICATED"
        )
      ) {
        return <SignInModal />;
      } else if (
        networkError.result.errors.filter(
          (err: any) => err.extensions && err.extensions.code === "FORBIDDEN"
        ).length > 0
      ) {
        return <NotAllowed />;
      }
    } else {
      Sentry.captureException(e);
      const status =
        "response" in networkError ? networkError.response.statusText : "";
      const maybeStatus = status ? `: ${status}` : "";
      return (
        <ErrorBox title={`🌎 Network Error${maybeStatus}`} error={e}>
          Please check your connection or refresh the page.
        </ErrorBox>
      );
    }
  }

  Sentry.captureException(e);
  return <ErrorBox error={e} />;
}
