import * as React from "react";

export function NotFound() {
  return (
    <div className="flex-grow-1 flex justify-center items-center">
      <div className="pa3 ba bw2">
        <h1 className="mv0">404 🤷‍♀️</h1>
        <div className="pt1" />
        We couldn't find the page you were looking for.
        <div className="pt1" />
        <small className="gray">
          <a
            href="mailto:official@trueusd.com"
            className="blue link underline"
          >
            Contact support
          </a>
        </small>
      </div>
    </div>
  );
}
