import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { BetweenEach, HSpace, VSpace } from "~/common/Layout";
import { ApolloError } from "~common/ApolloError";
import { MODULE_NAME_TO_LABEL_MAP } from "~common/application";
import { CustomerIdContext } from "~common/CustomerIdContext";
import { Button } from "~common/FormElements";
import { Section, SectionHeading } from "~common/Section";
import { Unknown } from "~common/Unknown";
import { UserContext } from "~common/UserContext";
import { formatTime, formatDateString, notifyUser } from "~common/util";
import { CustomerModulesRejectButton } from "./CustomerModulesRejectButton";

const GET_KYC_MODULE_VERIFICATIONS = gql`
  query($customerId: ID) {
    getCustomer(id: $customerId) {
      id
      kycModuleVerifications {
        id
        moduleName
        sentToAutomaticReviewAt
        sentToManualReviewAt
        reviewedAt
        decision
        rejectionReasons {
          code
          message
        }
        preliminaryReviewClaimedBy {
          id
          email
        }
        preliminaryReviewClaimedAt
        preliminarilyApprovedAt
        reviewedBy {
          id
          email
        }
        claimedBy {
          id
          email
        }
        claimedAt
        reviewedAt
        reviewedBy {
          id
          email
        }
      }
    }
  }
`;

const PRELIMINARY_APPROVE = gql`
  mutation($id: ID) {
    preliminarilyApproveKycModuleVerification(id: $id) {
      id
    }
  }
`;

const APPROVE = gql`
  mutation($id: ID) {
    approveKycModuleVerification(id: $id) {
      id
    }
  }
`;

const PRELIMINARY_REJECT = gql`
  mutation($id: ID) {
    preliminarilyRejectKycModuleVerification(id: $id) {
      id
    }
  }
`;

const REJECT = gql`
  mutation($id: ID, $input: RejectKycModuleVerificationInput) {
    rejectKycModuleVerification(id: $id, input: $input) {
      id
    }
  }
`;

function isVerificationEnabledForUser(verification, user) {
  if ((!verification.preliminarilyApprovedAt && (!!verification.preliminaryReviewClaimedBy && verification.preliminaryReviewClaimedBy.email === user.email)) ||
      (!verification.reviewedAt && (!!verification.claimedBy && verification.claimedBy.email === user.email))) {
    return true;
  }

  return false;
}

function isPreliminarilyApproved(verification) {
  return !!verification.preliminarilyApprovedAt;
}

export function CustomerModules() {
  const user = React.useContext(UserContext);
  const customerId = React.useContext(CustomerIdContext);

  const { loading, error, data, refetch } = useQuery(GET_KYC_MODULE_VERIFICATIONS, {
    variables: { customerId },
    fetchPolicy: "network-only"
  });

  const [preliminarilyApprove] = useMutation(PRELIMINARY_APPROVE);
  const [preliminarilyReject] = useMutation(PRELIMINARY_REJECT);

  const [approve] = useMutation(APPROVE, {
    onError: err => notifyUser(err, "Error approving user")
  });

  const [reject] = useMutation(REJECT, {
    onError: err => notifyUser(err, "Error rejecting user")
  });

  // Don't show loading. Prevents layout thrashing
  if (loading) return null;
  if (error) return <ApolloError error={error} />;

  const verifications = data.getCustomer.kycModuleVerifications;

  if (!verifications || verifications.length === 0) return null;

  return (
    <Section>
      <SectionHeading className="bb">KYC Module Verifications</SectionHeading>
      <div className="flex flex-column ba b--black-10">
        <BetweenEach between={<div className="bt b--black-10" />}>
          {verifications.sort((a, b) => a.moduleName.localeCompare(b.moduleName))
            .map((verification) => {
              const preliminaryReviewClaimedBy = verification.preliminaryReviewClaimedBy;
              const claimedBy = verification.claimedBy;

              const verificationEnabled = isVerificationEnabledForUser(verification, user);
              const preliminarilyApproved = isPreliminarilyApproved(verification);
              const approved = verification.decision === 'approved';

              const handleApprove = (verification) => {
                let approvePromise;
                const options = { variables: { id: verification.id } };

                if (!preliminarilyApproved) {
                  approvePromise = preliminarilyApprove(options);
                } else {
                  approvePromise = approve(options);
                }

                approvePromise.then(() => {
                  refetch()
                });
              }

              const handleReject = (verification) => {
                preliminarilyReject()
              };

              return (
                <VSpace space={2} className={`${approved ? 'bg-striped-light-green ' : ''}pa2 items-center`} key={module.id}>
                  <span>
                    <b>{verification.moduleName ? MODULE_NAME_TO_LABEL_MAP[verification.moduleName] : <Unknown isBad />}</b>
                  </span>
                  <div className="pl2">
                    <small>
                      {!preliminaryReviewClaimedBy && <div className="red">Module needs preliminary verification.</div>}
                      {preliminaryReviewClaimedBy &&
                        <>
                          <div>
                            {verificationEnabled &&
                              'Preliminary module verification claimed by: Me'
                            }
                            {!verificationEnabled &&
                              `Preliminary module verification claimed by: ${preliminaryReviewClaimedBy.email}`
                            }
                          </div>
                          <div>
                            Preliminary review claimed at: {formatDateString(verification.preliminaryReviewClaimedAt)}
                          </div>
                        </>
                      }
                      {preliminarilyApproved &&
                        <>
                          <div className="green">
                            Preliminarily approved by {verification.preliminaryReviewClaimedBy.email}
                          </div>
                          <hr/>
                          {!claimedBy && <div className="red">Module needs final verification.</div>}
                        </>
                      }
                      {claimedBy &&
                        <>
                          <div>
                            Final module verification claimed by {claimedBy.email}
                          </div>
                          <div>
                            Final module verification claimed at {formatDateString(verification.claimedAt)}
                          </div>
                        </>
                      }
                      {verification.reviewedAt && (
                        <div className="green">
                          Reviewed at: {formatTime(new Date(verification.reviewedAt))}
                        </div>
                      )}
                      {verification.reviewedAt && (
                        <div className={`${approved ? 'green' : 'red'}`}>
                          <hr/>
                          <VSpace space={1}>
                            Decision: {verification.decision}
                            {verification.rejectionReasons &&
                              verification.rejectionReasons.map(reason => (
                                <div>
                                  <div>
                                    <b>code:</b> {reason.code}
                                  </div>
                                  <div>
                                    <b>message:</b> {reason.message}
                                  </div>
                                </div>
                              ))}
                          </VSpace>
                        </div>
                      )}
                    </small>
                  </div>
                  {!verification.reviewedAt &&
                    <HSpace space={2} style={{ marginLeft: "auto" }}>
                      <Button
                        disabled={!verificationEnabled}
                        className="bg-light-green"
                        onClick={() => handleApprove(verification)}
                      >
                        {`${preliminarilyApproved ? '' : 'Preliminary '}Approve`}
                      </Button>
                      <CustomerModulesRejectButton
                        disabled={!verificationEnabled}
                        moduleName={verification.moduleName}
                        onReject={rejectionReasons =>
                          reject({
                            variables: {
                              id: verification.id,
                              input: { rejectionReasons }
                            }
                          }).then(() => refetch())
                        }
                      />
                    </HSpace>
                  }
                </VSpace>
              );
            })}
        </BetweenEach>
      </div>
    </Section>
  );
}
