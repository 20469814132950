import * as React from "react";
import posed from "react-pose";
import styled from "styled-components";

export function Table({
  head,
  children
}: {
  head: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <table className="collapse w-100 bg-white">
      <thead>
        <tr style={{ verticalAlign: "top" }}>{head}</tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}

export const TD = styled.td`
  padding: 0.5em;
  vertical-align: top;
`;

export function TH({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <th className={`tl pa2 bl bb top-0 bg-white b--moon-gray ${className}`}>
      {children}
    </th>
  );
}

/**
 * Treat this as you would treat a low-level dom node
 */
export const TR = React.forwardRef(function TR(
  props: any,
  ref: React.LegacyRef<HTMLTableRowElement>
) {
  const { className, children, onClick } = props;
  return (
    <tr
      ref={ref}
      className={`h2 w-100 bb b--moon-gray hover-bg-black-10 ${className}`}
      onClick={onClick}
      // Note: using {...rest} will break animation here, so best not to try
    >
      {children}
    </tr>
  );
});

export const PosedTR = posed(TR)({
  enter: { opacity: 1, x: 0, transition: { duration: 150, delay: 150 } },
  exit: {
    opacity: 0,
    x: ({ hiddenAtXPosition = 150 }) => hiddenAtXPosition,
    transition: { duration: 150 }
  },
  flip: {
    transition: {
      duration: 150,
      ease: "circOut"
    }
  }
});
