import { useMutation, useQuery } from "@apollo/react-hooks";
import { Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { ApolloError } from "~common/ApolloError";
import { Loading } from "~common/Loading";
import { Unknown } from "~common/Unknown";
import { notifyUser } from "~common/util";
import { Application } from "~types/types";
import { ApplicationFormikForm } from "./ApplicationFormikForm";

export const GET_CUSTOMER_APPLICATION = gql`
  query($customerId: ID) {
    getCustomer(id: $customerId) {
      id
      application {
        id
        accountPurpose {
          value
          other
        }
        anticipatedMonthlyVolume {
          amount
          currency
        }
      }
    }
  }
`;

export const UPDATE_CUSTOMER_APPLICATION = gql`
  mutation($customerId: ID, $customerApplicationInput: ApplicationInput) {
    updateCustomerApplication(
      id: $customerId
      input: $customerApplicationInput
    ) {
      id
    }
  }
`;

function cleanApplicationForApi(application: Application) {
  const {
    accountPurpose,
    anticipatedMonthlyVolume,
    identityVerificationDocumentType,
    proofOfAddressDocumentType
  } = application;

  return {
    accountPurpose: accountPurpose
      ? {
          value: accountPurpose.value,
          other: accountPurpose.value === "other" ? accountPurpose.other : null
        }
      : null,
    anticipatedMonthlyVolume: anticipatedMonthlyVolume
      ? {
          amount: anticipatedMonthlyVolume.amount,
          currency: anticipatedMonthlyVolume.currency
        }
      : null,
    identityVerificationDocumentType,
    proofOfAddressDocumentType
  };
}

export function Application({ customerId }: { customerId: string }) {
  const { loading, error, data, refetch } = useQuery(GET_CUSTOMER_APPLICATION, {
    variables: { customerId }
  });

  const [updateCustomerApplication] = useMutation(UPDATE_CUSTOMER_APPLICATION, {
    onCompleted: () => alert("Updated application"),
    onError: err => notifyUser(err, "Error updating customer application")
  });

  function saveCustomerApplication(application: Application) {
    if (!application) return;

    updateCustomerApplication({
      variables: {
        customerId,
        customerApplicationInput: cleanApplicationForApi(application)
      }
    }).then(() => refetch());
  }

  const application: Application =
    data && data.getCustomer && data.getCustomer.application;

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  if (!application) return <Unknown isBad />;

  return (
    <Formik
      initialValues={application}
      onSubmit={saveCustomerApplication}
      component={ApplicationFormikForm}
    />
  );
}
