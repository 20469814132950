import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { ApolloError } from "~/common/ApolloError";
import { CustomerIdContext } from "~common/CustomerIdContext";
import { Loading } from "~common/Loading";
import { OrgMembers } from "~internal-portal/OrgMembers";
import { Customer } from "~types/types";

const GET_ORG = gql`
  query($customerId: ID) {
    getCustomer(id: $customerId) {
      id
      organizationId
    }
  }
`;

export function MaybeOrgMembers() {
  const customerId = React.useContext(CustomerIdContext);
  const { loading, error, data } = useQuery(GET_ORG, {
    variables: { customerId }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const customer: Customer = data.getCustomer;

  if (!customer) return null;
  if (!customer.organizationId) return null;

  return <OrgMembers orgId={customer.organizationId} />;
}
