import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import {
  BasicPageBody,
  Mortar,
  TwoColumnPageBody,
  VSpace
} from "~/common/Layout";
import { Section, SectionHeading } from "~/common/Section";
import { ApolloError } from "~common/ApolloError";
import { CustomerIdContext } from "~common/CustomerIdContext";
import { Loading } from "~common/Loading";
import { UserContext } from "~common/UserContext";
import { Module } from "~types/types";
import { ComplianceDocuments } from "../ComplianceDocuments";
import { CustomerInfo } from "../CustomerInfo";
import { CustomerNotes } from "../CustomerNotes";
import { InternalNotes } from "../InternalNotes";
import { MaybeOrgMembers } from "../MaybeOrgMembers";
import { PrimaryComplianceButtons } from "../PrimaryComplianceButtons";
import { Submissions } from "../Submissions";
import { ExternalCustomerSubmissions } from "../ExternalCustomerSubmissions";
import { getApplicationBucketNames } from '~lib/application';

function AllNotes({ application }) {
  const applicationBucketNames = getApplicationBucketNames(application?.applicationBucket);
  return (
    <Mortar space={3}>
      <div className="flex-grow-1" style={{ minWidth: "30ch" }}>
        <Section>
          <VSpace space={3}>
            <SectionHeading className="bb">🔒Internal Notes</SectionHeading>
            <InternalNotes />
          </VSpace>
        </Section>
      </div>
      <div className="flex-grow-1" style={{ minWidth: "30ch" }}>
        <Section>
          <VSpace space={3}>
            <SectionHeading className="bb">Customer Notes</SectionHeading>
            <CustomerNotes />
          </VSpace>
        </Section>
      </div>
      <div className="flex-grow-1" style={{ minWidth: "60ch" }}>
        <Section>
          <VSpace space={3}>
            <SectionHeading className="bb">{applicationBucketNames.join(", ")} Submissions</SectionHeading>
            <Submissions />
          </VSpace>
        </Section>
      </div>
      <div className="flex-grow-1" style={{ minWidth: "60ch" }}>
        <Section>
          <VSpace space={3}>
            <SectionHeading className="bb"> External Client Submissions </SectionHeading>
            <ExternalCustomerSubmissions />
          </VSpace>
        </Section>
      </div>
    </Mortar>
  );
}

export const GET_CUSTOMER = gql`
  query($customerId: ID) {
    getCustomer(id: $customerId) {
      id
      application {
        id
        applicationBucket {
          watr
          truecurrency
          truefi
          other {
            name
          }
        }
      }
      kycModuleVerifications {
        id
      }
    }
  }
`;

export function CustomerGeneral() {
  const user = React.useContext(UserContext);
  const customerId = React.useContext(CustomerIdContext);

  const { loading, error, data } = useQuery(GET_CUSTOMER, {
    variables: { customerId },
    fetchPolicy: "network-only"
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const isCompliance = user.experimental.isCompliance;
  const customer = data.getCustomer;
  const application = customer && customer.application;
  const kycModuleVerifications: Module[] =
    data.getCustomer.kycModuleVerifications;
  const hasModules =
    kycModuleVerifications && kycModuleVerifications.length > 0;

  return (
    <TwoColumnPageBody
      left={
        <VSpace space={4}>
          <BasicPageBody title="General">
            <AllNotes application={application} />
            {isCompliance && <MaybeOrgMembers />}
            {isCompliance && <ComplianceDocuments />}
          </BasicPageBody>
        </VSpace>
      }
      right={<CustomerInfo />}
      rightTop={isCompliance && !hasModules && <PrimaryComplianceButtons />}
    />
  );
}
