import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { Button, Input } from "~/common/FormElements";
import { VSpace } from "~/common/Layout";
import { Modal } from "~/common/Modal";
import { Annotated } from "~common/Annotated";
import { notifyUser, useFileUpload } from "~common/util";

const ADD_DOCUMENT_TO_ORGANIZATION = gql`
  mutation($organizationId: ID, $input: AddDocumentInput) {
    addDocumentToOrganization(organizationId: $organizationId, input: $input) {
      id
    }
  }
`;

const ADD_DOCUMENT_TO_PERSON = gql`
  mutation($personId: ID, $input: AddDocumentInput) {
    addDocumentToPerson(personId: $personId, input: $input) {
      id
    }
  }
`;

type PersonOrOrgProps = {
  personId?: string;
  organizationId?: string;
  onComplete: () => void;
};

export function UploadInternalDocumentsButton({
  personId,
  organizationId,
  onComplete
}: PersonOrOrgProps) {
  const fileUpload = useFileUpload();
  const [showModal, setShowModal] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [type, setType] = React.useState("");
  const [side, setSide] = React.useState("");

  const [addDocument, { loading }] = useMutation(
    personId ? ADD_DOCUMENT_TO_PERSON : ADD_DOCUMENT_TO_ORGANIZATION
  );

  function resetAll() {
    fileUpload.reset();
    setDescription("");
    setType("");
    setSide("");
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      await addDocument({
        variables: {
          // Only `personId` or `organizationId` will be set. Not both
          personId,
          organizationId,
          input: {
            uploadSlug: fileUpload.slug,
            description,
            type,
            side
          }
        }
      });
      resetAll();
      alert("Upload worked!");
      onComplete();
    } catch (error) {
      notifyUser(
        error,
        `Error submitting document.

        Possible reasons:
        - You didn't attach a file
        - "Type" or "Side" value is incorrect
        - A problem reaching our servers
        `
      );
    }
  }

  return (
    <>
      <Button className="f7" onClick={() => setShowModal(true)}>
        ↑ Upload Internal Document
      </Button>
      {showModal && (
        <Modal
          title="Upload Internal Document"
          onClose={() => setShowModal(false)}
        >
          <form onSubmit={handleSubmit}>
            <VSpace space={3}>
              <Annotated
                label="File"
                value={<Input className="w-100" {...fileUpload.inputProps} />}
              />
              {fileUpload.isUploading && "Uploading..."}
              {!fileUpload.isUploading && fileUpload.slug && (
                <span title={fileUpload.slug}>Uploaded: {fileUpload.slug}</span>
              )}
              <Annotated
                label="Description"
                value={
                  <Input
                    className="w-100"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                  />
                }
              />
              <Annotated
                label="Type (optional)"
                value={
                  <Input
                    className="w-100"
                    value={type}
                    onChange={e => setType(e.target.value)}
                  />
                }
              />
              <Annotated
                label="Side (optional)"
                value={
                  <Input
                    className="w-100"
                    value={side}
                    onChange={e => setSide(e.target.value)}
                  />
                }
              />
              <small className="db gray">Ask ENG for types</small>
              <Button type="submit" disabled={loading}>
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </VSpace>
          </form>
        </Modal>
      )}
    </>
  );
}
