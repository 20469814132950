import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import * as React from 'react';

import { ApolloError } from '~/common/ApolloError';
import { CustomerIdContext } from '~/common/CustomerIdContext';
import { BasicPageBody, TwoColumnPageBody, VSpace } from '~/common/Layout';
import { Loading } from '~/common/Loading';
import { Section, SectionHeading } from '~/common/Section';
import { Tab, Tabs } from '~/common/Tabs';
import { CURRENCIES, CURRENCY_PRODUCT_MAP } from '~/common/util';
import { Customer } from '~types/types';
import { BankInfo } from '../BankInfo';
import { PurchasesTable } from '../PurchasesTable';
import { RedemptionsTable } from '../RedemptionsTable';
import { CustomerRedemptionAddresses } from '~internal-portal/CustomerRedemptionAddresses';

const GET_MINTS = gql`
  query($customerId: ID) {
    getMintsForCustomer(customerId: $customerId) {
      id
      customerId
      bankingPartner {
        name
      }
      createdAt
      product
      amountInCents
      shortId
      status
      requestTransactionHash
      finalizeTransactionHash
      blockchainId
    }
  }
`;

function PurchasesTableWithData() {
  const customerId = React.useContext(CustomerIdContext);
  const { loading, error, data } = useQuery(GET_MINTS, {
    variables: { customerId }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const purchases = data.getMintsForCustomer;

  return <PurchasesTable purchases={purchases} hideCustomerColumn />;
}

const GET_REDEMPTIONS = gql`
  query($customerId: ID) {
    getRedemptionsForCustomer(customerId: $customerId) {
      id
      customerId
      bankingPartner {
        internalName
        name
      }
      createdAt
      product
      amountInCents
      shortId
      status
      transactionHash
      blockchainId
    }
  }
`;

function RedemptionsTableWithData() {
  const customerId = React.useContext(CustomerIdContext);
  const { loading, error, data } = useQuery(GET_REDEMPTIONS, {
    variables: { customerId }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const redemptions = data.getRedemptionsForCustomer;

  return <RedemptionsTable redemptions={redemptions} hideCustomerColumn />;
}

export function Transactions() {
  return (
    <Tabs>
      <Tab name="Mints">
        <PurchasesTableWithData />
      </Tab>
      <Tab name="Redemptions">
        <RedemptionsTableWithData />
      </Tab>
    </Tabs>
  );
}

const GET_CUSTOMER = gql`
  query($customerId: ID) {
    getCustomer(id: $customerId) {
      id
      redemptionAddresses {
        redemptionAddress
      }
    }
  }
`;

export function CustomerTransactions() {
  const customerId = React.useContext(CustomerIdContext);
  const { loading, error, data } = useQuery(GET_CUSTOMER, {
    variables: { customerId }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const customer: Customer = data.getCustomer;
  const redemptionAddresses = (customer && customer.redemptionAddresses) || [];

  return (
    <TwoColumnPageBody
      left={
        <BasicPageBody title="Transactions">
          <Transactions />
        </BasicPageBody>
      }
      right={
        <VSpace space={4}>
          <Section>
            <SectionHeading className="normal">Bank Info</SectionHeading>
            <CustomerRedemptionAddresses addresses={redemptionAddresses} />
            <div className="flex-shrink-0" style={{ flexGrow: 1 }}>
              <Tabs tabFontSize={6}>
                {CURRENCIES.map(currency => {
                  const product = CURRENCY_PRODUCT_MAP[currency];
                  return (
                    <Tab name={currency} key={currency}>
                      <div className="mt3" />
                      <BankInfo product={product} currency={currency} />
                    </Tab>
                  );
                })}
              </Tabs>
            </div>
          </Section>
        </VSpace>
      }
    />
  );
}
