import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { Annotated } from "~/common/Annotated";
import { ApolloError } from "~/common/ApolloError";
import { ApprovalStatus } from "~/common/ApprovalStatus";
import { CustomerIdContext } from "~/common/CustomerIdContext";
import { VSpace } from "~/common/Layout";
import { Loading } from "~/common/Loading";
import { Section, SectionHeading } from "~/common/Section";
import { formatTime } from "~/common/util";
import { Labeled } from "~common/Labeled";
import { UserContext } from "~common/UserContext";
import { Client, Customer } from "~types/types";
import { ComplianceSection } from "./ComplianceSection";
import { CustomerInfoOrg } from "./CustomerInfoOrg";
import { CustomerInfoPerson } from "./CustomerInfoPerson";
import { CustomerModules } from "./CustomerModules";
import { ExternalCustomerApproval } from "./ExternalCustomerApproval";

export const GET_CUSTOMER = gql`
  query($customerId: ID) {
    getCustomer(id: $customerId) {
      id
      accountType
      approvedAt
      deactivatedAt
      createdAt
      riskScore
      application {
        id
        submittedAt
        accountPurpose {
          value
          other
        }
        emailVerified
        anticipatedMonthlyVolume {
          amount
          currency
        }
      }
      externalCustomers {
        id
        client {
          id
          name
        }
        latestApplicationSubmission {
         id
         createdAt
         decision
         reviewedAt
         permanentlyRejected
         clientKycTier {
           name
         }
        }
      }
      personId
      organizationId
    }
  }
`;

export function CustomerInfo() {
  const user = React.useContext(UserContext);
  const customerId = React.useContext(CustomerIdContext);
  const { loading, error, data } = useQuery(GET_CUSTOMER, {
    variables: { customerId }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const customer = data.getCustomer;

  if (!customer) {
    return <div className="gray">None</div>;
  }

  return (
    <Section>
      <VSpace space={4}>
        {user.experimental.isCompliance ? (
          <VSpace space={3}>
            <CustomerModules />
            {customer.externalCustomers.length > 0 &&
              <Section>
                <SectionHeading className="bb">External Client Status</SectionHeading>
                <ExternalCustomerApproval customer={customer} />
              </Section>
            }
            {/* <Section>
              <SectionHeading className="bb">KYC</SectionHeading>
              <ComplianceSection />
            </Section> */}
          </VSpace>
        ) : (
          <ApprovalStatus
            approvedAt={customer.approvedAt}
            deactivatedAt={customer.deactivatedAt}
          />
        )}
        <Section>
          <VSpace space={3}>
            <SectionHeading className="bb">Info</SectionHeading>
            {customer.organizationId ? (
              <CustomerInfoOrg application={customer.application} />
            ) : (
              <CustomerInfoPerson application={customer.application} />
            )}
            <b className="db pb1 bb b--black-20">Dates</b>
            <Annotated
              label="Created"
              value={
                customer.createdAt && formatTime(new Date(customer.createdAt))
              }
            />
          </VSpace>
        </Section>
      </VSpace>
    </Section>
  );
}
