import * as React from "react";

const HeadingContext = React.createContext(0);

export function SectionHeading({ children, ...props }: any) {
  const headingLevel = React.useContext(HeadingContext);
  return React.createElement(
    `h${headingLevel}`,
    {
      ...props,
      className: `mv0 normal b--black-20 ${
        props.className ? props.className : ""
      }`
    },
    children
  );
}

export function Section({ children }: { children?: React.ReactNode }) {
  const headingLevel = React.useContext(HeadingContext);
  return (
    <HeadingContext.Provider value={headingLevel + 1}>
      {children}
    </HeadingContext.Provider>
  );
}
