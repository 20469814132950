import { useQuery } from "@apollo/react-hooks";
import { compareAsc } from "date-fns";
import gql from "graphql-tag";
import * as React from "react";
import { Link } from "react-router-dom";
import { ApolloError } from "~/common/ApolloError";
import { BasicPageBody, HSpace } from "~/common/Layout";
import { Loading } from "~/common/Loading";
import { ColumnPage } from "~/common/Page";
import { formatTime } from "~/common/util";
import { SectionHeading } from "~common/Section";
import { Tab, Tabs } from "~common/Tabs";
import { getApplicationBucketNames } from '~lib/application';

const GET_APPLICANTS = gql`
  query($input: FindApplicationSubmissionsInput) {
    getApplicationSubmissions(input: $input) {
      createdAt
      reviewedAt
      decision
      customer {
        id
        organization {
          id
          name
        }
        application {
          id
          applicationBucket {
            watr
            truecurrency
            truefi
            other {
              name
            }
          }
        }
      }
      authorizedPerson {
        id
        firstName
        middleName
        lastName
        email
      }
    }
  }
`;

type Status = "new" | "resubmitted";

function Submissions({ status, customerType }) {
  const { loading, error, data } = useQuery(GET_APPLICANTS, {
    variables: {
      input: {
        new: status === "new",
        resubmitted: status === "resubmitted",
        customerType: customerType,
      }
    }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const submissions: any[] | undefined = data.getApplicationSubmissions;

  if (!submissions || submissions.length === 0) {
    return (
      <SectionHeading className="tc f1 bt pa2">All Done 😎</SectionHeading>
    );
  }

  const sorted = submissions.sort((a, b) => {
    return compareAsc(new Date(a.createdAt), new Date(b.createdAt));
  });

  const showOrgBuilder = (customerType === 'organization') && (status === 'resubmitted');

  return (
    <div>
      <div className="flex justify-between pv3">
        <div className="f6 black-50">Count: {sorted.length}</div>
        <div className="f6 black-50">(Oldest first)</div>
      </div>
      {sorted.map((submission: any) => {
        const customer = submission.customer;
        const applicationBucketNames = getApplicationBucketNames(customer?.application?.applicationBucket)
        let url;
        const hasAuthorizedPerson = !!submission.authorizedPerson;
        // show the builder URL for org resubmissions
        if (showOrgBuilder) {
          url = `/internal/pending-org-customers/${customer.id}/orgs/${customer.organization.id}`;
        } else {
          url = `/internal/customers/${customer.id}`;
        }

        return (
          <Link
            key={submission.customer.id}
            to={url}
            className="db pine-green link hover-bg-near-white ba pa2"
          >
            <HSpace space={2}>
              <div className="flex flex-column">
                {hasAuthorizedPerson && (
                  <>
                    <b>
                      {submission.authorizedPerson.firstName &&
                        [
                          submission.authorizedPerson.firstName,
                          submission.authorizedPerson.middleName,
                          submission.authorizedPerson.lastName,
                        ].join(' ')}
                    </b>
                    <span>{submission.authorizedPerson.email}</span>
                  </>
                )}
                {submission.customer.organization && (
                  <div>
                    {submission.customer.organization.name}{" "}
                    <small className="ml2 gray">ORG</small>
                  </div>
                )}
              </div>
              <div className="flex flex-column tr">
                {submission.decision === 'approved' && (
                  <div>
                    Approved: {formatTime(new Date(submission.reviewedAt))}
                  </div>
                )}
                {submission.decision === 'rejected' && (
                  <div>
                    Rejected: {formatTime(new Date(submission.reviewedAt))}
                  </div>
                )}
                {submission.createdAt && (
                  <>
                    <div>Created: {formatTime(new Date(submission.createdAt))}</div>
                    <div>For: {applicationBucketNames.join(', ')}</div>
                  </>
                )}
              </div>
            </HSpace>
          </Link>
        );
      })}
    </div>
  );
}

export function Applicants({ customerType = 'individual' }) {
  let title = 'Applicants';
  if (customerType === 'individual') {
    title = 'Individual Applicants';
  } else if (customerType === 'organization') {
    title = 'Organization Applicants';
  }

  return (
    <ColumnPage>
      <BasicPageBody title={title}>
        <Tabs>
          <Tab name="New">
            <Submissions status="new" customerType={customerType} />
          </Tab>
          <Tab name="Resubmitted">
            <Submissions status="resubmitted" customerType={customerType} />
          </Tab>
        </Tabs>
      </BasicPageBody>
    </ColumnPage>
  );
}
