import * as React from "react";
import { Unknown } from "./Unknown";

export function Labeled({
  label,
  children,
  className,
  unknownOk = false
}: {
  label: string;
  children: React.ReactNode;
  className?: string;
  unknownOk?: boolean;
}) {
  return (
    <label className={className}>
      <small
        className="db black-40 mb1"
        style={{
          /* Prevent selecting labels, so easier copying when selecting multiple Labeled components */
          userSelect: "none"
        }}
      >
        {label}
      </small>
      {children || <Unknown isBad={!unknownOk} />}
    </label>
  );
}
