import * as React from "react";
import { NavLink } from "react-router-dom";

export function LinkToNewColumn({
  to,
  children
}: {
  to: string;
  children: React.ReactNode;
}) {
  return (
    <NavLink
      to={to}
      className="pa1 ba blue no-underline flex justify-between flex-wrap"
      activeClassName="bg-black-10"
    >
      {children}
      <div>→</div>
    </NavLink>
  );
}
