import * as React from "react";
import { ErrorBox } from "./ErrorBox";

export function NotAllowed() {
  return (
    <ErrorBox title="✋ Not Allowed">
      Your user role doesn't let you go here
    </ErrorBox>
  );
}
