import styled from "styled-components";

export const GlobalStyles = styled.div`
  // GitHub's system font stack. The goal is to have nicest font that system can provide
  // https://css-tricks.com/snippets/css/system-font-stack/
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  * {
    box-sizing: border-box;
  }
`;
