import * as React from "react";
import { Button } from "~/common/FormElements";
import { Modal } from "~/common/Modal";
import { RejectionReasonForm } from "./RejectionReasonForm";

interface Props {
  submissionId?: string;
}

export function RejectButton({ submissionId }: Props) {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <Button onClick={() => setShowModal(true)}>Reject</Button>
      {showModal && (
        <Modal title="Reject" onClose={() => setShowModal(false)}>
          <RejectionReasonForm submissionId={submissionId} />
        </Modal>
      )}
    </>
  );
}
