import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { ApolloError } from "~/common/ApolloError";
import { CustomerIdContext } from "~/common/CustomerIdContext";
import { Section, SectionHeading } from "~/common/Section";
import { Button } from "~/common/FormElements";
import { HSpace, VSpace } from "~/common/Layout";
import { Loading } from "~/common/Loading";
import { notifyUser } from "~/common/util";
import { ApprovalStatus } from "./ApprovalStatus";
import { RejectButton } from "./RejectButton";
import { formatTime } from "~/common/util";


const APPROVE = gql`
  mutation($customerId: ID, $submissionId: ID) {
    approveCustomerApplicationSubmission(customerId: $customerId, submissionId: $submissionId)
  }
`;

function ExternalCustomerSubmission({ customer, externalCustomer }) {

  const submission = externalCustomer.latestApplicationSubmission;

  const [approve] = useMutation(APPROVE, {
    variables: {
      customerId: customer.id,
      submissionId: submission.id,
    },
    onError: err => notifyUser(err, "Error approving customer"),
    onCompleted: () => { document.location.reload() },
  });


  let bgColor = 'bg-washed-yellow';
  if (submission.decision == 'approved') {
    bgColor = 'bg-washed-green';
  }

  return (
    <div className={`ba b--black-10 pv3 ph3 ${bgColor}`}>
      <div className="f4 b--black-20 bb mb2 pb2">{externalCustomer.client.name}</div>
      {submission.decision === null &&
        <>
          <div className="orange mb2">
            <div>
              <b> Submitted </b>
            </div>
            <small className="db">
              {formatTime(new Date(submission.createdAt))}
            </small>
          </div>
          <HSpace space={1}>
            <Button onClick={() => approve()}> Approve </Button>
            <RejectButton submissionId={submission.id} />
          </HSpace>
        </>
      }
      {submission.decision === 'approved' &&
        <>
          <div className="green mb2">
            <div>
              <b> Approved </b>
            </div>
            <small className="db">
              {formatTime(new Date(submission.reviewedAt))}
            </small>
          </div>
        </>
      }
      {submission.decision === 'rejected' &&
        <>
          <div className="orange mb2">
            <div>

              <b> { submission.permanentlyRejected ? 'Permanently Rejected' : 'Rejected' } </b>
            </div>
            <small className="db">
              {formatTime(new Date(submission.reviewedAt))}
            </small>
          </div>
        </>
      }
    </div>
  )
}

export function ExternalCustomerApproval({ customer }) {
  const customerId = React.useContext(CustomerIdContext);

  const [approve] = useMutation(APPROVE, {
    variables: {
      customerId
    },
    onError: err => notifyUser(err, "Error approving customer"),
  });

  // only show submissions for fiat_onramp tier!
  return (
    <>
      {customer.externalCustomers.map(externalCustomer => {
        if (externalCustomer.latestApplicationSubmission.clientKycTier.name !== 'fiat_onramp') {
          return;
        }

        return (
          <Section key={externalCustomer.id}>
            <ExternalCustomerSubmission customer={customer} externalCustomer={externalCustomer} />
          </Section>
        );
      })}
    </>
  );
}
