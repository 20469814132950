import gql from "graphql-tag";
import { notifyUser } from "~common/util";
import { apolloClient } from "./apolloClient";

const RESET_MFA = gql`
  mutation($userId: ID) {
    resetUserMFA(userId: $userId)
  }
`;

/**
 * Reset user MFA
 * @param userId Optional so you don't have to do the checking on the calling side
 */
export function resetUserMFA(userId?: string) {
  if (!userId) {
    throw new Error("Expected person.userId to be defined");
  }
  apolloClient
    .mutate({
      mutation: RESET_MFA,
      variables: { userId }
    })
    // If API returns successfully, that means it worked
    .then(() => alert("Success!"))
    .catch(err => notifyUser(err, "Error sending MFA email"));
}
