import * as React from "react";

import { Nav } from "~/common/Nav";
import { NotAllowed } from "~/common/NotAllowed";
import { Body, Footer } from "~/common/Page";

export function Page({ children }: { children: React.ReactNode }) {
  return (
    <>
      <Nav />
      <Body>{children}</Body>
      <Footer />
    </>
  );
}

export function NotAllowedPage() {
  return (
    <Page>
      <NotAllowed />
    </Page>
  );
}
