import * as React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import { Button } from "~/common/FormElements";
import { Loading } from "~/common/Loading";
import { ApolloError } from "~/common/ApolloError";
import { BasicPageBody } from "~/common/Layout";
import { ColumnPage } from "~/common/Page";
import { RadioSelect } from "~common/RadioSelect";

import { SanctionMonitoringAlertsTable } from "../SanctionMonitoringAlertsTable";

const GET_SANCTION_MONITORING_ALERTS = gql`
  query($input: GetSanctionMonitoringAlertsInput) {
    getSanctionMonitoringAlerts(input: $input) {
      id
      createdAt
      updatedAt
      preliminaryDecision
      decision
      preliminarilyReviewedBy
      reviewedBy
      externalVendorData {
        complyadvantageSearchId
      }
      person {
        id
        firstName
        lastName
        email
        customer {
          id
        }
      }
      organization {
        id
        name
        customer {
          id
        }
        authorizedPersons {
          email
        }
      }
    }
  }
`;

const REVIEW_STATUS_VALUES = {
  NOT_REVIEWED: 'NOT_REVIEWED',
  PRELIMINARY_REVIEWED: 'PRELIMINARY_REVIEWED',
  FINAL_REVIEWED: 'FINAL_REVIEWED',
  REJECTED: 'REJECTED',
};


const PAGE_LENGTH = 20;

export function SanctionMonitoringAlerts() {
  const [offset, setOffset] = React.useState(0);
  const [nextDisabled, setNextDisabled] = React.useState(false);
  const [preliminarilyReviewed, setPreliminarilyReviewed] = React.useState(false);
  const [finalReviewed, setFinalReviewed] = React.useState(false);
  const [decision, setDecision] = React.useState(null);
  const [currentView, setCurrentView] = React.useState(REVIEW_STATUS_VALUES.NOT_REVIEWED);

  const { loading, error, data, fetchMore } = useQuery<{ getSanctionMonitoringAlerts: unknown[] }>(GET_SANCTION_MONITORING_ALERTS, {
    variables: {
      input: {
        offset: 0,
        limit: PAGE_LENGTH,
        preliminarilyReviewed: preliminarilyReviewed,
        finalReviewed: finalReviewed,
        decision: decision
      }
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
  });

  const onPageChange = (pageDiff) => {
    const newOffset = offset + (PAGE_LENGTH * pageDiff)

    fetchMore({
      variables: {
       input: {
         offset: newOffset,
       }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) { return prev; }
        if (!fetchMoreResult.getSanctionMonitoringAlerts.length) {
          setNextDisabled(true);
          return prev;
        }

        if (fetchMoreResult.getSanctionMonitoringAlerts.length < PAGE_LENGTH) {
          setNextDisabled(true);
        }

        return fetchMoreResult;
      },
    });

    setOffset(newOffset);
  };

  if (loading) {
    return (
      <ColumnPage>
        <BasicPageBody title="Sanction Monitoring Alerts">
          <Loading />
        </BasicPageBody>
      </ColumnPage>
    );
  }

  function selectAlertReviewStatus (status){
    if (status === REVIEW_STATUS_VALUES.NOT_REVIEWED){
      setCurrentView(REVIEW_STATUS_VALUES.NOT_REVIEWED)
      setPreliminarilyReviewed(false)
      setFinalReviewed(false)
      setDecision(null)
    }
    else if (status === REVIEW_STATUS_VALUES.PRELIMINARY_REVIEWED) {
      setCurrentView(REVIEW_STATUS_VALUES.PRELIMINARY_REVIEWED)
      setPreliminarilyReviewed(true)
      setFinalReviewed(false)
      setDecision(null)
    }
    else if (status === REVIEW_STATUS_VALUES.FINAL_REVIEWED) {
      setCurrentView(REVIEW_STATUS_VALUES.FINAL_REVIEWED)
      setPreliminarilyReviewed(true)
      setFinalReviewed(true)
      setDecision(null)
    }
    else if (status === REVIEW_STATUS_VALUES.REJECTED) {
      setCurrentView(REVIEW_STATUS_VALUES.REJECTED)
      setPreliminarilyReviewed(true)
      setFinalReviewed(true)
      setDecision('true_positive')
    }

  }

  const sanctionMonitoringAlerts = data.getSanctionMonitoringAlerts;
  const REVIEW_STATUS_OPTIONS = [
    {
      value: REVIEW_STATUS_VALUES.NOT_REVIEWED,
      label: "Not reviewed"
    },
    {
      value: REVIEW_STATUS_VALUES.PRELIMINARY_REVIEWED,
      label: "Preliminary Reviewed"
    },
    {
      value: REVIEW_STATUS_VALUES.FINAL_REVIEWED,
      label: "Final Reviewed"
    },
    {
      value: REVIEW_STATUS_VALUES.REJECTED,
      label: "Rejected"
    }
  ];
    return (
    <ColumnPage>
      <BasicPageBody title="Sanction Monitoring Alerts">
        <div>
          <RadioSelect
            options={REVIEW_STATUS_OPTIONS}
            value={currentView}
            onChange={status => selectAlertReviewStatus(status)}
          />
          <br/>
          <br/>
          <SanctionMonitoringAlertsTable sanctionMonitoringAlerts={sanctionMonitoringAlerts} view={currentView}/>
          <div className="mt2">
            <Button
              onClick={() => onPageChange(-1)}
              disabled={offset === 0}
            >
              Prev Page
            </Button>
            <Button
              onClick={() => onPageChange(1)}
              disabled={nextDisabled}
            >
              Next Page
            </Button>
          </div>
        </div>
      </BasicPageBody>
    </ColumnPage>
  );
}
