import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { ApolloError } from "~common/ApolloError";
import { DocumentLabel } from "~common/DocumentLabel";
import { Button } from "~common/FormElements";
import { Labeled } from "~common/Labeled";
import { VSpace } from "~common/Layout";
import { Modal } from "~common/Modal";
import { RadioSelect } from "~common/RadioSelect";
import { Document } from "~internal-portal/Document";
import { DocumentType, UploadedDocument } from "~types/types";
import { LoadingColumn } from "./Column";

export const GET_USER_DOCUMENTS = gql`
  query($userId: ID) {
    getUserDocuments(userId: $userId) {
      id
      slug
      category
      type
      side
      description
      signedUrl
    }
  }
`;

const DOCUMENT_PURPOSE_OPTIONS = [
  {
    value: "registration_document",
    label: "Registration Document"
  },
  {
    value: "formation_document",
    label: "Formation Document"
  },
  {
    value: "proof_of_ownership",
    label: "Proof of Ownership"
  },
  {
    value: "bsa_aml_program",
    label: "BSA/AML Program"
  },
  {
    value: "authorized_user_verification",
    label: "Authorized User Verification (e.g. authorized signatory)"
  },
  {
    value: "organization_chart",
    label: "Organization Chart"
  },
  {
    value: "identity_verification",
    label: "Identity Verification (e.g. government id)"
  },
  {
    value: "proof_of_address",
    label: "Proof of Address"
  },
  {
    value: "other",
    label: "Other"
  }
];


const DOCUMENT_TYPE_OPTIONS = [
  {
    value: "driver_license",
    label: "Driver License",
  },
  {
    value: "government_id",
    label: "Government ID",
  },
  {
    value: "passport",
    label: "Passport",
  },
  {
    value: "resident_permit",
    label: "Resident Permit",
  },
]


export function AddDocumentButton({
  document,
  onComplete
}: {
  document: UploadedDocument;
  onComplete?: (document: UploadedDocument) => void;
}) {
  const [showModal, setShowModal] = React.useState(false);
  const [documentPurpose, setDocumentPurpose] = React.useState(null);
  const [documentType, setDocumentType] = React.useState(null);

  return (
    <>
      <Button
        className="bg-light-green"
        onClick={() => setShowModal(true)}
        disabled={!onComplete}
      >
        ← Add Document
      </Button>
      {showModal && (
        <Modal title="Add Document" onClose={() => setShowModal(false)}>
          <form
            onSubmit={e => {
              e.preventDefault();
              if (!documentPurpose) {
                alert("Please set the document purpose");
                return;
              }

              document.purpose = documentPurpose;
              document.type = documentType;

              if (!onComplete) {
                throw Error(
                  "Bug! `onComplete` should be callable at this point."
                );
              }
              onComplete({
                slug: document.slug,
                purpose: document.purpose,
                type: document.type
              });
            }}
          >
            <VSpace space={2}>
              <Labeled label="Purpose">
                <VSpace space={1}>
                  <RadioSelect
                    options={DOCUMENT_PURPOSE_OPTIONS}
                    value={documentPurpose}
                    onChange={value => setDocumentPurpose(value)}
                  />
                </VSpace>
              </Labeled>
              <br/>
              <Labeled label="Type (for persons)">
                <VSpace space={1}>
                  <RadioSelect
                    options={DOCUMENT_TYPE_OPTIONS}
                    value={documentType}
                    onChange={value => setDocumentType(value)}
                  />
                </VSpace>
              </Labeled>
              <Button type="submit">Submit</Button>
            </VSpace>
          </form>
        </Modal>
      )}
    </>
  );
}

export function AuthorizedUserDocuments({
  userId,
  onDocumentSelect
}: {
  userId: string;
  onDocumentSelect?: (document: UploadedDocument) => void;
}) {
  const { loading, error, data } = useQuery(GET_USER_DOCUMENTS, {
    variables: { userId }
  });

  if (loading) return <LoadingColumn />;
  if (error) return <ApolloError error={error} />;

  const documents = data.getUserDocuments;

  function getFilenameFromSlug(slug: string) {
    if (slug) {
      return slug.split("/").slice(-1)[0];
    }
  }

  if (!documents || documents.length === 0) {
    return (
      <span className="black-50">
        The organization's authorized user didn't upload any documents yet
      </span>
    );
  }

  return (
    <VSpace space={3}>
      {documents.map((d: UploadedDocument) => (
        <div className="ba pa2" key={d.id}>
          <VSpace space={2}>
            <AddDocumentButton document={d} onComplete={onDocumentSelect} />
            <b>{getFilenameFromSlug(d.slug)}</b>
            <DocumentLabel document={d} />
            <Document document={d} />
          </VSpace>
        </div>
      ))}
    </VSpace>
  );
}
