import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import cloneDeep from "lodash-es/cloneDeep";
import * as React from "react";
import { CustomerIdContext } from "~/common/CustomerIdContext";
import { Checkbox, CheckboxSelect } from "~common/CheckboxSelect";
import { Button } from "~common/FormElements";

const REJECT = gql`
  mutation($customerId: ID, $input: RejectCustomerApplicationInput) {
    rejectCustomerApplication(customerId: $customerId, input: $input) {
      id
    }
  }
`;

const REJECT_SUBMISSION = gql`
  mutation($customerId: ID, $submissionId: ID, $input: RejectCustomerApplicationInput) {
    rejectCustomerApplicationSubmission(customerId: $customerId, submissionId: $submissionId, input: $input)
  }
`;

type OtherValue = {
  code: "other";
  message: string;
};

type Value =
  | {
      component: string;
      code: string;
    }
  | OtherValue;

const identityVerificationOptions = [
  {
    value: {
      component: "identity_verification",
      code: "poor_image_quality"
    },
    label: "Poor image quality"
  },
  {
    value: {
      component: "identity_verification",
      code: "not_passport"
    },
    label: "Not a passport"
  },
  {
    value: {
      component: "identity_verification",
      code: "name_mismatch"
    },
    label: "Name does not match application"
  },
  {
    value: {
      component: "identity_verification",
      code: "expired"
    },
    label: "Doc is expired"
  },
  {
    value: {
      component: "national_id_number",
      code: "ssn_invalid"
    },
    label: "SSN invalid"
  },
  {
    value: {
      component: "date_of_birth",
      code: "government_id_mismatch"
    },
    label: "Birth date does not match government id (likely typo)"
  },
];

const proofOfAddressOptions = [
  {
    value: {
      component: "proof_of_address",
      code: "document_too_old"
    },
    label: "Outside 90 days"
  },
  {
    value: {
      component: "proof_of_address",
      code: "name_mismatch"
    },
    label: "Does not match name"
  },
  {
    value: {
      component: "proof_of_address",
      code: "language_not_english"
    },
    label: "Not in English"
  },
  {
    value: {
      component: "proof_of_address",
      code: "address_mismatch"
    },
    label: "Address does not match application"
  },
  {
    value: {
      component: "proof_of_address",
      code: "invalid_document_type"
    },
    label: "Document is not an accepted form of POA document"
  },
  {
    value: {
      component: "proof_of_address",
      code: "address_is_po_box"
    },
    label: "Address is PO Box"
  },
  {
    value: {
      component: "proof_of_address",
      code: "not_residential_address"
    },
    label: "Not a residential address"
  },
];

const otherOption = {
  value: {
    code: "other",
    message: ""
  } as OtherValue,
  label: "Other"
};

function RejectionReasonForm({ submissionId }) {
  const customerId = React.useContext(CustomerIdContext);

  const [checkedValues, setCheckedValues] = React.useState<Value[]>([]);
  const [otherMessage, setOtherMessage] = React.useState("");
  const [permanentlyReject, setPermanentlyReject] = React.useState(false);

  // This form supports either Truecurrency submissions, which call rejectCustomerApplication, and
  // Client fiat-onramp submissions which call rejectCustomerApplicationSubmission
  const [reject] = useMutation(REJECT, {
    // NOTE: not doing `onError` because `Failed to fetch` happens, and it misleadingly gives the user the impression that the operation failed
    onCompleted: () => {
      // Refreshing page because we need to update many parts of the UI
      document.location.reload();
    }
  });
  const [rejectSubmission] = useMutation(REJECT_SUBMISSION, {
    onCompleted: () => {
      document.location.reload();
    }
  });

  function handleSubmit() {
    const rejectionReasons = cloneDeep(checkedValues); // Don't wanna mutate state
    const otherIndex = checkedValues.indexOf(otherOption.value);
    if (otherMessage) {
      (rejectionReasons[otherIndex] as OtherValue).message = otherMessage;
    }

    if (permanentlyReject) {
      if (!confirm('Are you sure you want to permanently reject this customer?')) {
        return;
      }
    }

    if (submissionId) {
      rejectSubmission({
        variables: {
          customerId,
          submissionId,
          input: { rejectionReasons, permanentlyReject: permanentlyReject }
        }
      });
    } else {
      reject({
        variables: {
          customerId,
          input: { rejectionReasons, permanentlyReject: permanentlyReject }
        }
      });
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <p>
        <strong>Identity Verification</strong>
      </p>
      <CheckboxSelect
        checkedValues={checkedValues}
        onChange={setCheckedValues}
        options={identityVerificationOptions}
      />
      <p>
        <strong>Proof of Address</strong>
      </p>
      <CheckboxSelect
        checkedValues={checkedValues}
        onChange={setCheckedValues}
        options={proofOfAddressOptions}
      />
      <p>
        <strong>Miscellaneous</strong>
      </p>
      <div>
        <CheckboxSelect
          checkedValues={checkedValues}
          onChange={setCheckedValues}
          options={[otherOption]}
        />
        {checkedValues.includes(otherOption.value) && (
          <div className="mt1">
            <textarea
              value={otherMessage}
              onChange={e => setOtherMessage(e.target.value)}
            />
          </div>
        )}
      </div>

      <p>
        <strong>Permanently Reject</strong>
      </p>
      <Checkbox
        label="Permanently Reject Customer (Will Deactivate)"
        value={permanentlyReject}
        onChange={setPermanentlyReject}
      />

      <Button className="mt3" type="submit">
        Confirm Reject
      </Button>
    </form>
  );
}

export { RejectionReasonForm };
