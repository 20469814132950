import { Form, FormikProps } from "formik";
import * as React from "react";
import { Button, Input } from "~common/FormElements";
import { Labeled } from "~common/Labeled";
import { HSpace, VSpace } from "~common/Layout";
import { Select } from "~common/Select";
import {
  CURRENCIES,
  Currency,
  ORGANIZATION_MONTHLY_VOLUMES,
  ORGANIZATION_PURPOSES
} from "~common/util";
import { AccountPurpose, Application, MonthlyVolume } from "~types/types";

export function ApplicationFormikForm(formikProps: FormikProps<Application>) {
  const { values, setFieldValue } = formikProps;

  return (
    <Form>
      <VSpace space={3}>
        <Labeled label="Purpose">
          <div className="flex flex-column">
            <Select<AccountPurpose>
              options={ORGANIZATION_PURPOSES}
              value={values.accountPurpose?.value}
              onChange={v => setFieldValue("accountPurpose.value", v)}
            />
          </div>
        </Labeled>
        {values.accountPurpose && values.accountPurpose.value === "other" && (
          <Labeled label="Please Explain">
            <Input
              value={values.accountPurpose.other}
              onChange={e =>
                setFieldValue("accountPurpose.other", e.target.value)
              }
            />
          </Labeled>
        )}
        <HSpace
          space={2}
          style={{
            /* Align currency input with anticipated volume input when the longer label wraps */
            alignItems: "end"
          }}
        >
          <Labeled label="Anticipated Monthly Volume">
            <Select<MonthlyVolume>
              options={ORGANIZATION_MONTHLY_VOLUMES}
              value={values.anticipatedMonthlyVolume?.amount}
              onChange={v =>
                setFieldValue("anticipatedMonthlyVolume.amount", v)
              }
            />
          </Labeled>
          {/* <Labeled label="Currency">
            <Select<Currency>
              options={CURRENCIES.map(c => ({ label: c, value: c }))}
              value={values.anticipatedMonthlyVolume?.currency}
              onChange={v =>
                setFieldValue("anticipatedMonthlyVolume.currency", v)
              }
            />
          </Labeled> */}
        </HSpace>
        <Button type="submit" className="w-100 bg-light-blue">
          Save
        </Button>
      </VSpace>
    </Form>
  );
}
