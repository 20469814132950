import * as React from "react";
import { Annotated } from "~/common/Annotated";
import { Mortar } from "~/common/Layout";
import { UploadedDocument } from "~types/types";

export function DocumentLabel({ document }: { document: UploadedDocument }) {
  return (
    <Mortar space={2}>
      {document.category && (
        <Annotated label="Category" value={document.category} />
      )}
      {document.purpose && (
        <Annotated label="Purpose" value={document.purpose} />
      )}
      {document.type && <Annotated label="Type" value={document.type} />}
      {document.description && <Annotated label="Description" value={document.description} />}
      {document.side && (
        <Annotated label="Side" value={document.side} />
      )}
    </Mortar>
  );
}
