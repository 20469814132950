// TODO: consider using flat structure that is easy to grep
// Goal is to make search and replace easy in the future
export const LABELS = {
  // Misc
  // ...
  // Bank
  customer: {
    redemptionAddresses: "Redemption Addresses",
    redemptionMemo: "Redemption Memo",
    customerBankingPartner: {
      wireReference: "Wire Reference",
      trustTokenSenAccount: "TrustToken SEN Account",
      trustTokenSignetAddress: "TrustToken Signet Address",
      bankingPartner: {
        name: "Partner Bank"
      }
    }
  },
  blockchainAccount: {
    exchange: "Exchange",
    blockchainAddress: "Blockchain Address",
    memo: "Blockchain Account Memo"
  },
  bank: {
    accountHolderName: "Account Holder Name",
    bankName: "Bank Name",
    accountNumber: "Account Number / IBAN",
    routingNumber: "Routing Number",
    swiftCode: "Swift Code",
    senAccount: "SEN Account",
    signetAddress: "Signet Address",
    intermediaryHeading: "Correspondent / Intermediary Bank",
    intermediaryBankName: "Intermediary Bank Name",
    intermediaryBankSwiftCode: "Intermediary Bank Swift Code",
    notes: "Notes",
    phoneNumber: "Phone Number",
    address: "Address",
    intermediaryBankAddress: "Intermediary Bank Address",
    institutionType: "Institution Type"
  }
};
