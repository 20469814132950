import { Org } from "~types/types";

export function cleanOrgForApi(org: Org): Org {
  // NOTE:
  // Doing all this to avoid sending up something unexpected like `__typename`
  // or other potentially unexpected fields. Whitelisting is safer than blacklisting
  const { name, taxIdNumber, regionOfFormation, entityType, address } = org;

  return {
    name,
    taxIdNumber,
    regionOfFormation: regionOfFormation
      ? {
          country: regionOfFormation.country,
          state: regionOfFormation.state
        }
      : undefined,
    entityType,
    address: address
      ? {
          street: address.street,
          subpremise: address.subpremise,
          locality: address.locality,
          stateOrProvince: address.stateOrProvince,
          postalCode: address.postalCode,
          country: address.country
        }
      : undefined
  };
}
