import * as React from "react";
import styled from "styled-components";
import { ErrorBoundary } from "./ErrorBoundary";
import { ScrollToTopOnMount } from "./ScrollToTopOnMount";
import { Section, SectionHeading } from "./Section";
import { useDocumentTitle } from "./useDocumentTitle";

type ColumnProps = {
  header?: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
  grow?: boolean;
};

function Column({ header, body, footer, grow = false }: ColumnProps) {
  header && React.Children.only(header);
  React.Children.only(body);
  footer && React.Children.only(footer);

  return (
    <div className={`flex flex-column ${grow ? "flex-grow-1" : ""}`}>
      <ErrorBoundary>
        {header && <div className="flex-shrink-0">{header}</div>}
        <div className="flex-grow-1 flex overflow-auto">{body}</div>
        {footer && <div className="flex-shrink-0">{footer}</div>}
      </ErrorBoundary>
    </div>
  );
}

type ColumnProps2 = {
  header?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  grow?: boolean;
};

function Column2({ header, children, footer, grow = false }: ColumnProps2) {
  header && React.Children.only(header);
  // React.Children.only(children);
  footer && React.Children.only(footer);

  return (
    <div className={`flex flex-column ${grow ? "flex-grow-1" : ""}`}>
      <ErrorBoundary>
        {header && <div className="flex-shrink-0">{header}</div>}
        <div className="flex-grow-1 flex overflow-auto">{children}</div>
        {footer && <div className="flex-shrink-0">{footer}</div>}
      </ErrorBoundary>
    </div>
  );
}

// Default class to use for spacing
const columnBodyPaddingClass = "pv4 ph4";

function PageBody({
  heading,
  children
}: {
  heading: string;
  children: React.ReactNode;
}) {
  useDocumentTitle(heading);

  return (
    <ColumnBody>
      <ScrollToTopOnMount />
      <div className={columnBodyPaddingClass}>
        <VSpace space={4}>
          <Section>
            <SectionHeading>{heading}</SectionHeading>
            {children}
          </Section>
        </VSpace>
      </div>
    </ColumnBody>
  );
}

const ColumnBody = styled.div`
  width: 100%;
  min-height: min-content;
  // height: min-content;
  flex-grow: 1;
  display: flex;
  & > * {
    flex-grow: 1;
  }
`;

const ColumnNarrowBody = styled.div`
  width: 24em;
  min-height: 100%;
  height: min-content;
  display: flex;
  & > * {
    flex-grow: 1;
  }
`;

function PageSkeleton({
  left,
  right
}: {
  left: React.ReactElement<ColumnProps>;
  right: React.ReactElement<ColumnProps>;
}) {
  return (
    <div className="vh-100 flex">
      <ErrorBoundary>{left}</ErrorBoundary>
      <ErrorBoundary>{right}</ErrorBoundary>
    </div>
  );
}

function BasicPageBody({
  title,
  titleRight,
  children
}: {
  title: string;
  titleRight?: React.ReactNode;
  children: React.ReactNode;
}) {
  useDocumentTitle(title);

  return (
    <Section>
      <div className={columnBodyPaddingClass}>
        <VSpace space={4} className="flex-grow-1">
          <div className="flex justify-between items-center">
            <SectionHeading className="normal">{title}</SectionHeading>
            {titleRight}
          </div>
          <ErrorBoundary>{children}</ErrorBoundary>
        </VSpace>
      </div>
    </Section>
  );
}

export const FlexRow = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  overflow: auto;
`;

export const FlexColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 24em;
  overflow: auto;
`;

export const HRule = styled.hr.attrs({ className: "b--black-10" })`
  margin: 0;
  border-bottom: 0;
  height: 0;
  width: 100%;
`;

export const VRule = styled.hr.attrs({ className: "b--black-10" })`
  margin: 0;
  border-left: 0;
  width: 0;
  height: 100%;
`;

function TwoColumnPageBody({
  left,
  right,
  rightTop,
  rightBottom
}: {
  left: React.ReactNode;
  right: React.ReactNode;
  rightTop?: React.ReactNode;
  rightBottom?: React.ReactNode;
}) {
  return (
    <FlexRow>
      <FlexColumn>
        <ErrorBoundary>{left}</ErrorBoundary>
      </FlexColumn>
      <div className="br b--black-20" />
      {right && (
        <FlexColumn className="flex-shrink-0">
          <ErrorBoundary>
            {rightTop}
            {rightTop && right && <div className="bt b--black-20" />}
            <FlexColumn className={columnBodyPaddingClass}>{right}</FlexColumn>
            {rightBottom}
          </ErrorBoundary>
        </FlexColumn>
      )}
    </FlexRow>
  );
}

type BetweenEachProps = {
  children: Array<React.ReactElement>;
  between: React.ReactNode;
};

function BetweenEach({ children, between }: BetweenEachProps) {
  return (
    <>
      {React.Children.toArray(children)
        .filter(child => child !== null && child !== undefined)
        .map((child, i) => {
          if (i === 0) {
            return child;
          }
          return (
            <React.Fragment key={child?.key || i}>
              {between}
              {child}
            </React.Fragment>
          );
        })}
    </>
  );
}

function sizeFor(n: number) {
  return n && Math.pow(2, n - 3);
}

const InnerSpaced = styled.div<{ space: number }>`
  margin: ${props => `-${sizeFor(props.space) / 2}rem`};
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin: ${props => `${sizeFor(props.space) / 2}rem`};
  }
`;

type SpacerProps = {
  children: React.ReactNode;
  space: number;
  className?: string;
};

function Mortar({ children, space, ...props }: SpacerProps) {
  return (
    <div {...props}>
      <InnerSpaced space={space}>{children}</InnerSpaced>
    </div>
  );
}

const VSpace = styled.div<{ space: number }>`
  display: grid;
  grid-gap: ${({ space }) => sizeFor(space)}rem;
  grid-auto-flow: row;
`;

const HSpace = styled.div<{ space: number }>`
  display: grid;
  grid-gap: ${({ space }) => sizeFor(space)}rem;
  grid-auto-flow: column;
`;

export {
  PageSkeleton,
  Column,
  Column2,
  ColumnBody,
  ColumnNarrowBody,
  BetweenEach,
  VSpace,
  HSpace,
  Mortar,
  columnBodyPaddingClass,
  PageBody,
  BasicPageBody,
  TwoColumnPageBody
};
