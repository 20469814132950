import * as React from "react";
import { Option } from "~types/types";
import { Select as SelectField } from "./FormElements";

export type SelectProps<T> = {
  value?: T;
  onChange: (value?: T) => void;
  /**
   * Note: Option.value should not repeat
   * BAD:
   * `[{value: 'a', label: "A"}, {value: 'a', label: "A"}] */
  options: Option<T>[];
};

// TODO: extract to new file
// TODO: consider splitting parent and children so Formik handling is easier
export function Select<T extends string>({
  value,
  onChange,
  options,
  ...rest
}: SelectProps<T>) {
  return (
    <SelectField
      className="bg-transparent"
      style={{ width: "100%" }}
      value={value}
      // Either value or defaultValue needs to be set, but not both
      defaultValue={value === undefined ? "" : undefined}
      onChange={e => onChange(e.target.value as T)}
      {...rest}
    >
      <option disabled value="">
        — Please Select —
      </option>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </SelectField>
  );
}
