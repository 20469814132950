import * as React from "react";
import { Address } from "~types/types";
import { Unknown } from "./Unknown";

export function Address({ address }: { address?: Address }) {
  if (!address) return <Unknown />;
  return (
    <address className="fs-normal">
      <div className="mb1">
        {address.street} {address.subpremise}
      </div>
      <div className="mb1">
        {address.locality}, {address.stateOrProvince} {address.postalCode}
      </div>
      <div>{address.country}</div>
    </address>
  );
}
