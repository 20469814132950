import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { ApolloError } from "~/common/ApolloError";
import { VSpace } from "~/common/Layout";
import { Loading } from "~/common/Loading";
import { Section, SectionHeading } from "~/common/Section";
import { Org } from "~types/types";
import { OrgLink } from "./OrgLink";
import { OrgMemberLink } from "./OrgMemberLink";

const GET_ORG_MEMBERS = gql`
  query($orgId: ID) {
    getOrganization(id: $orgId) {
      id
      ownerOrganizations {
        id
        name
      }
      beneficialOwners {
        id
        firstName
        middleName
        lastName
        email
        isAuthorizedUser
        ownershipPercentage
        nationalIdNumber
        dateOfBirth
        address {
          street
          subpremise
          locality
          stateOrProvince
          postalCode
          country
        }
        documents {
          id
          type
          side
          signedUrl
          signedThumbnailUrl
        }
      }
      persons {
        id
        firstName
        middleName
        lastName
        userId
        isAuthorizedUser
        ownershipPercentage
        nationalIdNumber
        dateOfBirth
        isPep
        address {
          street
          subpremise
          locality
          stateOrProvince
          postalCode
          country
        }
        documents {
          id
          type
          side
          signedUrl
          signedThumbnailUrl
        }
      }
    }
  }
`;

export function OrgMembers({ orgId }: { orgId: string }) {
  const { loading, error, data } = useQuery(GET_ORG_MEMBERS, {
    variables: { orgId }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const org: Org = data.getOrganization;

  if (!org) {
    return null;
  }

  return (
    <>
      <Section>
        <SectionHeading className="bb">Persons</SectionHeading>
        <VSpace space={1}>
          {org.persons && org.persons.length > 0
            ? org.persons.map(person => (
                <OrgMemberLink key={person.id} member={person} />
              ))
            : "None"}
        </VSpace>
      </Section>

      <Section>
        <SectionHeading className="bb">Beneficial Owners</SectionHeading>
        <VSpace space={1}>
          {org.beneficialOwners && org.beneficialOwners.length > 0
            ? org.beneficialOwners.map(owner => (
                <OrgMemberLink key={owner.id} member={owner} />
              ))
            : "None"}
        </VSpace>
      </Section>

      <Section>
        <SectionHeading className="bb">Owner Organizations</SectionHeading>
        <VSpace space={1}>
          {org.ownerOrganizations && org.ownerOrganizations.length > 0
            ? org.ownerOrganizations.map(owner => (
                <OrgLink
                  key={owner.id}
                  orgId={owner.id as string /* The ID should exist */}
                >
                  {owner.name}
                </OrgLink>
              ))
            : "None"}
        </VSpace>
      </Section>
    </>
  );
}
