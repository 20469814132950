import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { NavLink, Redirect, Route, Switch, withRouter } from "react-router-dom";
import { ApolloError } from "~/common/ApolloError";
import { Loading } from "~/common/Loading";
import { NotFound } from "~/common/NotFound";
import { ColumnPage } from "~/common/Page";

const GET_CUSTOMER_FOR_ORGANIZATION_PERSON = gql`
query($id: ID) {
  getCustomerForOrganizationPerson(personId: $id) {
    id
  }
}
`;


export const OrganizationPerson = withRouter(({ match }) => {
  const { personId } = match.params;
  const { loading, error, data } = useQuery(GET_CUSTOMER_FOR_ORGANIZATION_PERSON, {
    variables: { id: personId }
  });

  if (loading) {
    return (
      <ColumnPage>
        <Loading />
      </ColumnPage>
    );
  }

  // API returns an error if no customer found, so using this workaround
  if (!data || !data.getCustomerForOrganizationPerson) {
    return (
      <ColumnPage>
        <NotFound />
      </ColumnPage>
    );
  }

  // Even if `data` exists, we might still have an error
  if (error) {
    return (
      <ColumnPage>
        <ApolloError error={error} />
      </ColumnPage>
    );
  }

  const customerId = data.getCustomerForOrganizationPerson.id;

  return (
    <Redirect exact to={`/internal/customers/${customerId}`} />
  );
});
