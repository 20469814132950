import * as React from "react";
import { Portal } from "react-portal";

import { ErrorBoundary } from "./ErrorBoundary";
import { GlobalStyles } from "./GlobalStyles";
import { useEscapeKey } from "./useEscapeKey";

export function Panel({
  children,
  onClose
}: {
  children: React.ReactNode;
  onClose: () => void;
}) {
  useEscapeKey(onClose);
  return (
    <Portal>
      <GlobalStyles>
        <div
          className="fixed left-0 bottom-0 shadow-1 ba w-100 measure-wide bg-white overflow-y-scroll"
          style={{ overscrollBehavior: "contain", maxHeight: "75vh" }}
        >
          <button
            className="button-reset absolute top-0 right-0 f2 bw0 pv0 ph2 bg-white"
            onClick={() => onClose()}
          >
            ×
          </button>
          <div className="pa4">
            <ErrorBoundary>{children}</ErrorBoundary>
          </div>
        </div>
      </GlobalStyles>
    </Portal>
  );
}
