import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { Link } from "react-router-dom";
import { ApolloError } from "~common/ApolloError";
import { MODULE_NAME_TO_LABEL_MAP } from "~common/application";
import { Badge } from "~common/Badge";
import { Button } from "~common/FormElements";
import { BasicPageBody, VSpace } from "~common/Layout";
import { Loading } from "~common/Loading";
import { ColumnPage } from "~common/Page";
import { RadioSelect } from "~common/RadioSelect";
import { Table, TD, TH, TR } from "~common/Table";
import { Unknown } from "~common/Unknown";
import { cleanGraphQLErrorMessage, formatTime } from "~common/util";
import { Module } from "~types/types";
import { UserContext } from "~common/UserContext";

const GET_FINAL_KYC_MODULE_VERIFICATIONS = gql`
  query($input: GetManualKycModuleVerificationsInput) {
    getFinalReviewKycModuleVerifications(input: $input) {
      id
      customer {
        id
        person {
          id
          firstName
          middleName
          lastName
          email
        }
        organization {
          id
          name
          authorizedPersons {
            id
            firstName
            middleName
            lastName
            email
          }
        }
      }
      moduleName
      sentToAutomaticReviewAt
      sentToManualReviewAt
      reviewedAt
      decision
      rejectionReasons {
        code
        message
      }
      reviewedBy {
        id
        email
      }
      claimedBy {
        id
        email
      }
      claimedAt
    }
  }
`;

const CLAIM = gql`
  mutation($id: ID) {
    claimKycModuleVerification(id: $id) {
      id
    }
  }
`;

const UNCLAIM = gql`
  mutation($id: ID) {
    unclaimKycModuleVerification(id: $id) {
      id
    }
  }
`;

function SearchResults({ moduleName, claimedStatus, openStatus, }) {
  const user = React.useContext(UserContext);

  const [claim] = useMutation(CLAIM, {
    onError: (err) => {
      alert(cleanGraphQLErrorMessage(err.message));
    }
  });
  const [unclaim] = useMutation(UNCLAIM);

  let input = {
    moduleName
  };

  if (claimedStatus) {
    if (claimedStatus === 'claimedBy') {
      input[claimedStatus] = user.id;
    } else {
      input[claimedStatus] = true;
    }
  }
  if (openStatus) { input [openStatus] = true; }

  const { loading, error, data, refetch } = useQuery(GET_FINAL_KYC_MODULE_VERIFICATIONS, {
    variables: {
      input,
    },
    fetchPolicy: "no-cache"
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const modules: Module[] = data.getFinalReviewKycModuleVerifications;


  return (
    <Table
      head={
        <>
          <TH>Date</TH>
          <TH>Customer</TH>
          <TH>Module</TH>
          <TH>State</TH>
        </>
      }
    >
      {(!modules || modules.length === 0) && (
        <TR className="black-50">
          <TD>No Results</TD>
          <TD></TD>
          <TD></TD>
          <TD></TD>
        </TR>
      )}
      {modules
        .sort((a, b) => a.moduleName.localeCompare(b.moduleName))
        .map(m => (
          <TR>
            <TD>{formatTime(new Date(m.sentToManualReviewAt))}</TD>
            <TD>
              <Link
                key={m.id}
                to={`/internal/customers/${m.customer.id}`}
                className="pine-green link"
              >
                {m.customer.person && (
                  <VSpace space={1}>
                    <b>
                      {m.customer.person.firstName}{" "}
                      {m.customer.person.middleName}{" "}
                      {m.customer.person.lastName}
                    </b>
                    <div>{m.customer.person.email}</div>
                  </VSpace>
                )}
                {m.customer.organization && (
                  <VSpace space={1}>
                    <b>
                      {m.customer.organization.name}
                      <small className="ml2 gray normal">ORG</small>
                    </b>
                    <div>
                      {m.customer.organization.authorizedPersons ? (
                        m.customer.organization.authorizedPersons.map(
                          person => <div key={person.id}>{person.email}</div>
                        )
                      ) : (
                        <Unknown />
                      )}
                    </div>
                  </VSpace>
                )}
              </Link>
            </TD>
            <TD>
              <Badge>
                {m.moduleName ? (
                  MODULE_NAME_TO_LABEL_MAP[m.moduleName]
                ) : (
                  <Unknown isBad />
                )}
              </Badge>
            </TD>
            <TD>
              {m.claimedBy ? (
                <div>
                  <Button
                    className="w-100"
                    onClick={e =>
                      unclaim({ variables: { id: m.id } }).then(() => refetch())
                    }
                  >
                    Unclaim
                  </Button>
                  <small className="db green mt1">
                    Claimed by {m.claimedBy.email}
                  </small>
                </div>
              ) : (
                <div>
                  <Button
                    className="w-100"
                    onClick={() =>
                      claim({ variables: { id: m.id } }).then(() => refetch())
                    }
                  >
                    Claim
                  </Button>
                </div>
              )}
            </TD>
          </TR>
        ))}
    </Table>
  );
}

function buildFilterOptions() {
  const allOption = { label: 'All', value: undefined };
  const moduleOptions = Object.keys(MODULE_NAME_TO_LABEL_MAP).map((moduleName) => {
    return {
      label: MODULE_NAME_TO_LABEL_MAP[moduleName],
      value: moduleName
    }
  });

  return [allOption, ...moduleOptions];
}

export function Modules() {
  const [moduleName, setModuleName] = React.useState();
  const [claimedStatus, setClaimedStatus] = React.useState<string>();
  const [openStatus, setOpenStatus] = React.useState<string>();

  const filterOptions = buildFilterOptions();

  const claimedStatusOptions = [
    {
      label: 'All',
      value: undefined,
    },
    {
      label: 'Claimed',
      value: 'claimed',
    },
    {
      label: 'Unclaimed',
      value: 'unclaimed',
    },
    {
      label: 'My Claimed',
      value: 'claimedBy',
    }
  ];

  const openStatusOptions = [
    {
      label: 'All',
      value: undefined,
    },
    {
      label: 'Open',
      value: 'pending',
    },
    {
      label: 'Closed',
      value: 'completed',
    },
  ];

  return (
    <ColumnPage>
      <BasicPageBody title="Final Modules">
        <div className="flex">
          <div className="pr3 mb4" style={{ width: "20em" }}>
            <VSpace space={1}>
              <strong>Module Name</strong>
              <div className="pl3">
                <VSpace space={1}>
                  <RadioSelect
                    options={filterOptions}
                    value={moduleName}
                    onChange={moduleName => setModuleName(moduleName)}
                  />
                </VSpace>
              </div>
              <strong>Claimed</strong>
              <div className="pl3">
                <VSpace space={1}>
                  <RadioSelect
                    options={claimedStatusOptions}
                    value={claimedStatus}
                    onChange={(claimedStatus) => setClaimedStatus(claimedStatus)}
                  />
                </VSpace>
              </div>
              <strong>Open</strong>
              <div className="pl3">
                <VSpace space={1}>
                  <RadioSelect
                    options={openStatusOptions}
                    value={openStatus}
                    onChange={(openStatus) => setOpenStatus(openStatus)}
                  />
                </VSpace>
              </div>
            </VSpace>
          </div>
          <SearchResults moduleName={moduleName} claimedStatus={claimedStatus} openStatus={openStatus} />
        </div>
      </BasicPageBody>
    </ColumnPage>
  );
}
