import { useMutation } from "@apollo/react-hooks";
import { Formik } from "formik";
import gql from "graphql-tag";
import * as React from "react";
import { Section } from "~common/Section";
import { notifyUser } from "~common/util";
import { OrgMember } from "~types/types";
import { cleanPersonForApi } from "./cleanPersonForApi";
import { Column, ColumnBody, ColumnSectionHeading } from "./Column";
import { OrgMemberPersonFormikForm } from "./OrgMemberPersonFormikForm";

const EMPTY_PERSON: OrgMember = {
  firstName: undefined,
  middleName: undefined,
  lastName: undefined,
  dateOfBirth: undefined,
  nationalIdNumber: undefined,
  governmentIdCountry: undefined,
  address: {
    street: undefined,
    subpremise: undefined,
    locality: undefined,
    stateOrProvince: undefined,
    postalCode: undefined,
    country: undefined
  },
  ownershipPercentage: undefined
};

const ADD_PERSON = gql`
  mutation($orgId: ID, $person: OrganizationPersonInput) {
    addBeneficialOwnerToOrganization(id: $orgId, input: $person) {
      id
    }
  }
`;

export function ColumnAddOrgMemberPerson({
  orgId,
  onAdd,
  onClose
}: {
  orgId: string;
  onAdd: (personId: string) => void;
  onClose: () => void;
}) {
  const [addPerson] = useMutation(ADD_PERSON, {
    onError: err => notifyUser(err, "Error adding person")
  });

  async function handleAddPerson(person: OrgMember) {
    const { data } = await addPerson({
      variables: {
        orgId,
        person: cleanPersonForApi(person)
      }
    });

    onAdd(data.addBeneficialOwnerToOrganization.id);
  }

  return (
    <Column>
      <Section>
        <ColumnSectionHeading onClose={onClose}>
          Add Person
        </ColumnSectionHeading>
        <ColumnBody>
          <Formik
            initialValues={EMPTY_PERSON}
            onSubmit={handleAddPerson}
            component={OrgMemberPersonFormikForm}
          />
        </ColumnBody>
      </Section>
    </Column>
  );
}
