import * as React from "react";
import { Button } from "~/common/FormElements";
import { Section, SectionHeading } from "~/common/Section";
import { Org } from "./Org";

export function OrgLink({
  orgId,
  children
}: {
  orgId: string;
  children: React.ReactNode;
}) {
  const [show, setShow] = React.useState(false);
  return (
    <Section>
      <Button className="ba w-100 pa2" onClick={() => setShow(s => !s)}>
        <SectionHeading className="normal flex justify-between">
          {children} <span>{show ? "↑" : "↓"}</span>
        </SectionHeading>
      </Button>
      {show && (
        <div className="pa4 bg-black-05">
          <Org orgId={orgId} />
        </div>
      )}
    </Section>
  );
}
