import * as React from "react";
import { ErrorBoundary } from "~common/ErrorBoundary";
import { FlexColumn, HRule } from "~common/Layout";
import { Loading } from "~common/Loading";
import { SectionHeading } from "~common/Section";

export function Column({ children }: { children: React.ReactNode }) {
  return (
    <FlexColumn
      className="br b--black-10"
      style={{
        maxWidth: "24em",
        /*
        Need `content-box` because we don't want border to add to the width here.
        Otherwise, we get horizontal scrolling because `FlexColumn` minWidth is `24em`
         */
        boxSizing: "content-box"
        // boxShadow: "5px 0 5px rgba(0,0,0,0.1)"
      }}
    >
      {children}
    </FlexColumn>
  );
}

export function LoadingColumn() {
  return (
    <Column>
      <ColumnBody>
        <Loading />
      </ColumnBody>
    </Column>
  );
}

export function ColumnSectionHeading({
  children,
  onClose
}: {
  children: React.ReactNode;
  onClose?: () => void;
}) {
  // const { url } = useColumnRouting();
  return (
    <>
      <div className="pv3 ph4 flex justify-between items-center">
        <SectionHeading className="f5 b">
          <ErrorBoundary>{children}</ErrorBoundary>
        </SectionHeading>
        {onClose && (
          <button className="pa0 bw0" onClick={onClose}>
            ✖
          </button>
        )}
      </div>
      <HRule />
      {/* <div>{url}</div> */}
    </>
  );
}

export function ColumnBody({ children }: { children: React.ReactNode }) {
  return (
    <FlexColumn className="pv3 ph4">
      <ErrorBoundary>{children}</ErrorBoundary>
    </FlexColumn>
  );
}

export function ColumnFooter({ children }: { children: React.ReactNode }) {
  return (
    <>
      <HRule />
      <div className="pv3 ph4">
        <ErrorBoundary>{children}</ErrorBoundary>
      </div>
    </>
  );
}
