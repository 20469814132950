import * as Sentry from "@sentry/browser";
import "babel-polyfill";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "tachyons/css/tachyons.min.css";
import "./styles.css";
import { ErrorBoundary } from "~/common/ErrorBoundary";
import { GlobalStyles } from "~/common/GlobalStyles";
import { NotAllowed } from "~/common/NotAllowed";
import { NotFound } from "~/common/NotFound";
import { UserContext, UserContextProvider } from "~/common/UserContext";
import { InternalRoutes } from "~/internal-portal/routes/All";
import { PartnerRoutes } from "~/partner-portal/routes/All";
import { TestLayout } from "./stories/TestLayout";
import { TestSentry } from "./stories/TestSentry";

// TODO: Send internal portal errors to their own place?
if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://8e1a34fcd03943ebb96cf2e0e1ebef71@sentry.io/1489378",
    release: process.env.RELEASE,
    environment: process.env.NODE_ENV
  });
}

function RedirectToRightPortal() {
  const user = React.useContext(UserContext);
  const roleNames = user.roles.map(r => r.name);

  if (roleNames.includes("admin")) {
    return <Redirect to="/internal/" />;
  } else if (roleNames.includes("banking_partner")) {
    return <Redirect to="/partner/" />;
  } else {
    return (
      <div className="flex vh-100">
        <NotAllowed />
      </div>
    );
  }
}

function App() {
  return (
    <ErrorBoundary>
      <GlobalStyles>
        <UserContextProvider>
          <ErrorBoundary>
            <BrowserRouter>
              <Switch>
                <Route exact path="/" component={RedirectToRightPortal} />
                <Route path="/partner" component={PartnerRoutes} />
                <Route path="/internal" component={InternalRoutes} />
                <Route path="/test-sentry" component={TestSentry} />
                <Route path="/test-layout" component={TestLayout} />
                <Route
                  render={() => (
                    <div className="flex vh-100">
                      <NotFound />
                    </div>
                  )}
                />
              </Switch>
            </BrowserRouter>
          </ErrorBoundary>
        </UserContextProvider>
      </GlobalStyles>
    </ErrorBoundary>
  );
}

const rootElement = document.getElementById("react-root");
ReactDOM.render(<App />, rootElement);
