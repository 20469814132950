import * as React from "react";
import { Link } from "react-router-dom";
import { ScrollToTopOnMount } from "~/common/ScrollToTopOnMount";
import { ErrorBoundary } from "~/common/ErrorBoundary";

export function Dashboard() {
  return (
    <ErrorBoundary>
      <div>
        <ScrollToTopOnMount />
        <h1>Dashboard</h1>
        <div className="flex flex-wrap">
          <Link
            to="/partner/mints"
            className="link blue hover-bg-near-white w5 ba pa3 mr3 mb3"
          >
            <h3 className="ma0">Mints</h3>
            <p className="gray">
              Incoming wires to mint
              <br />
              Money → TrueCurrencies
            </p>
          </Link>
          <Link
            to="/partner/redemptions"
            className="link blue hover-bg-near-white w5 ba pa3 mr3 mb3"
          >
            <h3 className="ma0">Redemptions</h3>
            <p className="gray">
              Outgoing wires to redeem
              <br />
              TrueCurrencies → Money
            </p>
          </Link>
        </div>
      </div>
    </ErrorBoundary>
  );
}
