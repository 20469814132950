import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { Address } from "~/common/Address";
import { Annotated } from "~/common/Annotated";
import { ApolloError } from "~/common/ApolloError";
import { CustomerIdContext } from "~/common/CustomerIdContext";
import { Button } from "~/common/FormElements";
import { Mortar } from "~/common/Layout";
import { Loading } from "~/common/Loading";
import { ShowLast4 } from "~/common/ShowLast4";
import { Unknown } from "~/common/Unknown";
import { UserContext } from "~common/UserContext";
import { Application, Customer, Person } from "~types/types";
import { AnticipatedMonthlyVolume } from "./AnticipatedMonthlyVolume";
import { resetUserMFA } from "./resetUserMFA";

const GET_PERSON = gql`
  query($customerId: ID) {
    getCustomer(id: $customerId) {
      id
      person {
        userId
        id
        firstName
        lastName
        middleName
        dateOfBirth
        gender
        occupation {
          value
          other
        }
        governmentIdCountry
        citizenshipCountry
        email
        nationalIdNumber
        address {
          street
          subpremise
          locality
          stateOrProvince
          postalCode
          country
        }
      }
    }
  }
`;

export function CustomerInfoPerson({
  application
}: {
  application?: Application;
}) {
  const user = React.useContext(UserContext);
  const customerId = React.useContext(CustomerIdContext);

  const { loading, error, data } = useQuery(GET_PERSON, {
    variables: { customerId }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const customer: Customer = data.getCustomer;
  const person: Person | undefined = customer.person;

  if (!person) {
    return <div className="gray">No Person</div>;
  }

  return (
    <>
      <Mortar space={3} className="flex-wrap">
        <Annotated label="First" value={person.firstName} />
        {person.middleName && (
          <Annotated label="Middle" value={person.middleName} />
        )}
        <Annotated label="Last" value={person.lastName} />
      </Mortar>
      <div>
        <Annotated label="Email" value={person.email} />
        {application && (
          <div className="black-50">
            {application.emailVerified
              ? "Email verified ✓"
              : "Email not verified"}
          </div>
        )}
        {user.experimental.isSupport && (
          <small className="db mt1">
            <Button onClick={() => resetUserMFA(person.userId)}>
              Send MFA reset email
            </Button>
          </small>
        )}
      </div>
      <Mortar space={3}>
        <Annotated label="Gender" value={person.gender} unknownOk />
        <Annotated label="Date of birth" value={person.dateOfBirth} />
      </Mortar>
      <Mortar space={3}>
        <Annotated
          label="Occupation"
          value={
            person &&
            person.occupation &&
            (person.occupation.other || person.occupation.value)
          }
        />
        <Annotated
          label="Purpose"
          value={
            application &&
            application.accountPurpose &&
            (application.accountPurpose.other ||
              application.accountPurpose.value)
          }
        />
      </Mortar>
      <Annotated
        label="Anticipated Monthly Volume"
        value={
          application && (
            <AnticipatedMonthlyVolume
              anticipatedMonthlyVolume={application.anticipatedMonthlyVolume}
            />
          )
        }
      />
      {user.experimental.isCompliance && (
        <Annotated
          label="National ID Number"
          value={
            person.nationalIdNumber && (
              <ShowLast4 value={person.nationalIdNumber.toString()} />
            )
          }
        />
      )}
      <b className="db pb1 bb b--black-20">Location</b>
      <Annotated label="Address" value={<Address address={person.address} />} />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "max-content 1fr",
          gridGap: ".5rem"
        }}
      >
        <div className="black-50">Country of Citizenship</div>
        <div>{person.citizenshipCountry || <Unknown />}</div>
        <div className="black-50">Country of ID</div>
        <div>{person.governmentIdCountry || <Unknown />}</div>
      </div>
    </>
  );
}
