import * as Sentry from "@sentry/browser";
import * as React from "react";
import { ErrorBox } from "./ErrorBox";

export class ErrorBoundary extends React.Component<{}, { error: unknown }> {
  constructor(props: any) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }

  componentDidCatch(error: Error) {
    // Prevent double errors in Sentry in dev mode
    // https://github.com/getsentry/sentry-javascript/issues/1432
    if (process.env.NODE_ENV === "development") return;
    Sentry.captureException(error);
  }

  render() {
    const { error } = this.state;
    if (error) {
      return <ErrorBox />;
    }

    return this.props.children;
  }
}
