import { Form, FormikProps } from "formik";
import * as React from "react";
import { Button, Input } from "~common/FormElements";
import { Labeled } from "~common/Labeled";
import { VSpace } from "~common/Layout";
import { Section, SectionHeading } from "~common/Section";
import { Select } from "~common/Select";
import { COUNTRY_LIST } from "~common/util";
import { OrgMember } from "~types/types";
import { AddressFormikPart } from "./AddressFormikPart";

export function OrgMemberPersonFormikForm(formikProps: FormikProps<OrgMember>) {
  const { values, handleChange, setFieldValue } = formikProps;

  return (
    <Form>
      <VSpace space={3}>
        <Labeled label="First Name">
          <Input
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
          />
        </Labeled>
        <Labeled label="Middle Name">
          <Input
            name="middleName"
            value={values.middleName}
            onChange={handleChange}
          />
        </Labeled>
        <Labeled label="Last Name">
          <Input
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
          />
        </Labeled>
        {/* <Labeled label="Email">
          <Input
            name="email"
            value={values.email}
            onChange={handleChange}
          />
        </Labeled> */}
        <Labeled label="Date of Birth">
          <Input
            type="date"
            name="dateOfBirth"
            // Adding this here because can't update person via `updatePersonForOrganization` mutation otherwise
            required
            value={values.dateOfBirth}
            onChange={handleChange}
          />
        </Labeled>
        <Labeled label="National ID">
          <Input
            name="nationalIdNumber"
            value={values.nationalIdNumber}
            onChange={handleChange}
          />
        </Labeled>
        <Labeled label="Government ID Country">
          <Select<string>
            options={COUNTRY_LIST}
            value={values.governmentIdCountry || undefined}
            onChange={value =>
              setFieldValue("governmentIdCountry", value)
            }
          />
        </Labeled>
        <Labeled label="Ownership Percentage">
          <Input
            type="number"
            step="0.01"
            name="ownershipPercentage"
            value={values.ownershipPercentage}
            onChange={handleChange}
          />
        </Labeled>
        <Section>
          <SectionHeading>Location</SectionHeading>
          <AddressFormikPart formikProps={formikProps} />
        </Section>
        <Button
          type="submit"
          className="w-100 bg-light-blue"
          style={{ position: "sticky", bottom: 0 }}
        >
          Save
        </Button>
      </VSpace>
    </Form>
  );
}
