import * as React from "react";
import { Annotated } from "~/common/Annotated";
import { VSpace } from "~/common/Layout";
import { DocumentLabel } from "~common/DocumentLabel";
import { Au10tixResult, UploadedDocument } from "~types/types";
import { Document } from "./Document";

export function DocumentAu10tixResult({ result }: { result?: Au10tixResult }) {
  if (!result) {
    return null;
  }

  return (
    <VSpace space={2}>
      <b>Au10tix Results</b>
      <Annotated label="au10tixDocumentId" value={result.au10tixDocumentId} />
      <Annotated label="authenticity" value={result.authenticity} />
      <Annotated label="address" value={result.address} />
      <Annotated label="dateOfBirth" value={result.dateOfBirth} />
      <Annotated label="documentExpiresAt" value={result.documentExpiresAt} />
      <Annotated label="documentIssuedAt" value={result.documentIssuedAt} />
      <Annotated label="documentNumber" value={result.documentNumber} />
      <Annotated label="firstName" value={result.firstName} />
      <Annotated label="lastName" value={result.lastName} />
      <Annotated label="gender" value={result.gender} />
      <Annotated label="nationality" value={result.nationality} />
    </VSpace>
  );
}

export function Documents({ documents }: { documents?: UploadedDocument[] }) {
  if (!documents || documents.length === 0) {
    return <div className="dark-red">No Documents</div>;
  }
  return (
    <>
      {documents.map((d: UploadedDocument) => (
        <div className="ba pa2" key={d.id}>
          <VSpace space={2}>
            <DocumentLabel document={d} />
            <Document document={d} />
            <DocumentAu10tixResult result={d.au10tixResult} />
          </VSpace>
        </div>
      ))}
    </>
  );
}
