import * as React from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { CustomerIdContext } from "~common/CustomerIdContext";
import { FlexRow } from "~common/Layout";
import { ColumnPage } from "~common/Page";
import { ColumnAddOrg } from "../ColumnAddOrg";
import { ColumnAddOrgMemberPerson } from "../ColumnAddOrgMemberPerson";
import { ColumnAvailableDocuments } from "../ColumnAvailableDocuments";
import { ColumnOrg } from "../ColumnOrg";
import { ColumnOrgMemberPerson } from "../ColumnOrgMemberPerson";

function useGoUpALevel(path: string) {
  const history = useHistory();
  const match = useRouteMatch(path);
  // const matchPerson = useRouteMatch("**/persons/:personId");
  // const matchAddOrg = useRouteMatch("**/add-org");
  // const matchAddPerson = useRouteMatch("**/add-person");
  // const matchAddDocuments = useRouteMatch("**/add-documents");

  // Get the unmatched part of the URL
  const parentUrl = match.params[0];

  // Return function that takes you up a level
  return () => {
    history.push(parentUrl);
  };
}

// ---

function OrgRoute() {
  // Note: not the full url, only the url up to this point
  const { url } = useRouteMatch();

  const { customerId, orgId } = useParams<{ customerId?: string; orgId: string }>();
  const goUp = useGoUpALevel(`**/orgs/${orgId}`);

  const parentMatch = useRouteMatch<{ parentOrgId?: string }>("**/orgs/:parentOrgId/orgs/:orgId");
  const parentOrgId = parentMatch?.params?.parentOrgId;

  return (
    <ColumnOrg
      url={url}
      customerId={customerId}
      parentOrgId={orgId !== parentOrgId && parentOrgId}
      orgId={orgId}
      onClose={goUp}
    />
  );
}

function AddOrgRoute() {
  const history = useHistory();
  const { params } = useRouteMatch<{ orgId: string }>("**/orgs/:orgId/add-org");
  const goUp = useGoUpALevel(`**/add-org`);

  return (
    <ColumnAddOrg
      parentOrgId={params.orgId}
      onAdd={orgId => {
        history.push(`${params[0]}/orgs/${params.orgId}/orgs/${orgId}`)
      }}
      onClose={goUp}
    />
  );
}

function PersonRoute() {
  const { params } = useRouteMatch<{ orgId: string; personId: string }>("**/orgs/:orgId/persons/:personId");
  const goUp = useGoUpALevel("**/persons/:personId");

  return (
    <ColumnOrgMemberPerson
      orgId={params.orgId}
      personId={params.personId}
      onClose={goUp}
    />
  );
}

function AddPersonRoute() {
  const history = useHistory();
  const { params } = useRouteMatch<{ orgId: string }>("**/orgs/:orgId/add-person");
  const goUp = useGoUpALevel("**/add-person");

  return (
    <ColumnAddOrgMemberPerson
      orgId={params.orgId}
      onAdd={personId =>
        history.push(`${params[0]}/orgs/${params.orgId}/persons/${personId}`)
      }
      onClose={goUp}
    />
  );
}

function AvailableDocumentsRoute() {
  const {
    params: { rootOrgId }
  } = useRouteMatch<{ rootOrgId: string }>(
    "/internal/pending-org-customers/:customerId/orgs/:rootOrgId"
  );

  const orgMatch = useRouteMatch<{ orgId?: string }>({
    path: "**/orgs/:orgId",
    exact: true
  });
  const orgId = orgMatch?.params?.orgId;

  const personMatch = useRouteMatch<{ personId?: string }>({
    path: "**/persons/:personId",
    exact: true
  });
  const personId =
    personMatch && personMatch.params && personMatch.params.personId;

  return (
    <ColumnAvailableDocuments
      rootOrgId={rootOrgId}
      orgId={orgId}
      personId={personId}
    />
  );
}

/**
 * Recursive orgs get rendered as a list of `OrgColumn`
 * Each `OrgColumn` can be followed by:
 * - Another org
 * - A person
 * - User documents
 */
function RecursiveOrgRoutes() {
  const { url } = useRouteMatch();

  return (
    <>
      <OrgRoute />
      <Switch>
        <Route path={`${url}/add-person`} component={AddPersonRoute} />
        <Route path={`${url}/add-org`} component={AddOrgRoute} />
        <Route path={`${url}/persons/:personId`} component={PersonRoute} />
        <Route path={`${url}/orgs/:orgId`} component={RecursiveOrgRoutes} />
      </Switch>
    </>
  );
}

export function PendingOrgCustomer() {
  const { customerId } = useParams<{ customerId: string }>();

  return (
    <CustomerIdContext.Provider value={customerId}>
      <ColumnPage>
        <FlexRow>
          <RecursiveOrgRoutes />
          <AvailableDocumentsRoute />
        </FlexRow>
      </ColumnPage>
    </CustomerIdContext.Provider>
  );
}
