import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { Link } from "react-router-dom";
import { ApolloError } from "~/common/ApolloError";
import { Button, Input } from "~/common/FormElements";
import { BasicPageBody, VSpace } from "~/common/Layout";
import { Loading } from "~/common/Loading";
import { ColumnPage } from "~/common/Page";
import { RadioSelect } from "~/common/RadioSelect";
import { Section, SectionHeading } from "~/common/Section";

type Customer = {
  id: string;
  person?: {
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
  };
  organization?: {
    name: string;
  };
};

const SEARCH_CUSTOMERS = gql`
  query($input: SearchCustomersInput) {
    searchCustomers(input: $input) {
      id
      person {
        id
        firstName
        middleName
        lastName
        email
      }
      organization {
        id
        name
      }
    }
  }
`;

/**
 * These should (at least) be a subset of those available in `SearchCustomersInput`
 * See: https://github.com/trusttoken/trusttoken-api/blob/0c7560d7de8765ec439f7b619c77e52d35877380/src/api/internal/customer.ts#L169
 */
type SearchType =
  | "id"
  | "email"
  | "shortId"
  | "externalId"
  | "personName"
  | "organizationName";

export function Customers() {
  const [searchType, setSearchType] = React.useState<SearchType | undefined>(
    "email"
  );
  const [searchText, setSearchText] = React.useState("");

  const [searchCustomers, { loading, error, data }] = useLazyQuery(
    SEARCH_CUSTOMERS
  );

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    if (!searchType) {
      alert("Search type shouldn't be empty");
      return;
    }
    searchCustomers({
      variables: {
        input: { [searchType]: searchText }
      }
    });
  }

  const customers: Customer[] = data && data.searchCustomers;

  if (customers && customers.length === 1) {
    window.location.assign(`/internal/customers/${customers[0].id}`);
  }


  // { value: "shortId", label: "Wire Reference" },
  // { value: "externalId", label: "External Customer ID" },
  return (
    <ColumnPage>
      <BasicPageBody title="Customers">
        <form className="pa3 ba" onSubmit={handleSubmit}>
          <label className="db">
            <div className="b mb2">Search</div>
            <Input
              ref={searchInputRef}
              type="search"
              className="w-100"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </label>
          <div className="pv2">
            By:{" "}
            <div className="flex flex-column">
              <RadioSelect<SearchType>
                value={searchType}
                onChange={value => {
                  setSearchType(value);
                  searchInputRef.current && searchInputRef.current.focus();
                }}
                options={[
                  { value: "email", label: "Email" },
                  { value: "id", label: "Customer ID" },
                  { value: "personName", label: "Person Name" },
                  { value: "organizationName", label: "Organization Name" }
                ]}
              />
            </div>
          </div>
          <Button type="submit" className="bg-light-green">
            Search
          </Button>
        </form>
        <Section>
          <VSpace space={2}>
            <SectionHeading className="bb b--black-10">Results</SectionHeading>
            {loading && <Loading />}
            {error && <ApolloError error={error} />}
            {data && customers && customers.length > 0 ? (
              customers.map(customer => (
                <Link
                  key={customer.id}
                  to={`/internal/customers/${customer.id}`}
                  className="db blue link hover-bg-near-white ba pa2 mb2"
                >
                  {customer.person &&
                    [
                      customer.person.firstName,
                      customer.person.middleName,
                      customer.person.lastName
                    ].join(" ")}
                  {customer.organization && customer.organization.name}
                </Link>
              ))
            ) : (
              <div className="gray">No Results</div>
            )}
          </VSpace>
        </Section>
      </BasicPageBody>
    </ColumnPage>
  );
}
