import { ExportToCsv } from "export-to-csv";
import omit from "lodash-es/omit";
// @ts-ignore
import flatten from "obj-flatten";
import * as React from "react";
import { Address } from "~/common/Address";
import { Button } from "~/common/FormElements";
import { KV } from "~/common/KV";
import { ShowLast4 } from "~/common/ShowLast4";
import { isImageFileName } from "~/common/util";
import { DocumentPreviews } from "~common/DocumentPreviews";
import { Person as PersonType, UploadedDocument } from "~types/types";

function exportAsCSV({
  fileName,
  object
}: {
  fileName: string;
  object: object;
}) {
  // This series of steps would go well in a functional pipe function
  const flattened = flatten(object, ".");
  const table = Object.keys(flattened).map(label => ({
    label,
    value: flattened[label]
  }));

  const tableWithoutTypeName = table.filter(
    thing => !thing.label.includes("__typename")
  );

  const csvExporter = new ExportToCsv({
    // TODO: check that malicious content can't go here
    filename: fileName,
    useKeysAsHeaders: true
  });
  // Triggers download too
  csvExporter.generateCsv(tableWithoutTypeName);
}

/**
 * Find a good header image from a list of documents if possible
 */
function headerImage(documents?: UploadedDocument[]) {
  if (documents && documents.length > 0) {
    const foundDoc = documents.find((doc: UploadedDocument) => {
      if (doc.signedThumbnailUrl) {
        return isImageFileName(new URL(doc.signedThumbnailUrl).pathname);
      }
      return null;
    });
    if (foundDoc) return foundDoc.signedThumbnailUrl;
  }
}

export function Person({
  person,
  belowHeader
}: {
  person: PersonType;
  belowHeader?: React.ReactNode;
}) {
  const url = headerImage(person.documents);

  return (
    <>
      <div className="mb4 tc">
        {url && <img src={url} className="h4" />}
        <h1 className="mb1">
          {person.firstName} {person.middleName} {person.lastName}
        </h1>
        {belowHeader}
      </div>
      <h2 className="bb flex justify-between">
        Person Information
        <Button
          className="mb2 f5 flex items-center"
          onClick={() => {
            exportAsCSV({
              fileName: `person info ${person.firstName} ${person.lastName}`,
              object: omit(person, "documents")
            });
          }}
        >
          ↓ Download <small className="ml1 gray">(.CSV)</small>
        </Button>
      </h2>
      <div
        style={{
          userSelect: "none",
          cursor: "not-allowed"
        }}
      >
        <KV k="First name" v={person.firstName} />
        <KV k="Middle name" v={person.middleName} unknownOk />
        <KV k="Last name" v={person.lastName} />
        {/* TODO: add this to the DB? */}
        {/* <KV k="Gender" v="Female" /> */}
        <KV k="Email" v={person.email} unknownOk />
        <KV k="Date of birth" v={person.dateOfBirth} />
        <KV
          k="Occupation"
          v={
            person.occupation
              ? person.occupation.other || person.occupation.value
              : ""
          }
        />
        <KV
          k="National ID"
          v={
            person.nationalIdNumber && (
              <ShowLast4 value={person.nationalIdNumber.toString()} />
            )
          }
        />
        <b className="db">Address:</b>
        <div className="bg-near-white pa3">
          <Address address={person.address} />
        </div>
        .
        <KV k="Country of ID" v={person.governmentIdCountry} />
        <KV k="Country of citizenship" v={person.citizenshipCountry} />
        <KV
          k="Account purpose"
          v={
            person.application && person.application.accountPurpose
              ? person.application.accountPurpose.other
                ? person.application.accountPurpose.other
                : person.application.accountPurpose.value
              : ""
          }
        />
        <KV
          k="Anticipated volume"
          v={
            person.application && person.application.anticipatedMonthlyVolume
              ? `${person.application.anticipatedMonthlyVolume.currency} ${person.application.anticipatedMonthlyVolume.amount} / month`
              : ""
          }
        />
      </div>
      <h2 className="bb">Documents</h2>
      <DocumentPreviews documents={person.documents} />
    </>
  );
}
