import * as React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import { Loading } from "~/common/Loading";
import { ApolloError } from "~/common/ApolloError";
import { BasicPageBody } from "~/common/Layout";

import { ColumnPage } from "~/common/Page";
import { PurchasesTable } from "../PurchasesTable";

const GET_MINTS = gql`
  query {
    getMints {
      id
      customerId
      bankingPartner {
        name
      }
      createdAt
      product
      amountInCents
      shortId
      status
      requestTransactionHash
      finalizeTransactionHash
      blockchainId
    }
  }
`;

export function Purchases() {
  const { loading, error, data } = useQuery(GET_MINTS);

  if (loading) {
    return (
      <ColumnPage>
        <BasicPageBody title="Mints">
          <Loading />
        </BasicPageBody>
      </ColumnPage>
    );
  }

  if (error) return <ApolloError error={error} />;

  const mints = data.getMints;

  return (
    <ColumnPage>
      <BasicPageBody title="Mints">
        <PurchasesTable purchases={mints} />
      </BasicPageBody>
    </ColumnPage>
  );
}
