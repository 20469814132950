import * as React from "react";
import { Link } from "react-router-dom";
import { Panel } from "~/common/Panel";
import { Customer } from "./Customer";
import { Customer as CustomerType } from "~types/types";

export function CustomerLink({ customer }: { customer: CustomerType }) {
  const [showPanel, setShowPanel] = React.useState(false);
  const isNotApproved = !customer.approvedAt || customer.deactivatedAt;

  return (
    <span>
      {isNotApproved && <span className="dark-red mr1">✕</span>}
      <Link
        to="#"
        onClick={() => setShowPanel(true)}
        className={`black link underline ${isNotApproved && "dark-red"}`}
      >
        {/* Note: should only show person or org */}
        {customer.person &&
          `${customer.person.firstName} ${customer.person.lastName}`}
        {customer.organization && customer.organization.name}
      </Link>
      {customer.organization && <small className="ml2 gray">ORG</small>}
      {showPanel && (
        <Panel onClose={() => setShowPanel(false)}>
          <Customer id={customer.id} />
        </Panel>
      )}
    </span>
  );
}
