import { useQuery } from "@apollo/react-hooks";
import { compareAsc } from "date-fns";
import gql from "graphql-tag";
import * as React from "react";
import { Link } from "react-router-dom";
import { ApolloError } from "~/common/ApolloError";
import { BasicPageBody, HSpace } from "~/common/Layout";
import { Loading } from "~/common/Loading";
import { ColumnPage } from "~/common/Page";
import { formatTime } from "~/common/util";
import { SectionHeading } from "~common/Section";

const GET_CLIENT_FIAT_ONRAMP_APPLICANTS = gql`
  query($input: FindApplicationSubmissionsInput) {
    getClientFiatOnrampApplicationSubmissions(input: $input) {
      createdAt
      reviewedAt
      decision
      customer {
        id
        organization {
          name
        }
      }
      authorizedPerson {
        id
        firstName
        middleName
        lastName
        email
      }
      client {
        id
        name
      }
    }
  }
`;

function Submissions() {
  const { loading, error, data } = useQuery(GET_CLIENT_FIAT_ONRAMP_APPLICANTS);

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const submissions = data.getClientFiatOnrampApplicationSubmissions;

  if (!submissions || submissions.length === 0) {
    return (
      <SectionHeading className="tc f1 bt pa2">All Done </SectionHeading>
    );
  }

  const sorted = submissions.sort((a, b) => {
    return compareAsc(new Date(a.createdAt), new Date(b.createdAt));
  });

  return (
    <div>
      <div className="flex justify-between pv3">
        <div className="f6 black-50">Count: {sorted.length}</div>
        <div className="f6 black-50">(Oldest first)</div>
      </div>
      {sorted.map((submission: any) => (
        <Link
          key={submission.customer.id}
          to={`/internal/customers/${submission.customer.id}`}
          className={`db ${!submission.customer.organization ? 'pine-green' : 'brown'} link hover-bg-near-white ba pa2`}
        >
          <HSpace space={2}>
            <div className="flex flex-column">
              <b> Client: {submission.client.name} </b>
              <b>
                {submission.authorizedPerson.firstName &&
                  [
                    submission.authorizedPerson.firstName,
                    submission.authorizedPerson.middleName,
                    submission.authorizedPerson.lastName
                  ].join(" ")}
              </b>
              Customer: {submission.authorizedPerson.email}
              {submission.customer.organization && (
                <div>
                  {submission.customer.organization.name}{" "}
                  <small className="ml2 gray">ORG</small>
                </div>
              )}
            </div>
            <div className="flex flex-column tr">
              {submission.decision === 'approved' && (
                <div>
                  Approved: {formatTime(new Date(submission.reviewedAt))}
                </div>
              )}
              {submission.decision === 'rejected' && (
                <div>
                  Rejected: {formatTime(new Date(submission.reviewedAt))}
                </div>
              )}
              {submission.createdAt && (
                <div>Created: {formatTime(new Date(submission.createdAt))}</div>
              )}
            </div>
          </HSpace>
        </Link>
      ))}
    </div>
  );
}

export function ExternalApplicants() {
  return (
    <ColumnPage>
      <BasicPageBody title="External Client Applicants">
        <Submissions />
      </BasicPageBody>
    </ColumnPage>
  );
}
