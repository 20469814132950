import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { ApolloError } from "~/common/ApolloError";
import { CustomerIdContext } from "~/common/CustomerIdContext";
import { VSpace } from "~/common/Layout";
import { Loading } from "~/common/Loading";
import { Section, SectionHeading } from "~/common/Section";
import { IsUnknownBad } from "~common/Unknown";
import { Customer } from "~types/types";
import { Documents } from "./Documents";
import { UploadInternalDocumentsButton } from "./UploadInternalDocumentsButton";

const GET_DOCUMENTS = gql`
  query($customerId: ID) {
    getCustomer(id: $customerId) {
      id
      personId
      organizationId
      person {
        id
        documents {
          id
          slug
          purpose
          type
          side
          signedUrl
          au10tixResult {
            au10tixDocumentId
            authenticity
            address
            dateOfBirth
            documentExpiresAt
            documentIssuedAt
            documentNumber
            firstName
            lastName
            gender
            nationality
          }
        }
        internalDocuments {
          id
          slug
          type
          side
          signedUrl
        }
      }
      organization {
        id
        documents {
          id
          slug
          type
          side
          signedUrl
        }
        internalDocuments {
          id
          slug
          type
          side
          signedUrl
        }
      }
    }
  }
`;

export function ComplianceDocuments() {
  const customerId = React.useContext(CustomerIdContext);
  const { loading, error, data, refetch } = useQuery(GET_DOCUMENTS, {
    variables: { customerId }
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const customer: Customer = data.getCustomer;

  if (!customer) return <div className="black-50">No Customer</div>;
  if (!customer.person && !customer.organization) {
    throw new Error(
      "Customer should have either a `person` or an `organization`"
    );
  }

  const documents =
    (customer.person && customer.person.documents) ||
    (customer.organization && customer.organization.documents);
  const internalDocuments =
    (customer.person && customer.person.internalDocuments) ||
    (customer.organization && customer.organization.internalDocuments);

  return (
    <VSpace space={3}>
      <Section>
        <SectionHeading className="bb flex justify-between items-center">
          Documents
        </SectionHeading>
        <Documents documents={documents} />
      </Section>
      <Section>
        <SectionHeading className="bb flex justify-between items-center">
          Internal Documents
          <div className="f5">
            <UploadInternalDocumentsButton
              personId={customer.personId}
              organizationId={customer.organizationId}
              onComplete={() => refetch()}
            />
          </div>
        </SectionHeading>
        <IsUnknownBad.Provider value={false}>
          <Documents documents={internalDocuments} />
        </IsUnknownBad.Provider>
      </Section>
    </VSpace>
  );
}
