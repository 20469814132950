import { ApolloProvider, useQuery } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import * as React from "react";
import { User } from "~types/types";
import { ApolloError } from "./ApolloError";
import { getCookie } from "./util";

const apolloAccountClient = new ApolloClient({
  uri: "/api/graphql",
  credentials: "same-origin",
  headers: {
    "CSRF-Token": getCookie("csrf_token")
  }
});

type UserWithExperimental = User & {
  experimental: {
    isSupport: boolean;
    isCompliance: boolean;
  };
};

export const UserContext = React.createContext<UserWithExperimental>({
  id: undefined,
  email: undefined,
  roles: [],
  groups: [],
  experimental: {
    isSupport: false,
    isCompliance: false
  }
});

const GET_MY_USER = gql`
  query {
    getMyUser {
      id
      email
      roles {
        name
      }
      groups {
        name
      }
    }
  }
`;

function InnerUserContextProvider({ children }: { children: React.ReactNode }) {
  const { loading, error, data } = useQuery(GET_MY_USER);

  if (loading) return null;

  if (error) {
    return <ApolloError error={error} />;
  }

  const user: UserWithExperimental = data.getMyUser;

  // TODO: this should be fleshed out a bit more
  user.experimental = {
    isCompliance: true,
    isSupport: true
  };

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export function UserContextProvider({
  children
}: {
  children: React.ReactNode;
}) {
  return (
    <ApolloProvider client={apolloAccountClient}>
      <InnerUserContextProvider>{children}</InnerUserContextProvider>
    </ApolloProvider>
  );
}
