import * as React from "react";
import gql from "graphql-tag";
import { Button } from "~common/FormElements";
import { useMutation } from "@apollo/react-hooks";
import { notifyUser } from "~/common/util";


const RETRIGGER = gql`
  mutation($redemptionId: ID) {
    retriggerPrimetrustRedemption(redemptionId: $redemptionId)
  }
`;


export function RetriggerPrimetrustMintButton({ redemptionId, bankingPartner }) {
  const [retrigger] = useMutation(RETRIGGER, {
    variables: {
      redemptionId
    },
      onError: err => notifyUser(err, "Error retriggering redemption, make sure primetrust actually canceled it"),
    });

  function confirmAndRetrigger() {
    const response = confirm('Retrigger this redemption? Note: only primetrust redemption can be retriggered');
    if (response) {
      retrigger();
    }
  }
  return (
    <>
      {bankingPartner && bankingPartner.internalName == 'primetrust' && <Button onClick={() => confirmAndRetrigger()}>Retrigger</Button>}
    </>
  );
}
