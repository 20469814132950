import { FormikProps } from "formik";
import * as React from "react";
import { Input } from "~common/FormElements";
import { Labeled } from "~common/Labeled";
import { HSpace } from "~common/Layout";
import { Select } from "~common/Select";
import { COUNTRY_LIST, STATES_LIST } from "~common/util";
import { Org, OrgMember } from "~types/types";

export function AddressFormikPart({
  formikProps
}: {
  formikProps: FormikProps<Org | OrgMember>;
}) {
  const { values, setFieldValue, handleChange } = formikProps;

  let country;
  if (values.address) {
    country = values.address.country;
  }

  return (
    <>
      <HSpace space={2}>
        <Labeled label="Country">
          <Select
            options={COUNTRY_LIST}
            value={country || undefined}
            onChange={(value) => setFieldValue("address.country", value)}
          />
        </Labeled>
        <Labeled label="State or Province (Code)">
          {country !== 'US' &&
            <Input
              name="address.stateOrProvince"
              value={values.address && values.address.stateOrProvince}
              onChange={handleChange}
            />
          }
          {country === 'US' &&
            <Select
              options={STATES_LIST}
              value={
                (values.address && values.address.stateOrProvince) ||
                undefined
              }
              onChange={value =>
                setFieldValue("address.stateOrProvince", value)
              }
            />
          }
        </Labeled>
      </HSpace>

      <Labeled label="Street (address line 1)">
        <Input
          name="address.street"
          value={values.address && values.address.street}
          onChange={handleChange}
        />
      </Labeled>
      <Labeled label="Subpremise (address line 2)">
        <Input
          name="address.subpremise"
          value={values.address && values.address.subpremise}
          onChange={handleChange}
        />
      </Labeled>

      <HSpace space={2}>
        <Labeled label="City">
          <Input
            name="address.locality"
            value={values.address && values.address.locality}
            onChange={handleChange}
          />
        </Labeled>
        <Labeled label="Postal Code">
          <Input
            name="address.postalCode"
            value={values.address && values.address.postalCode}
            onChange={handleChange}
          />
        </Labeled>
      </HSpace>
    </>
  );
}
