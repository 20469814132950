export const MODULE_NAME_TO_LABEL_MAP = {
  sanction_screening: "Sanction Screening",
  government_id: "Government ID",
  selfie: "Selfie",
  us_ssn_check: "US SSN Check",
  liveness: "Liveness",
  address_verification: 'Address Verification',
};

export const REJECTION_REASON_NOTES: any = {
  identity_verification: {
    poor_image_quality: 'Unfortunately, we were unable to validate your proof of identity due to poor image quality. A quick retake or a higher resolution scan of your identification would clear this up quickly. Thanks for your patience as we process this for you.',
    not_passport: 'Oops! Looks like we received your Government ID rather than your Passport. Please submit your passport. Thanks for your patience as we process this for you.',
    name_mismatch: 'Oops! Looks like the name listed in your application does not match your ID. Unfortunately, an exact match is required. Please ensure the name on the application matches the name on your Government ID exactly. Thanks for your patience as we process this for you.',
    expired: 'Oops! Looks like your ID may have expired. Please provide a current and valid passport issued by a Government entity. Thanks for your patience as we process this for you.',
  },
  proof_of_address: {
    document_too_old: 'Oops! Looks like your proof of address that is older than 90 days. Please resubmit a proof of address document that is dated within 90 days. Thanks for your patience as we process this for you.',
    name_mismatch: 'Oops! Looks like the name listed in your application does not match your proof of address document. Please ensure the name on the application matches the name on your proof of address. Thanks for your patience as we process this for you.',
    language_not_english: 'Unfortunately, we can only accept English documents at this time. Please resubmit your proof of address document in English. Thanks for your patience as we build out our language capabilities.',
    address_mismatch: 'Oops! Looks like the address listed in your application does not match your proof of address document. Please ensure the address on the application matches the address on your proof of address. Thanks for your patience as we process this for you.',
    invalid_document_type: 'Oops! Looks like we are unable to validate the document you provided as a valid proof of address. We are happy to accept a bank statement, utility bill or cellphone bill. Here is a full list of document options; (https://support.trusttoken.com/hc/en-us/articles/360019869591-What-are-valid-forms-of-proof-of-address-). Please resubmit a new proof of address document. Thanks for your patience as we process this for you.',
    address_is_po_box: 'Oops! Looks like you provided a proof of address with only a PO Box number. Please resubmit a proof of physical address. Thanks for your patience as we process this for you.',
    not_residential_address: 'Oops! Looks like the address you have provided does not seem to be a residential address. Please update your address with your full current residential address. Thanks for your patience as we process this for you.',
  },
  address: {
    address_not_verified: 'We were not able to verify your address. Please upload your proof of address here: https://app.truecnh.io/individual/verify/proof-of-address',
  },
  national_id_number: {
    ssn_invalid: 'Unfortunately, we were unable to verify your Social Security Number. Please review your application to ensure the SSN provided is correct.',
  },
  date_of_birth: {
    government_id_mismatch: 'Oops! Looks like your Date of Birth listed in your application does not match your ID. Unfortunately, an exact match is required. Please edit your Date of Birth so it matches your proof of identification document exactly. Thanks for your patience as we process this for you.',
  },
};
