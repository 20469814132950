import * as React from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import { Loading } from "~/common/Loading";
import { ApolloError } from "~/common/ApolloError";
import { BasicPageBody } from "~/common/Layout";

import { ColumnPage } from "~/common/Page";
import { RedemptionsTable } from "../RedemptionsTable";

const GET_REDEMPTIONS = gql`
  query {
    getRedemptions {
      id
      customerId
      bankingPartner {
        name
        internalName
      }
      createdAt
      product
      amountInCents
      shortId
      status
      transactionHash
      blockchainId
    }
  }
`;

export function Redemptions() {
  const { loading, error, data } = useQuery(GET_REDEMPTIONS);

  if (loading) {
    return (
      <ColumnPage>
        <BasicPageBody title="Redemptions">
          <Loading />
        </BasicPageBody>
      </ColumnPage>
    );
  }

  if (error) return <ApolloError error={error} />;

  const redemptions = data.getRedemptions;

  return (
    <ColumnPage>
      <BasicPageBody title="Redemptions">
        <RedemptionsTable redemptions={redemptions} />
      </BasicPageBody>
    </ColumnPage>
  );
}
