import * as React from "react";
import { compareDesc } from "date-fns";
import { Link } from "react-router-dom";

import {
  formatTime,
  displayCurrencyNumber,
  centsToDollars,
  PRODUCT_CURRENCY_MAP,
  Product
} from "~/common/util";
import { Table, TH, TR, TD } from "~/common/Table";
import {RetriggerPrimetrustMintButton} from './RetriggerPrimetrustMintButton'
import { BlockchainTransactionLink } from "./BlockchainTransactionLink";

type Redemption = {
  id: string;
  customerId: string;
  bankingPartner?: {
    name: string;
  };
  createdAt: string;
  product: Product;
  amountInCents: number;
  shortId: string;
  status: string;
  transactionHash: string;
  blockchainId: string;
};

export function RedemptionsTable({
  redemptions,
  hideCustomerColumn
}: {
  redemptions?: Redemption[];
  hideCustomerColumn?: boolean;
}) {
  if (!redemptions || redemptions.length === 0) {
    return <div className="gray pv3">None</div>;
  }

  const reshaped = redemptions.map(p => ({
    ...p,
    // TODO: different browsers parse string dates differently
    createdAt: new Date(p.createdAt)
  }));
  const sorted = reshaped.sort((a, b) =>
    compareDesc(new Date(a.createdAt), new Date(b.createdAt))
  );

  const showCustomer = !hideCustomerColumn;

  return (
    <Table
      head={
        <>
          <TH>
            Created At
            <small className="db normal gray">(Most recent first)</small>
          </TH>
          {showCustomer && <TH>Customer</TH>}
          <TH>Amount Sent</TH>
          <TH>Reference Number</TH>
          <TH>Banking Partner</TH>
          <TH>Status</TH>
          <TH>Retrigger</TH>
        </>
      }
    >
      {sorted.map(r => (
        <TR key={r.id}>
          <TD>{formatTime(new Date(r.createdAt))}</TD>
          {showCustomer && (
            <TD>
              <Link
                to={`/internal/customers/${r.customerId}/transactions`}
                className="blue link underline"
              >
                View Customer
              </Link>
            </TD>
          )}
          <TD className="code tr">
            {displayCurrencyNumber(
              centsToDollars(r.amountInCents),
              PRODUCT_CURRENCY_MAP[r.product]
            )}
          </TD>
          <TD className="code tr">{r.shortId}</TD>
          <TD>{r.bankingPartner ? r.bankingPartner.name : ''}</TD>
          <TD className="flex justify-between">
            {r.status}
            <BlockchainTransactionLink
              product={r.product}
              blockchainId={r.blockchainId}
              hash={r.transactionHash}
            />
          </TD>
          <TD>
              <RetriggerPrimetrustMintButton
              redemptionId={r.id}
              bankingPartner={r.bankingPartner}
              />
          </TD>
        </TR>
      ))}
    </Table>
  );
}
