import { useMutation, useQuery } from "@apollo/react-hooks";
import { compareAsc, differenceInHours } from "date-fns";
import gql from "graphql-tag";
import * as React from "react";
import { PoseGroup } from "react-pose";
import {
  Link,
  matchPath,
  NavLink,
  Redirect,
  withRouter
} from "react-router-dom";
import { ApolloError } from "~/common/ApolloError";
import { ErrorBoundary } from "~/common/ErrorBoundary";
import { Button } from "~/common/FormElements";
import { Loading } from "~/common/Loading";
import { ScrollToTopOnMount } from "~/common/ScrollToTopOnMount";
import { PosedTR, Table, TD, TH } from "~/common/Table";
import { Unknown } from "~/common/Unknown";
import {
  centsToDollars,
  displayCurrencyNumber,
  formatTime,
  notifyUser,
  Product,
  PRODUCT_CURRENCY_MAP
} from "~/common/util";
import { VSpace } from "~common/Layout";
import { Section, SectionHeading } from "~common/Section";
import { BankInfo } from "~partner-portal/BankInfo";
import { Customer, RedemptionStatus } from "~types/types";
import { CustomerLink } from "../CustomerLink";

const NOW = Date.now();

function Time({ time, isSent }: { time: Date; isSent: boolean }) {
  return (
    <>
      <div>{formatTime(time)}</div>
      {differenceInHours(NOW, time) > 36 && !isSent && (
        <small className="db mt1 orange">⚠️ {'>'}36 Hours Old</small>
      )}
    </>
  );
}

type Redemption = {
  id: string;
  createdAt: string;
  amountInCents: number;
  product: Product;
  status: RedemptionStatus;
  customer?: Customer;
};

const GET_REDEMPTIONS = gql`
  query {
    getRedemptionsForMyBankingPartner {
      id
      createdAt
      amountInCents
      product
      status
      customer {
        id
        approvedAt
        deactivatedAt
        person {
          firstName
          lastName
        }
        organization {
          id
          name
        }
        defaultBankAccount {
          bankName
          notes
        }
      }
    }
  }
`;

const UPDATE_REDEMPTION = gql`
  mutation($redemptionId: ID, $status: String) {
    updateRedemption(id: $redemptionId, status: $status) {
      id
      status
    }
  }
`;

function Status({
  status,
  onChangeStatus
}: {
  status: RedemptionStatus;
  onChangeStatus: (s: RedemptionStatus) => void;
}) {
  switch (status) {
    case "new": {
      return (
        <>
          Received
          <span className="gray mh1">→</span>
          <Button
            className="w-100 bg-light-blue"
            onClick={() => onChangeStatus("processing")}
          >
            Mark Processing Wire
          </Button>
        </>
      );
    }
    case "processing": {
      return (
        <>
          Processing Wire <span className="gray mh1">→</span>
          {/* Initializing wire */}
          <Button
            className="w-100 bg-light-green"
            onClick={() => onChangeStatus("sent")}
          >
            Mark Wire Sent
          </Button>{" "}
          {/* wire sent to holder */}
        </>
      );
    }
    case "sent": {
      return (
        <>
          <Button
            className="w-100 b--silver"
            onClick={() => {
              if (confirm("Are you sure?")) {
                onChangeStatus("processing");
              }
            }}
          >
            Mark Processing Wire
          </Button>
          <span className="gray mh1">←</span>
          <span className="db mb1 dark-green">Wire Sent</span>
          {/* Confirm clicking, and  */}
        </>
      );
    }
    default: {
      return <Unknown />;
    }
  }
}

function RedemptionTable({
  status,
  redemptions,
  onRedemptionStatusChange
}: {
  status: RedemptionStatus;
  redemptions: Redemption[];
  onRedemptionStatusChange: (id: string, status: RedemptionStatus) => void;
}) {
  const sorted = redemptions.sort((a, b) =>
    compareAsc(new Date(a.createdAt), new Date(b.createdAt))
  );
  const filtered = sorted.filter(redemption => redemption.status === status);

  const [currentIndex, setCurrentIndex] = React.useState(0);

  return (
    <div className="flex">
      <div className="flex" style={{ flex: "1" }}>
        <Table
          head={
            <>
              <TH>Received</TH>
              <TH>TrueCurrency Holder</TH>
              <TH>Amount</TH>
              <TH>Bank</TH>
              <TH className="w5">Status</TH>
            </>
          }
        >
          {/* Key on PoseGroup to avoid animations when changing status */}
          <PoseGroup key={status}>
            {filtered.map((redemption, i) => (
              <PosedTR
                key={redemption.id}
                hiddenAtXPosition={status === "sent" ? -150 : 150}
                onClick={() => setCurrentIndex(i)}
                className={currentIndex === i ? "bg-black-05" : ""}
              >
                <TD className="nowrap">
                  <Time
                    time={new Date(redemption.createdAt)}
                    isSent={redemption.status === "sent"}
                  />
                </TD>
                <TD>
                  {redemption.customer && (
                    <CustomerLink customer={redemption.customer} />
                  )}
                </TD>
                <TD className="code tr nowrap">
                  {displayCurrencyNumber(
                    centsToDollars(redemption.amountInCents),
                    PRODUCT_CURRENCY_MAP[redemption.product]
                  )}
                </TD>
                {redemption.customer && redemption.customer.defaultBankAccount && (
                  <>
                    <TD style={{ background: "#0000000c" }}>
                      {redemption.customer.defaultBankAccount.bankName}
                      {redemption.customer.defaultBankAccount
                        .intermediaryBankName && (
                        <small className="db mt1">
                          ⚠️via:{" "}
                          {
                            redemption.customer.defaultBankAccount
                              .intermediaryBankName
                          }
                          {redemption.customer.defaultBankAccount.notes && (
                            <div>⚠️Notes</div>
                          )}
                        </small>
                      )}
                    </TD>
                  </>
                )}
                <TD className="tc flex nowrap">
                  <Status
                    status={status}
                    onChangeStatus={status =>
                      onRedemptionStatusChange(redemption.id, status)
                    }
                  />
                </TD>
              </PosedTR>
            ))}
          </PoseGroup>
        </Table>
      </div>
      <div className="pa4 bg-black-05 flex" style={{ minWidth: "24em" }}>
        {filtered.length === 0 && (
          <div className="tc pv5 f1">
            {status === "new" && (
              <Link
                to="/partner/redemptions/processing"
                className="black link underline"
              >
                Send out processed wires →
              </Link>
            )}
            {status === "processing" && (
              <>
                Nothing to do 😎
                <Link
                  to="/partner/redemptions/sent"
                  className="db mt1 f5 gray link underline"
                >
                  Admire your work →
                </Link>
              </>
            )}
            {status === "sent" && "Nothing sent out yet"}
          </div>
        )}
        {filtered.length > 0 &&
          filtered[currentIndex] &&
          filtered[currentIndex].customer &&
          // Somehow TS doesn't pick up on the fact that I've already verified that customer exists
          (filtered[currentIndex].customer as Customer).id && (
            <Section>
              <div>
                <SectionHeading style={{ paddingBottom: '24px'}}>Bank Info</SectionHeading>
                <BankInfo
                  customerId={(filtered[currentIndex].customer as Customer).id}
                  product={filtered[currentIndex].product}
                />
              </div>
            </Section>
          )}
      </div>
    </div>
  );
}

function RedemptionsContent({ status }: { status: RedemptionStatus }) {
  const { loading, error, data } = useQuery(GET_REDEMPTIONS);
  const [updateRedemption] = useMutation(UPDATE_REDEMPTION, {
    onError: err => notifyUser(err, "Error updating redemption")
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  function changeStatus(redemptionId: string, status: RedemptionStatus) {
    updateRedemption({
      variables: { redemptionId, status },
      refetchQueries: [{ query: GET_REDEMPTIONS }]
    });
  }

  return (
    <RedemptionTable
      // Reset internal state when status changes
      key={status}
      status={status}
      redemptions={data.getRedemptionsForMyBankingPartner as Redemption[]}
      onRedemptionStatusChange={changeStatus}
    />
  );
}

function statusFromPath(path: string): RedemptionStatus {
  if (matchPath(path, { path: "/partner/redemptions/received" })) {
    return "new";
  }
  if (matchPath(path, { path: "/partner/redemptions/processing" })) {
    return "processing";
  }
  if (matchPath(path, { path: "/partner/redemptions/sent" })) {
    return "sent";
  }
  throw new Error("Status expected");
}

export const Redemptions = withRouter(({ location }) => {
  if (location.pathname === "/partner/redemptions") {
    return <Redirect to="/partner/redemptions/received" />;
  }

  const status = statusFromPath(location.pathname);

  return (
    <div>
      <ScrollToTopOnMount />
      <h1>TrueCurrencies Redemptions</h1>
      <div className="flex items-center justify-end bb">
        <NavLink
          to="/partner/redemptions/received"
          className="black no-underline pa3"
          activeClassName="bg-black white"
        >
          Received
        </NavLink>
        <div className="mh2 silver">→</div>
        <NavLink
          to="/partner/redemptions/processing"
          className="black no-underline pa3"
          activeClassName="bg-black white"
        >
          Processing Wire
        </NavLink>
        <div className="mh2 silver">→</div>
        <NavLink
          to="/partner/redemptions/sent"
          className="black no-underline pa3"
          activeClassName="bg-black white"
        >
          Wire Sent
        </NavLink>
      </div>
      <ErrorBoundary>
        <RedemptionsContent status={status} />
      </ErrorBoundary>
    </div>
  );
});
