import * as React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Checkbox } from "~common/CheckboxSelect";


export const UPDATE_PERSON_PEP_STATUS = gql`
  mutation($personId: ID, $updatePersonPepStatusInput: UpdatePersonPepStatusInput) {
    updatePersonPepStatus(
      id: $personId
      input: $updatePersonPepStatusInput
    ) {
      id
    }
  }
`;


export function PepStatusBlock({ person }) {  
  const [update] = useMutation(UPDATE_PERSON_PEP_STATUS, {
    onError: err => alert(err)
  });
  const [pep, setPep] = React.useState(person.isPep);

  function handleChange(isPep){
    setPep(isPep)
    update({
      variables: {
        personId: person.id,
        updatePersonPepStatusInput: { isPep }
      }
    })
  }  
  return (
    <div>
      <Checkbox label= 'Person is politically exposed'
        value={pep}
        onChange={handleChange}/>
    </div>
  );
}
