import * as React from "react";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { ErrorBox } from "~common/ErrorBox";
import { Unknown } from "~common/Unknown";
import { isIFrameFileName, isImageFileName } from "~common/util";
import { UploadedDocument } from "~types/types";

function IframeDocument({ document }: { document: UploadedDocument }) {
  return (
    <div>
      <div>
        <a href={`${document.signedUrl}#toolbar=0`} target="_blank">Open in new tab</a>
      </div>
      <object type="application/pdf" style={{width: '100%'}} data={`${document.signedUrl}#toolbar=0`}></object>
    </div>
  );
}

function ImageDocument({ document }: { document: UploadedDocument }) {
  return (
    <div className="relative overflow-hidden relative z-0 bg-black-05 ba b--black-10">
      {document.signedUrl ? (
        <ReactPanZoom image={document.signedUrl} />
      ) : (
        <Unknown isBad />
      )}
    </div>
  );
}

export function Document({ document }: { document: UploadedDocument }) {
  if (!document.signedUrl) return <ErrorBox title="Missing `signedUrl`" />;
  const url = new URL(document.signedUrl).pathname;

  if (isImageFileName(url)) {
    return <ImageDocument document={document} />;
  } else if (isIFrameFileName(url)) {
    return <IframeDocument document={document} />;
  } else {
    return (
      <a href={document.signedUrl} className="blue link" target="_blank">
        <b>{document.slug}</b>
      </a>
    );
  }
}
