import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as React from "react";
import { Address } from "~/common/Address";
import { Annotated } from "~/common/Annotated";
import { ApolloError } from "~/common/ApolloError";
import { LABELS } from "~/common/labels";
import { Loading } from "~/common/Loading";
import { Section, SectionHeading } from "~/common/Section";
import { ShowLast4 } from "~common/ShowLast4";
import { Customer } from "~types/types";
import { PRODUCT_CURRENCY_MAP } from "~common/util";

const GET_BANK_INFO = gql`
  query($customerId: ID, $currency: String) {
    getCustomer(id: $customerId) {
      id
      defaultBankAccount(currency: $currency) {
        id
        accountHolderName
        bankName
        accountNumber
        routingNumber
        swiftCode
        notes
        phoneNumber
        address {
          street
          subpremise
          locality
          stateOrProvince
          postalCode
          country
        }
        intermediaryBankName
        intermediaryBankSwiftCode
        intermediaryBankAddress {
          street
          subpremise
          locality
          stateOrProvince
          postalCode
          country
        }
      }
    }
  }
`;

export function BankInfo({
  customerId,
  product
}: {
  customerId: string;
  product: string;
}) {
  const currency = PRODUCT_CURRENCY_MAP[product];
  const { loading, error, data } = useQuery(GET_BANK_INFO, {
    variables: { customerId, currency}
  });

  if (loading) return <Loading />;
  if (error) return <ApolloError error={error} />;

  const customer: Customer | undefined = data.getCustomer;
  const account = customer && customer.defaultBankAccount;

  if (!account) return <div className="black-50">None</div>;

  return (
    <div>
      <div>
        <Annotated
          label={LABELS.bank.accountHolderName}
          value={account.accountHolderName}
          className="pb3"
        />
        <Annotated label={LABELS.bank.bankName} value={account.bankName} className="pb3" />
        <Annotated
          label={LABELS.bank.accountNumber}
          value={<ShowLast4 value={account.accountNumber || ""} />}
          unknownOk
          className="pb3"
        />
        <Annotated
          label={LABELS.bank.routingNumber}
          value={account.routingNumber}
          unknownOk
          className="pb3"
        />
        <Annotated
          label={LABELS.bank.swiftCode}
          value={account.swiftCode}
          unknownOk
          className="pb3"
        />
        <Annotated
          label={LABELS.bank.address}
          value={<Address address={account.address} />}
          className="pb3"
        />
        {/* Highlight notes so any special instructions are not missed */}
        {account.notes && (
          <div className="bg-light-yellow pa2">
            <Annotated
              label={LABELS.bank.notes}
              value={account.notes}
              unknownOk
              className="pb3"
            />
          </div>
        )}
      </div>

      {account.intermediaryBankName && (
        <Section>
          <div>
            <SectionHeading className="bb">
              {LABELS.bank.intermediaryHeading}
            </SectionHeading>
            <Annotated
              label={LABELS.bank.bankName}
              value={account.intermediaryBankName}
              className="pb3"
            />
            <Annotated
              label={LABELS.bank.swiftCode}
              value={account.intermediaryBankSwiftCode}
              className="pb3"
            />
            <Annotated
              label={LABELS.bank.address}
              value={<Address address={account.intermediaryBankAddress} />}
              className="pb3"
            />
          </div>
        </Section>
      )}
    </div>
  );
}
