import * as React from "react";
import { Button } from "./FormElements";

export function ErrorBox({
  title = "🤦‍♀️ Unexpected Error",
  error,
  children
}: {
  title?: React.ReactNode;
  error?: unknown;
  children?: React.ReactNode;
}) {
  return (
    // Place in center when component in flex container
    <div className="flex flex-grow-1 justify-center items-center">
      <div className="ba bw2 b--dark-red pa3">
        <h1 className="mv0 dark-red f4">{title}</h1>
        <div className="pv1" />
        {children || (
          <span>
            Our engineers have been notified. We'll fix it soon.
            <br />
            Need immediate help?{" "}
            <a
              href="mailto:official@trueusd.com"
              className="blue link underline"
            >
              Contact support
            </a>
            .
          </span>
        )}
        {error && (
          <Button
            className="db mt2"
            onClick={() => console.error(JSON.stringify(error, null, "  "))}
          >
            Print Error in Console
          </Button>
        )}
      </div>
    </div>
  );
}
