import * as React from "react";
import { Link } from "react-router-dom";
import { Panel } from "~/common/Panel";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Loading } from "~/common/Loading";


const GET_USER = gql`
  query($id: ID) {
    getUser(id: $id) {
      email
    }
  }
`;


export function UserLink(props) {
  const { userId } = props  
  const [showPanel, setShowPanel] = React.useState(false);

  return (
    <span>
      <Link
        to="#"
        onClick={() => setShowPanel(true)}
        className={`black link underline `}
      >
        {userId}
      </Link>
      {showPanel && (
        <Panel onClose={() => setShowPanel(false)}>
          <User userId={userId} />
        </Panel>
      )}
    </span>
  );
}


export function User(props) {
  const { userId } = props    
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { id: userId }
  });
  if (loading || error) {
    return (
      <Loading/>
    )
  }
  if (data.getUser) {
    return (
      <div>
        <h3>{`email: ${data.getUser.email}`}</h3>
      </div>
    )    
  }
}
